import React from 'react';
import { Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface IPagination {
    total: number;
    perPage: number;
    currentPage: number;
    changePage(val: number): void;
}

const CustomPagination: React.FC<IPagination> = ({ total, perPage, currentPage, changePage }) => {
    if (total <= 1) return null;

    return (
        <Col lg={12}>
            <Pagination listClassName='justify-content-center' className='pagination-separated mb-0'>
                <PaginationItem
                    disabled={currentPage <= 1}
                    onClick={() => currentPage > 1 && changePage(currentPage - 1)}
                >
                    <PaginationLink to='#'>
                        <i className='mdi mdi-chevron-left' />
                    </PaginationLink>
                </PaginationItem>
                {Array.from({ length: total }, (_, i) => i + 1).map((i) => {
                    return (
                        <PaginationItem active={i === currentPage} key={`page-${i}`}>
                            <PaginationLink to='#' onClick={() => changePage(i)}>
                                {i}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}
                <PaginationItem
                    disabled={currentPage >= total}
                    onClick={() => currentPage < total && changePage(currentPage + 1)}
                >
                    <PaginationLink to='#'>
                        <i className='mdi mdi-chevron-right' />
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </Col>
    );
};

export default CustomPagination;
