import classnames from 'classnames';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';

import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import useProfile from './useProfile';

const Profile = () => {
    document.title = 'Profile | Connections';

    const {
        formInfoValidation,
        formPasswordValidation,
        activeTab,
        validateAddressField,
        onAddressSelected,
        onAddressChanged,
        tabChange,
        navigate
    } = useProfile();

    return (
        <React.Fragment>
            <div className='row page-titles mx-0'>
                <div className='col-sm-9 p-md-0'>
                    <div className='welcome-tex'>
                        <h4>Profile</h4>
                    </div>
                </div>
            </div>

            <Row>
                <Col xxl={12}>
                    <Card className='mt-xxl-n5'>
                        <CardHeader>
                            <Nav className='nav-tabs-custom rounded card-header-tabs border-bottom-0' role='tablist'>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => tabChange(1)}
                                        type='button'
                                    >
                                        <i className='fas fa-home'></i>
                                        Personal Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to='#'
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => tabChange(2)}
                                        type='button'
                                    >
                                        <i className='far fa-user'></i>
                                        Change Password
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className='p-4'>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            //To prevent the form to be sent when user press enter when selecting the address
                                            if (document.activeElement?.id == 'address') return;
                                            if (validateAddressField()) formInfoValidation.handleSubmit();
                                            return false;
                                        }}
                                        action='#'
                                    >
                                        <Col lg={6}>
                                            <div className='mb-2'>
                                                <Label htmlFor='name' className='form-label'>
                                                    Account Category:
                                                </Label>
                                                <span className='ms-2'>{formInfoValidation.values.category?.name}</span>
                                            </div>

                                            <div className='mb-3'>
                                                <Label htmlFor='name' className='form-label'>
                                                    Name <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    name='name'
                                                    type='text'
                                                    placeholder='Enter your name'
                                                    onChange={formInfoValidation.handleChange}
                                                    onBlur={formInfoValidation.handleBlur}
                                                    value={formInfoValidation.values.name || ''}
                                                    invalid={
                                                        formInfoValidation.touched.name &&
                                                        !!formInfoValidation.errors.name
                                                    }
                                                />
                                                {formInfoValidation.touched.name &&
                                                    !!formInfoValidation.errors.name && (
                                                        <FormFeedback type='invalid'>
                                                            <div>{formInfoValidation.errors.name}</div>
                                                        </FormFeedback>
                                                    )}
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor='phone' className='form-label'>
                                                    Phone <span className='text-danger'>*</span>
                                                </Label>
                                                <Cleave
                                                    id='phone'
                                                    name='phone'
                                                    className={
                                                        'form-control ' +
                                                        (formInfoValidation.touched.phone &&
                                                        !!formInfoValidation.errors.phone
                                                            ? 'is-invalid form-control'
                                                            : '')
                                                    }
                                                    placeholder='(xxxx) xxx-xxx'
                                                    options={{
                                                        numericOnly: true,
                                                        delimiterLazyShow: true,
                                                        delimiters: ['(', ') ', '-'],
                                                        blocks: [0, 3, 3, 4]
                                                    }}
                                                    onBlur={formInfoValidation.handleBlur}
                                                    onChange={formInfoValidation.handleChange}
                                                    value={formInfoValidation.values.phone || ''}
                                                />
                                                {formInfoValidation.touched.phone &&
                                                    !!formInfoValidation.errors.phone && (
                                                        <FormFeedback type='invalid' style={{ display: 'block' }}>
                                                            {formInfoValidation.errors.phone}
                                                        </FormFeedback>
                                                    )}
                                            </div>

                                            <div className='mb-3'>
                                                <Label htmlFor='email' className='form-label'>
                                                    Email <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    id='email'
                                                    name='email'
                                                    className='form-control'
                                                    placeholder='Enter email address'
                                                    type='email'
                                                    onChange={formInfoValidation.handleChange}
                                                    onBlur={formInfoValidation.handleBlur}
                                                    value={formInfoValidation.values.email || ''}
                                                    invalid={
                                                        formInfoValidation.touched.email &&
                                                        !!formInfoValidation.errors.email
                                                    }
                                                />
                                                {formInfoValidation.touched.email &&
                                                    !!formInfoValidation.errors.email && (
                                                        <FormFeedback type='invalid'>
                                                            {formInfoValidation.errors.email}
                                                        </FormFeedback>
                                                    )}
                                            </div>

                                            <div className='mb-3'>
                                                <Label htmlFor='email' className='form-label'>
                                                    Address <span className='text-danger'>*</span>
                                                </Label>

                                                <Autocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                    id='address'
                                                    name='address'
                                                    defaultValue={formInfoValidation.values.fullAddress}
                                                    className={
                                                        'form-control ' + (!validateAddressField() && 'is-invalid')
                                                    }
                                                    placeholder='Enter your address'
                                                    onPlaceSelected={onAddressSelected}
                                                    onChange={onAddressChanged}
                                                    options={{
                                                        componentRestrictions: { country: 'us' },
                                                        types: ['address'],
                                                        fields: ['address_components', 'formatted_address']
                                                    }}
                                                />
                                                {!validateAddressField() && (
                                                    <FormFeedback type='invalid'>
                                                        {globals.invalidAddressMsg}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className='hstack gap-2 justify-content-start'>
                                                <button type='submit' className='btn btn-primary'>
                                                    Save changes
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-light'
                                                    onClick={() => navigate(RoutesName.dashboard)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            formPasswordValidation.handleSubmit();
                                            return false;
                                        }}
                                        action='#'
                                    >
                                        <Col lg={4}>
                                            <div className='mb-3'>
                                                <Label className='form-label' htmlFor='password-input'>
                                                    Current Password <span className='text-danger'>*</span>
                                                </Label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <Input
                                                        name='currentPassword'
                                                        value={formPasswordValidation.values.currentPassword || ''}
                                                        type={'password'}
                                                        className='form-control pe-5'
                                                        placeholder='Enter Current Password'
                                                        onChange={formPasswordValidation.handleChange}
                                                        onBlur={formPasswordValidation.handleBlur}
                                                        invalid={
                                                            formPasswordValidation.touched.currentPassword &&
                                                            !!formPasswordValidation.errors.currentPassword
                                                        }
                                                    />
                                                    {formPasswordValidation.touched.currentPassword &&
                                                        !!formPasswordValidation.errors.currentPassword && (
                                                            <FormFeedback type='invalid'>
                                                                {formPasswordValidation.errors.currentPassword}
                                                            </FormFeedback>
                                                        )}
                                                </div>
                                            </div>

                                            <div className='mb-3'>
                                                <Label className='form-label' htmlFor='password-input'>
                                                    Password <span className='text-danger'>*</span>
                                                </Label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <Input
                                                        name='password'
                                                        value={formPasswordValidation.values.password || ''}
                                                        type={'password'}
                                                        className='form-control pe-5'
                                                        placeholder='Enter Password'
                                                        onChange={formPasswordValidation.handleChange}
                                                        onBlur={formPasswordValidation.handleBlur}
                                                        invalid={
                                                            formPasswordValidation.touched.password &&
                                                            !!formPasswordValidation.errors.password
                                                        }
                                                    />
                                                    {formPasswordValidation.touched.password &&
                                                        !!formPasswordValidation.errors.password && (
                                                            <FormFeedback type='invalid'>
                                                                {formPasswordValidation.errors.password}
                                                            </FormFeedback>
                                                        )}
                                                </div>
                                            </div>

                                            <div className='mb-4'>
                                                <Label htmlFor='confirmPassword' className='form-label'>
                                                    Confirm Password <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    name='confirmPassword'
                                                    type='password'
                                                    placeholder='Confirm Password'
                                                    onChange={formPasswordValidation.handleChange}
                                                    onBlur={formPasswordValidation.handleBlur}
                                                    value={formPasswordValidation.values.confirmPassword || ''}
                                                    invalid={
                                                        formPasswordValidation.touched.confirmPassword &&
                                                        !!formPasswordValidation.errors.confirmPassword
                                                    }
                                                />
                                                {formPasswordValidation.touched.confirmPassword &&
                                                    !!formPasswordValidation.errors.confirmPassword && (
                                                        <FormFeedback type='invalid'>
                                                            <div>{formPasswordValidation.errors.confirmPassword}</div>
                                                        </FormFeedback>
                                                    )}
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className='hstack gap-2 justify-content-start'>
                                                <button type='submit' className='btn btn-primary'>
                                                    Change Password
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-light'
                                                    onClick={() => navigate(RoutesName.dashboard)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Profile;
