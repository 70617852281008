import AdminUsersList from '@src/pages/admin/AdminUsers/List';
import AdminBotsCategories from '@src/pages/admin/Bots/Categories';
import AdminBotsList from '@src/pages/admin/Bots/List';
import AdminBotsPrompts from '@src/pages/admin/Bots/Prompts';
import AdminBotsSubCategories from '@src/pages/admin/Bots/SubCategories';
import AdminClientsInfo from '@src/pages/admin/Clients/Info';
import AdminClientList from '@src/pages/admin/Clients/List';
import AdminDashboard from '@src/pages/admin/Dashboard';
import AdminImportUser from '@src/pages/admin/ImportUser';
import AdminLeadsList from '@src/pages/admin/Leads';
import AdminLogin from '@src/pages/admin/Login';
import AdminMunicipalitiesList from '@src/pages/admin/Municipalities/List';
import AdminMunicipalitiesRequests from '@src/pages/admin/Municipalities/Requests';
import AdminProfile from '@src/pages/admin/Profile';
import AdminQuestions from '@src/pages/admin/Questionnaire/Questions';
import AdminStateList from '@src/pages/admin/States';
import Messages from '@src/pages/app/Messages';
import AdminLayout from '@src/partials/AdminLayout';
import PublicAdminAuthLayout from '@src/partials/PublicAdminAuthLayout';
import { RoutesName } from '@src/utils/enums';

export const adminRoutes = [
    { path: RoutesName.adminRoot, component: <PublicAdminAuthLayout children={<AdminLogin />} /> },
    { path: RoutesName.adminLogin, component: <PublicAdminAuthLayout children={<AdminLogin />} /> },
    { path: RoutesName.adminDashboard, component: <AdminLayout children={<AdminDashboard />} /> },
    { path: RoutesName.adminProfile, component: <AdminLayout children={<AdminProfile />} /> },
    { path: RoutesName.adminClientsInfo, component: <AdminLayout children={<AdminClientsInfo />} /> },
    { path: RoutesName.adminClients, component: <AdminLayout children={<AdminClientList />} /> },
    { path: RoutesName.adminLeads, component: <AdminLayout children={<AdminLeadsList />} /> },
    { path: RoutesName.adminImportUser, component: <AdminLayout children={<AdminImportUser />} /> },
    { path: RoutesName.adminUsers, component: <AdminLayout children={<AdminUsersList />} /> },
    { path: RoutesName.adminQuestions, component: <AdminLayout children={<AdminQuestions />} /> },
    { path: RoutesName.adminBotCategories, component: <AdminLayout children={<AdminBotsCategories />} /> },
    { path: RoutesName.adminBotCategories, component: <AdminLayout children={<AdminBotsCategories />} /> },
    { path: RoutesName.adminBotCategoriesWithId, component: <AdminLayout children={<AdminBotsCategories />} /> },
    { path: RoutesName.adminBotSubCategories, component: <AdminLayout children={<AdminBotsSubCategories />} /> },
    {
        path: RoutesName.adminBotSubCategoriesByCategory,
        component: <AdminLayout children={<AdminBotsSubCategories />} />
    },
    { path: RoutesName.adminBotPrompts, component: <AdminLayout children={<AdminBotsPrompts />} /> },
    { path: RoutesName.adminBotMessages, component: <AdminLayout children={<Messages />} /> },
    { path: RoutesName.adminBotsList, component: <AdminLayout children={<AdminBotsList />} /> },

    { path: RoutesName.adminStates, component: <AdminLayout children={<AdminStateList />} /> },
    { path: RoutesName.adminStatesWithId, component: <AdminLayout children={<AdminStateList />} /> },
    { path: RoutesName.adminMunicipalities, component: <AdminLayout children={<AdminMunicipalitiesList />} /> },
    { path: RoutesName.adminMunicipalitiesByState, component: <AdminLayout children={<AdminMunicipalitiesList />} /> },
    {
        path: RoutesName.adminMunicipalitiesRequests,
        component: <AdminLayout children={<AdminMunicipalitiesRequests />} />
    }
];
