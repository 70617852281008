import IAutoCharge from '@src/interfaces/IAutoCharge';
import IUser from '@src/interfaces/IUser';
import { getAutoChargeByUser } from '@src/services/AutoChargeService';
import { addFreeBalance } from '@src/services/PaymentService';
import { getUser, getUserUsageBalance } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import { errorToast, sucessToast } from '@src/utils/helpers';
import { autoChargeInitValues } from '@src/utils/initValues';
import 'cleave.js/dist/addons/cleave-phone.in';
import { useEffect, useState } from 'react';

interface IUseOverviewTab {
    setRefreshBillingHistoryData: (value: boolean) => void;
    user?: IUser;
}
const useOverviewTab = ({ setRefreshBillingHistoryData, user }: IUseOverviewTab) => {
    const setLoading = globalStore((state) => state.setLoading);
    const loading = globalStore((state) => state.loading);

    const [showAddBalance, setShowAddBalance] = useState(false);
    const [rechargeAmount, setRechargeAmount] = useState(globals.minAmountToRechargeAdmin);
    const [autoCharge, setAutoCharge] = useState<IAutoCharge>(autoChargeInitValues);
    const [usageBlance, setUsageBalance] = useState(0);

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        const [userResult, autoChargeResult, usageBalanceResult] = await Promise.all([
            getUser(user?.userId!),
            getAutoChargeByUser(user?.userId!),
            getUserUsageBalance(user?.userId!)
        ]);

        if (userResult.success && user) {
            user.creditBalance = userResult.data.creditBalance;
        } else errorToast(userResult.message);

        if (autoChargeResult.success) {
            setAutoCharge(autoChargeResult.data);
        }

        if (usageBalanceResult.success) {
            setUsageBalance(usageBalanceResult.data);
        }
        setLoading(false);
    };

    const onRechargeAmountChange = (e: any) => {
        setRechargeAmount(parseInt(e.target.rawValue.toString()) || 0);
    };

    const onAddBalance = async () => {
        if (rechargeAmount < globals.minAmountToRechargeAdmin) {
            errorToast('The minimun amount to add is $' + globals.minAmountToRechargeAdmin);
            return;
        }
        setLoading(true);

        const addBalanceResult = await addFreeBalance(user?.userId!, rechargeAmount);

        if (addBalanceResult.success && user) {
            user.creditBalance = addBalanceResult.data.creditBalance;
            sucessToast('Balance added successfully!');
            setShowAddBalance(false);
            setRefreshBillingHistoryData(true);
            setRechargeAmount(globals.minAmountToRechargeAdmin);
        } else errorToast(addBalanceResult.message);
        setLoading(false);
    };

    return {
        rechargeAmount,
        showAddBalance,
        loading,
        autoCharge,
        usageBlance,
        setShowAddBalance,
        onRechargeAmountChange,
        onAddBalance
    };
};

export default useOverviewTab;
