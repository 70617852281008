import { Badge, Col, Row, TabPane, UncontrolledTooltip } from 'reactstrap';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import { CustomTable } from '@src/components/CustomTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import IStripePayment from '@src/interfaces/IStripePayment';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { convertPriceWithDecimals, errorToast, getCreditCardImage, getLoggedUser } from '@src/utils/helpers';
import { getPayments } from '@src/services/PaymentService';
import { FormButton } from '@src/components/FormButton';
import globalStore from '@src/stores/globalStore';
import { Link } from 'react-router-dom';

interface IBillingHistory {
    paymentMethods: Array<IStripePaymentMethod>;
    refreshData: boolean;
}
const BillingHistory: React.FC<IBillingHistory> = ({ paymentMethods, refreshData }) => {
    const loggedUser = getLoggedUser();
    const setLoading = globalStore((state) => state.setLoading);

    const [payments, setPayments] = useState<Array<IStripePayment>>([]);

    const columnHelper = createColumnHelper<IStripePayment>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Description',
            accessorKey: 'description'
        },
        {
            header: 'Amount',
            accessorKey: 'amount',
            cell: (value) => `$${convertPriceWithDecimals(value.getValue())}`
        },
        columnHelper.display({
            header: 'Status',
            cell: ({ row }) => {
                if (row.original.status === 'succeeded') return <Badge color='success'>Success</Badge>;
                return (
                    <>
                        <Badge color='danger' id={'payment-' + row.original.id}>
                            Failed
                        </Badge>
                        <UncontrolledTooltip placement='top' target={`payment-${row.original.id}`}>
                            {row.original.lastPaymentError?.message}
                        </UncontrolledTooltip>
                    </>
                );
            }
        }),
        columnHelper.display({
            header: 'Payment Method',
            cell: ({ row }) => {
                let pm = paymentMethods?.find((pm) => pm.id === row.original.paymentMethod);
                if (!pm && row.original.status !== 'succeeded')
                    pm = paymentMethods?.find((pm) => pm.id === row.original.lastPaymentError?.paymentMethod?.id);

                return (
                    <div className='d-flex align-items-center'>
                        {row.original.captureMethod === 'balance' ? (
                            'Credit Balance'
                        ) : (
                            <>
                                <img src={getCreditCardImage(pm?.card.brand!)} style={{ height: '1.5rem' }} />{' '}
                                <span className='ms-2'>{pm?.card.last4}</span>
                            </>
                        )}
                    </div>
                );
            }
        }),
        {
            header: 'Date',
            accessorKey: 'created',
            cell: (value) => moment(value.getValue() * 1000).format('MM/DD/YYYY hh:mm A')
        },
        columnHelper.display({
            header: 'Invoice/Receipt',
            cell: ({ row }) => {
                const url = row.original.invoice?.invoicePdf || row.original.latestCharge?.receiptUrl || '#';
                if (row.original.status !== 'succeeded' || url === '#')
                    return (
                        <div className='text-center'>
                            <span className='text-muted'>N/A</span>
                        </div>
                    );
                else
                    return (
                        <Link to={url} target='_blank' className='d-block text-center'>
                            <i className='ri-file-download-line fs-20'></i>
                        </Link>
                    );
            }
        })
    ];

    useEffect(() => {
        if (refreshData || !payments.length) getData();
    }, [refreshData]);

    const getData = async () => {
        setLoading(true);
        const { success, data, message } = await getPayments(loggedUser);
        if (success) {
            setPayments(data);
        } else errorToast(message);
        setLoading(false);
    };

    return (
        <TabPane tabId='4'>
            <Row>
                <Col lg={12}>
                    <FormButton className='mb-2 d-flex' fitParent={false} onClick={getData}>
                        Refresh <i className='ri-refresh-line ms-2'></i>
                    </FormButton>
                    <CustomTable data={payments} columns={columns} />
                </Col>
            </Row>
        </TabPane>
    );
};

export default BillingHistory;
