import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

interface IPlaceHolder {
    hideButton?: boolean;
}
const PlaceHolder: React.FC<IPlaceHolder> = ({ hideButton }) => {
    return (
        <Card>
            <CardBody>
                <h5 className='card-title placeholder-glow'>
                    <span className='placeholder col-6'></span>
                </h5>
                <p className='card-text placeholder-glow'>
                    <span className='placeholder col-7'></span>
                    <span className='placeholder col-4'></span>
                    <span className='placeholder col-4'></span>
                    <span className='placeholder col-6'></span>
                </p>
                {!hideButton && (
                    <Link to='#' tabIndex={-1} className='btn btn-primary disabled placeholder col-2'></Link>
                )}
            </CardBody>
        </Card>
    );
};

export default PlaceHolder;
