import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

// Formik validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { login, resetPassword } from '@src/services/UserService';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData } from '@src/utils/helpers';
import { resetPassInitValues } from '@src/utils/initValues';

const useResetPassword = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();
    const formValidation = useFormik({
        initialValues: resetPassInitValues,
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required('Confirm password is required')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setErrorMsg('');
            console.log('submit', values);

            try {
                const { success, data, message } = await resetPassword(token!, values.password);
                if (success) {
                    setSuccessMsg(message);
                    setTimeout(async () => {
                        if (data.isAdmin) {
                            await login(data.email, values.password);
                            localStorage.setItem(globals.adminSessionKey, encryptData(data));
                            navigate(RoutesName.adminDashboard, { replace: true });
                        } else {
                            if (token) {
                                await login(data.email, values.password);
                                localStorage.setItem(globals.userSessionKey, encryptData(data));
                            }
                            navigate(RoutesName.root, { replace: true });
                        }
                    }, 2000);
                } else {
                    setLoading(false);
                    setErrorMsg(message);
                }
            } catch (error: any) {
                setErrorMsg(error.message);
                setLoading(false);
            }
        }
    });

    return { formValidation, errorMsg, successMsg, loading };
};

export default useResetPassword;
