import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { stateInitValues } from '@src/utils/initValues';
import IState from '@src/interfaces/IState';
import { useParams } from 'react-router-dom';
import { createState, deleteState, getState, getStates, updateState } from '@src/services/StateService';

const useAdminStateList = () => {
    const { stateId: filteredStateId } = useParams();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [states, setStates] = useState<Array<IState>>([]);
    const [selectedState, setSelectedState] = useState<IState>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: stateInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            isoCode: Yup.string().required('Please enter the ISO code')
        }),
        onSubmit: async (values: IState, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            let response;
            if (values.stateId) response = await updateState(values);
            else response = await createState(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast(' State information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, [filteredStateId]);

    const getData = async () => {
        setLoading(true);

        let result;
        if (filteredStateId) result = await getState(filteredStateId);
        else result = await getStates();

        try {
            if (result.success) {
                setStates(Array.isArray(result.data) ? result.data : [result.data]);
            } else errorToast(result.message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (state: IState) => {
        setSelectedState(state);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedState(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (state: IState) => {
        setSelectedState(state);
        formValidation.setValues({ ...state });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (state: IState) => {
        setSelectedState(state);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteState(selectedState?.stateId!);

        if (success) {
            await getData();
            sucessToast(' State deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedState(undefined);
    };

    return {
        states,
        loading,
        selectedState,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        filteredStateId,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminStateList;
