import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import LineChart from '@src/components/LineChart';

const AdminDashboard = () => {
    document.title = 'Dashboard | Connections';

    const series = [
        {
            name: 'Desktops',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }
    ];

    const xaxis = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>Admin Dashboard</h2>
                                </div>
                                <div className='mt-6'>
                                    <Row>
                                        <Col lg={6}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className='card-title mb-0'>New users this week</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <LineChart
                                                        title=''
                                                        series={series}
                                                        xaxis={xaxis}
                                                        colors={['--vz-primary']}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col lg={6}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className='card-title mb-0'>New AI Assistants this weeks</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <LineChart
                                                        title=''
                                                        series={series}
                                                        xaxis={xaxis}
                                                        colors={['--vz-primary']}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className='card-title mb-0'>New Leads this week</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <LineChart
                                                        title=''
                                                        series={series}
                                                        xaxis={xaxis}
                                                        colors={['--vz-primary']}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col lg={6}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className='card-title mb-0'>New Threads this weeks</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <LineChart
                                                        title=''
                                                        series={series}
                                                        xaxis={xaxis}
                                                        colors={['--vz-primary']}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdminDashboard;
