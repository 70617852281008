import { useEffect, useState } from 'react';
import { errorToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import IBot from '@src/interfaces/IBot';
import { getBots } from '@src/services/BotService';

const useAdminBotsList = () => {
    const setLoading = globalStore((state) => state.setLoading);
    const [bots, setBots] = useState<Array<IBot>>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const { success, data, message } = await getBots();

            if (success) {
                setBots(data);
            } else errorToast(message);
            setLoading(false);
        } catch (error) {}
    };

    return {
        bots
    };
};

export default useAdminBotsList;
