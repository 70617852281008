import { CustomTable } from '@src/components/CustomTable';
import ISubscription from '@src/interfaces/ISubscription';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import IUser from '@src/interfaces/IUser';
import { getSubscriptions } from '@src/services/PaymentService';
import globalStore from '@src/stores/globalStore';
import { capitalize, errorToast } from '@src/utils/helpers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Col, Row, TabPane } from 'reactstrap';

interface ISubscriptionsTab {
    user?: IUser;
}
const SubscriptionsTab: React.FC<ISubscriptionsTab> = ({ user }) => {
    const setLoading = globalStore((state) => state.setLoading);
    const [subscriptions, setSubscriptions] = useState<Array<ISubscriptionProduct>>([]);

    const columnHelper = createColumnHelper<ISubscription>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Created',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY')
        },
        {
            header: 'Description',
            accessorKey: 'subscriptionProduct.description'
        },
        {
            header: 'AI Assistant',
            accessorKey: 'bot.name'
        },
        columnHelper.display({
            header: 'Status',
            cell: ({ row }) => {
                return (
                    <div className='d-flex align-items-center'>
                        <Badge color={row.original.isActive ? 'success' : 'danger'}>
                            {row.original.isActive ? 'Active' : 'Inactive'}
                        </Badge>
                        {!!row.original.canceledAt && row.original.isActive && (
                            <Badge color='light' className='text-muted fs-12 ms-2 d-flex align-items-center'>
                                <i className='ri-time-line me-2'></i>Cancels{' on '}
                                {moment(row.original.nextChargeDate).format('MMM D')}
                            </Badge>
                        )}
                    </div>
                );
            }
        }),

        {
            header: 'Amount',
            accessorKey: 'subscriptionProduct.price',
            cell: (value) => `$${value.getValue()}`
        },
        {
            header: 'Interval',
            accessorKey: 'subscriptionProduct.recurringIntervalDescription',
            cell: (value) => capitalize(value.getValue())
        },
        columnHelper.display({
            header: 'Current Period',
            cell: ({ row }) => {
                return (
                    <div className='d-flex align-items-center'>
                        <span className='ms-2'>
                            {moment(row.original.nextChargeDate)
                                .subtract(row.original.subscriptionProduct.recurringInterval, 'days')
                                .format('MM/DD/YYYY')}
                            {' to '}
                            {moment(row.original.nextChargeDate).format('MM/DD/YYYY')}
                        </span>
                    </div>
                );
            }
        })
    ];

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        try {
            const { success, data, message } = await getSubscriptions(user?.userId!);
            if (success) {
                setSubscriptions(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    return (
        <TabPane tabId='3'>
            <Row>
                <Col lg={12}>
                    <CustomTable data={subscriptions} columns={columns} />
                </Col>
            </Row>
        </TabPane>
    );
};

export default SubscriptionsTab;
