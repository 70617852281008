import IBot from '@src/interfaces/IBot';
import { IObj } from '@src/interfaces/IGeneric';
import IMessage from '@src/interfaces/IMessage';
import IThread from '@src/interfaces/IThread';
import { getBot } from '@src/services/BotService';
import { getMessagesByThread } from '@src/services/MessageService';
import { getThreadsByBot } from '@src/services/ThreadService';
import globalStore from '@src/stores/globalStore';
import { errorToast } from '@src/utils/helpers';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useMessages = () => {
    let { botId } = useParams();
    if (!botId?.includes('asst_')) botId = 'asst_' + botId;

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const [bot, setBot] = useState<IBot>(useLocation().state?.bot);
    const [threads, setThreads] = useState<Array<IThread>>([]);
    const [messages, setMessages] = useState<Array<IMessage>>([]);
    const [messagesCache, setMessagesCache] = useState<IObj>({});
    const [currentThread, setCurrentThread] = useState<IThread>();

    useEffect(() => {
        if (bot) getThreadsData();
        else getBotData();
    }, [bot]);

    useEffect(() => {
        if (currentThread) getMessagesData(currentThread.threadId);
    }, [currentThread]);

    const getBotData = async () => {
        setLoading(true);
        const { success, data, message } = await getBot(botId!);
        if (success) {
            setBot(data);
        } else {
            errorToast(message);
        }
        setLoading(false);
    };

    const getThreadsData = async () => {
        setLoading(true);

        const { success, data, message } = await getThreadsByBot(bot.botId!);
        if (success) {
            setThreads(data);
            if (data.length) setCurrentThread(data[0]);
        } else {
            errorToast(message);
        }
        setLoading(false);
    };

    const getMessagesData = async (threadId: string) => {
        setLoading(true);
        if (messagesCache[threadId]) {
            setMessages(messagesCache[threadId]);
            setLoading(false);
            return;
        }

        const { success, data, message } = await getMessagesByThread(threadId);
        if (success) {
            setMessages(data);
            setMessagesCache({ ...messagesCache, [threadId]: data });
        } else errorToast(message);
        setLoading(false);
    };

    return { threads, currentThread, messages, bot, loading, setCurrentThread };
};

export default useMessages;
