import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Formik formValidation
import { useFormik } from 'formik';
import * as Yup from 'yup';

import IUser from '@src/interfaces/IUser';
import { login } from '@src/services/UserService';
import registrationStore from '@src/stores/registrationStore';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData } from '@src/utils/helpers';
import { loginInitValues } from '@src/utils/initValues';

const useLogin = () => {
    const setRegistrationUser = registrationStore((state) => state.setRegistrationUser);
    const [passwordShow, setPasswordShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formValidation = useFormik({
        initialValues: loginInitValues,
        validationSchema: Yup.object({
            email: Yup.string().email('Please enter a valid email address').required('Please Enter Your Email'),
            password: Yup.string().required('Please Enter Your Password')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setErrorMsg('');
            console.log('submit', values);

            try {
                const { data, success, message } = await login(values.email, values.password);
                if (success) {
                    localStorage.setItem(globals.userSessionKey, encryptData(data));
                    //Clean registration user
                    setRegistrationUser({} as IUser);
                    if (!data.questionanaire) navigate(RoutesName.questionnaire, { replace: true });
                    else navigate(RoutesName.dashboard, { replace: true });
                } else {
                    setErrorMsg(message);
                }
            } catch (error: any) {
                setErrorMsg(error.message);
            }
            setLoading(false);
        }
    });
    return { formValidation, errorMsg, loading, passwordShow, setPasswordShow };
};

export default useLogin;
