import { Alert, Col, Row, TabPane } from 'reactstrap';
import { CustomTable } from '@src/components/CustomTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { errorToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import IAnswer from '@src/interfaces/IAnswer';
import IUser from '@src/interfaces/IUser';
import IQuestion from '@src/interfaces/IQuestion';
import { getQuestionnaire } from '@src/services/QuestionnaireService';

interface IQuestionnaireTab {
    user?: IUser;
}
const QuestionnaireTab: React.FC<IQuestionnaireTab> = ({ user }) => {
    const setLoading = globalStore((state) => state.setLoading);
    const [questions, setQuestions] = useState<Array<IQuestion>>([]);
    const columnHelper = createColumnHelper<IAnswer>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Created',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY')
        },
        columnHelper.display({
            header: 'Question',
            cell: ({ row }) => {
                const question = questions.find((q) => q.questionId === row.original.questionId);
                return question?.question;
            }
        }),
        {
            header: 'Answer',
            accessorKey: 'answer'
        }
    ];

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        try {
            const { success, data, message } = await getQuestionnaire();
            if (success) {
                setQuestions(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    return (
        <TabPane tabId='5'>
            <Row>
                <Col lg={12}>
                    {!user?.questionanaire ? (
                        <Alert color='warning' className='text-center'>
                            User has not answer the questionnaire
                        </Alert>
                    ) : (
                        <CustomTable data={user?.questionanaire || []} columns={columns} />
                    )}
                </Col>
            </Row>
        </TabPane>
    );
};

export default QuestionnaireTab;
