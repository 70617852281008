import { Navigate } from 'react-router-dom';
import { RoutesName } from '@src/utils/enums';
import { Container } from 'reactstrap';
import globalStore from '@src/stores/globalStore';
import Loader from '@src/components/Loader';
import Sidebar from './Sidebar';
import { getLoggedUser } from '@src/utils/helpers';

const AdminLayout = (props: any) => {
    const loading = globalStore((state) => state.loading);
    const loggedUser = getLoggedUser(true);

    if (!loggedUser?.isAdmin) {
        return <Navigate to={{ pathname: RoutesName.adminRoot }} />;
    }

    return (
        <div className='main-content'>
            <Sidebar layoutType={'vertical'} />
            <Loader show={loading} />
            <div className='page-content'>
                <Container fluid>{props.children}</Container>
            </div>
        </div>
    );
};

export default AdminLayout;
