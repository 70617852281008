import { IObj } from '@src/interfaces/IGeneric';
import IUser, { IInvite, TInvitedUser } from '@src/interfaces/IUser';
import HttpService from '@src/utils/HttpService';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, objToCamel, objToSnake } from '@src/utils/helpers';
import { NavigateFunction } from 'react-router-dom';
import { auth, signInWithEmailAndPassword, signOut } from './Firebase';

// const DEFAULT_RESPONSE = { data: null as any, success: false, message: '' };
export const createUser = async (user: IUser, isAdmin = false) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = '/users/create' + (isAdmin ? '/admin' : '');
        const formatApiData = objToSnake(user);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IUser;
        console.log('formatApiResponse', formatApiResponse);
        //Let auto login the user if not admin
        if (!isAdmin) await signInWithEmailAndPassword(auth, user.email, user.password);
        response.data = formatApiResponse as IUser;
        response.success = true;
    } catch (error: any) {
        console.log(error);
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const ImportUser = async (user: IUser) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = '/users/import';
        user.isVerified = false;
        const formatApiData = objToSnake(user);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IUser;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IUser;
        response.success = true;
    } catch (error: any) {
        console.log(error);
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getUser = async (userId: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IUser;
        formatApiResponse.fullAddress = formatApiResponse.address;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getUserUsageBalance = async (userId: string) => {
    const response = { data: 0, success: false, message: '' };

    try {
        const endPoint = `/users/usage-balance/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getPublicUser = async (userId: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/public/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IUser;
        formatApiResponse.fullAddress = formatApiResponse.address;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getUsers = async (onlyAdmins = false) => {
    const response = { data: [] as Array<IUser>, success: false, message: '' };

    try {
        const endPoint = `/users` + (onlyAdmins ? '/admins' : '');

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => {
            const tempUser = objToCamel(item) as IUser;
            tempUser.fullAddress = item.address;
            return tempUser;
        });
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as Array<IUser>;

        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updatePublicUser = async (user: IUser) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/public/update/${user.userId}`;
        const formatApiData = objToSnake(user) as IObj;
        console.log('data sent', formatApiData);

        formatApiData.roles = formatApiData.roles?.map((item: any) => item.role_id);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data) as IUser;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateUser = async (user: IUser) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/update/${user.userId}`;
        const formatApiData = objToSnake(user) as IObj;
        console.log('data sent', formatApiData);

        formatApiData.roles = formatApiData.roles?.map((item: any) => item.role_id);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data) as IUser;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateUserFields = async (userId: string, fields: IObj) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/update/${userId}`;
        const formatApiData = objToSnake(fields) as IObj;
        console.log('data sent', formatApiData);

        const { data } = await HttpService.patch(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data) as IUser;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteUser = async (userId: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/${userId}`;

        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data) as IUser;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const login = async (email: string, password: string, isAdmin = false) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const { user } = await signInWithEmailAndPassword(auth, email, password);

        //Get and save firebase auth jwt
        const authToken = await user.getIdToken();
        localStorage.setItem(globals.authTokenKey, encryptData(authToken));

        let userResponse;
        if (isAdmin) userResponse = await getUser(user.uid);
        else userResponse = await getPublicUser(user.uid);

        if (userResponse.success) {
            if (isAdmin && !userResponse.data.isAdmin) throw new Error('Invalid credentials');

            response.data = userResponse.data;
            response.success = true;
        } else {
            throw new Error('Invalid credentials');
        }
    } catch (error: any) {
        console.error(error);
        signOut(auth);
        localStorage.clear();
        if (error.message.includes('auth/invalid-credential')) response.message = 'Invalid credentials';
        else response.message = error.message.replace('Firebase:', '') || 'Unable to connect to the api';
    }

    return response;
};

export const sendResetPasswordEmail = async (email: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = '/users/sendResetPasswordEmail';
        const { data } = await HttpService.post(endPoint, { email });
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const resetPassword = async (token: string, password: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/resetPassword`;
        const { data } = await HttpService.put(endPoint, { token, password });
        const formatApiResponse = objToCamel(data) as IUser;
        await signInWithEmailAndPassword(auth, formatApiResponse.email, password);
        response.message = 'Password reset successfully, wait to be redirected';
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const resendVerificationEmail = async (email: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = '/users/resendVerificationEmail';
        const { data } = await HttpService.post(endPoint, { email });
        console.log('data', data);
        response.data = data;
        response.message = 'Verification email sent successfully';
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const resendEmailCode = async (userId: string) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = `/users/${userId}/sendVerificationEmail`;
        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const sendPhoneCode = async (phone: string) => {
    const response = { data: '', success: false, message: '' };

    try {
        const endPoint = '/phones/send-without-user';
        const { data } = await HttpService.post(endPoint, { phone });
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const resendPhoneCode = async (token = '', phone = '') => {
    const response = { data: '', success: false, message: '' };

    try {
        const sendData = token ? { token } : { phone };
        const endPoint = `/phones/resend`;
        const { data } = await HttpService.post(endPoint, sendData);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const validatePhoneCode = async (token = '', code: string) => {
    const response = { data: false, success: false, message: '' };

    try {
        const endPoint = `/phones/validate`;
        const { data } = await HttpService.post(endPoint, { token, code });
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const logout = async (navigate: NavigateFunction, isAdmin = false) => {
    try {
        localStorage.clear();
        signOut(auth);
    } catch (error: any) {}
    if (isAdmin) navigate(RoutesName.adminLogin, { replace: true });
    else navigate(RoutesName.root, { replace: true });
};

export const sendInvite = async (contacts: IInvite) => {
    const response = { data: false, success: false, message: '' };

    try {
        const endPoint = '/invites/send';
        const { data } = await HttpService.post(endPoint, contacts);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getUserInvitesAccepted = async (userId: string) => {
    const response = { data: [] as Array<TInvitedUser>, success: false, message: '' };

    try {
        const endPoint = `/users/${userId}/invitesAccepted`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as TInvitedUser);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as Array<TInvitedUser>;

        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const scrapeUserWebsite = async (url: string, uniqueId: string) => {
    const response = { data: {} as any, success: false, message: '' };

    try {
        const endPoint = `/scrapper`;
        const { data } = await HttpService.post(endPoint, { url, uniqueId });
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
