import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { Link } from 'react-router-dom';
import useAdminQuestions from './useAdminQuestions';
import { CustomTable } from '@src/components/CustomTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import QuestionsInfoModal from './partials/QuestionsInfoModal';
import QuestionsAddEditModal from './partials/QuestionsAddEditModal';
import moment from 'moment-timezone';
import { FormButton } from '@src/components/FormButton';
import IQuestion from '@src/interfaces/IQuestion';
import OkCancelModal from '@src/components/OkCancelModal';

const AdminQuestions = () => {
    document.title = 'Questionnaire Questions| Connections';

    const {
        selectedQuestion,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        loading,
        formValidation,
        questions,
        setShowInfoModal,
        onAddClick,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    } = useAdminQuestions();

    const columnHelper = createColumnHelper<IQuestion>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Question',
            accessorKey: 'question'
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: (value) => <span className='text-truncate-cell'>{value.getValue()}</span>
        },
        {
            header: 'Order',
            accessorKey: 'order',
            cell: (value) => <span className='text-truncate-cell'>{value.getValue()}</span>
        },
        {
            header: 'Created At',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        columnHelper.display({
            header: 'Actions',
            size: 100,
            cell: ({ row }) => (
                <ul className='list-inline hstack gap-2 mb-0'>
                    <li className='list-inline-item'>
                        <Link to='#' onClick={() => onInfoClick(row.original)} className='text-primary d-inline-block'>
                            <i className='ri-eye-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item edit'>
                        <Link
                            to='#'
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onEditClick(row.original)}
                        >
                            <i className='ri-pencil-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            className='text-danger d-inline-block remove-item-btn'
                            onClick={() => onDeleteClick(row.original)}
                        >
                            <i className='ri-delete-bin-5-fill fs-16'></i>
                        </Link>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>Questionaire</h2>
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <div className='flex-shrink-0'>
                                        <FormButton onClick={onAddClick}>
                                            <i className='ri-add-line align-bottom'></i> Add New
                                        </FormButton>
                                    </div>
                                </div>
                                <CustomTable data={questions} columns={columns} className='mt-3' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <QuestionsInfoModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                selectedItem={selectedQuestion}
            />

            <QuestionsAddEditModal
                show={showAddEditModal}
                onHide={() => setShowAddEditModal(false)}
                loading={loading}
                formValidation={formValidation}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br />"<b>${selectedQuestion?.question}</b>"?`}
                subTitle="If you continue, you won't be able to recover the information of this record."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </React.Fragment>
    );
};

export default AdminQuestions;
