import logo from '@src/assets/images/logo_connections.png';
import { globals } from '@src/utils/constants';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

//Import Components

import AdminProfileDropdown from '@src/components/AdminProfileDropdown';
import { MenuItems, RoutesName, SubMenuItems } from '@src/utils/enums';
import { trimAll } from '@src/utils/helpers';
import { capitalize } from 'lodash';
import { Container } from 'reactstrap';
import VerticalLayout from './VerticalLayout';

const Sidebar = ({ layoutType = 'vertical' }) => {
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState('');
    const menuItems = [
        {
            label: 'Menu',
            isHeader: true
        },
        {
            id: trimAll(MenuItems.dashboard),
            label: capitalize(MenuItems.dashboard),
            icon: 'mdi mdi-speedometer',
            link: RoutesName.adminRoot,
            stateVariables: selectedMenu === MenuItems.dashboard,
            click: (e: any) => onMenuClick(e, MenuItems.dashboard)
        },
        {
            id: trimAll(MenuItems.users),
            label: capitalize(MenuItems.users),
            icon: 'ri-user-line',
            link: '/#',
            stateVariables: selectedMenu === MenuItems.users,
            click: (e: any) => onMenuClick(e, MenuItems.users),
            subItems: [
                {
                    id: trimAll(SubMenuItems.adminsList),
                    label: capitalize(SubMenuItems.adminsList),
                    link: RoutesName.adminUsers,
                    parentId: trimAll(MenuItems.users)
                },
                {
                    id: trimAll(SubMenuItems.clientsList),
                    label: capitalize(SubMenuItems.clientsList),
                    link: RoutesName.adminClients,
                    parentId: trimAll(MenuItems.users)
                },
                {
                    id: trimAll(SubMenuItems.leadsList),
                    label: capitalize(SubMenuItems.leadsList),
                    link: RoutesName.adminLeads,
                    parentId: trimAll(MenuItems.users)
                },
                {
                    id: trimAll(SubMenuItems.importUser),
                    label: capitalize(SubMenuItems.importUser),
                    link: RoutesName.adminImportUser,
                    parentId: trimAll(MenuItems.users)
                }
            ]
        },
        {
            id: trimAll(MenuItems.questionnaire),
            label: capitalize(MenuItems.questionnaire),
            icon: 'ri-survey-line',
            link: '/#',
            stateVariables: selectedMenu === MenuItems.questionnaire,
            click: (e: any) => onMenuClick(e, MenuItems.questionnaire),
            subItems: [
                {
                    id: trimAll(SubMenuItems.questions),
                    label: capitalize(SubMenuItems.questions),
                    link: RoutesName.adminQuestions,
                    parentId: trimAll(MenuItems.questionnaire)
                }
            ]
        },
        {
            id: trimAll(MenuItems.bots),
            label: MenuItems.bots,
            icon: 'mdi mdi-robot-outline',
            link: '/#',
            stateVariables: selectedMenu === MenuItems.bots,
            click: (e: any) => onMenuClick(e, MenuItems.bots),
            subItems: [
                {
                    id: trimAll(SubMenuItems.botList),
                    label: capitalize(SubMenuItems.botList),
                    link: RoutesName.adminBotsList,
                    parentId: trimAll(MenuItems.bots)
                },
                {
                    id: trimAll(SubMenuItems.botCategories),
                    label: capitalize(SubMenuItems.botCategories),
                    link: RoutesName.adminBotCategories,
                    parentId: trimAll(MenuItems.bots)
                },
                {
                    id: trimAll(SubMenuItems.botSubCategories),
                    label: capitalize(SubMenuItems.botSubCategories),
                    link: RoutesName.adminBotSubCategories,
                    parentId: trimAll(MenuItems.bots)
                },
                {
                    id: trimAll(SubMenuItems.botPrompts),
                    label: capitalize(SubMenuItems.botPrompts),
                    link: RoutesName.adminBotPrompts,
                    parentId: trimAll(MenuItems.bots)
                }
            ]
        },
        globals.showMunicipalityOptions
            ? {
                  id: trimAll(MenuItems.municipalities),
                  label: capitalize(MenuItems.municipalities),
                  icon: 'mdi mdi-city-variant-outline',
                  link: '/#',
                  stateVariables: selectedMenu === MenuItems.municipalities,
                  click: (e: any) => onMenuClick(e, MenuItems.municipalities),
                  subItems: [
                      {
                          id: trimAll(SubMenuItems.states),
                          label: capitalize(SubMenuItems.states),
                          link: RoutesName.adminStates,
                          parentId: trimAll(MenuItems.municipalities)
                      },
                      {
                          id: trimAll(SubMenuItems.municipalities),
                          label: capitalize(SubMenuItems.municipalities),
                          link: RoutesName.adminMunicipalities,
                          parentId: trimAll(MenuItems.municipalities)
                      },
                      {
                          id: trimAll(SubMenuItems.municipalitiesRequests),
                          label: capitalize(SubMenuItems.municipalitiesRequests),
                          link: RoutesName.adminMunicipalitiesRequests,
                          parentId: trimAll(MenuItems.municipalities)
                      }
                  ]
              }
            : {}
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const initMenu = () => {
            let pathName = location.pathname;
            const pathNameSegments = location.pathname.split('/');
            if (pathNameSegments.length > 3) {
                pathName = pathNameSegments.slice(0, 3).join('/');
            }

            const ul = document.getElementById('navbar-nav');
            const items = ul?.getElementsByTagName('a');
            const itemsArray = [...items!]; // converts NodeList to Array
            removeActivation(itemsArray);
            const matchingMenuItem = itemsArray.find((x) => {
                return pathName === x.pathname;
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [location.pathname]);

    const onMenuClick = (e: any, item: MenuItems) => {
        e.preventDefault();
        setSelectedMenu(selectedMenu === item ? '' : item);
    };

    function activateParentDropdown(item: HTMLElement) {
        item.classList.add('active');
        const parentCollapseDiv = item.closest('.collapse.menu-dropdown');

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement?.children[0].classList.add('active');
            parentCollapseDiv.parentElement?.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement?.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse')?.classList.add('show');
                if (parentCollapseDiv.parentElement.closest('.collapse')?.previousElementSibling)
                    parentCollapseDiv.parentElement
                        .closest('.collapse')
                        ?.previousElementSibling?.classList.add('active');
                if (
                    parentCollapseDiv.parentElement.closest('.collapse')?.previousElementSibling?.closest('.collapse')
                ) {
                    parentCollapseDiv?.parentElement
                        ?.closest('.collapse')
                        ?.previousElementSibling?.closest('.collapse')
                        ?.classList.add('show');
                    parentCollapseDiv?.parentElement
                        ?.closest('.collapse')
                        ?.previousElementSibling?.closest('.collapse')
                        ?.previousElementSibling?.classList.add('active');
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items: Array<HTMLElement>) => {
        const actiItems = items.filter((x) => x.classList.contains('active'));

        actiItems.forEach((item) => {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', 'false');
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', 'false');
            }
            item.classList.remove('active');
        });
    };

    const addEventListenerOnSmHoverMenu = () => {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        } else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
    };
    return (
        <React.Fragment>
            <div className='app-menu navbar-menu'>
                <div className='navbar-brand-box d-flex align-items-center'>
                    <Link to={RoutesName.adminRoot} className='logo logo-light'>
                        <span className='logo-lg'>
                            <img src={logo} alt='' height='57' />
                        </span>
                    </Link>

                    <button
                        onClick={addEventListenerOnSmHoverMenu}
                        type='button'
                        className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
                        id='vertical-hover'
                    >
                        <i className='ri-record-circle-line'></i>
                    </button>
                    <AdminProfileDropdown />
                </div>

                <React.Fragment>
                    <SimpleBar id='scrollbar' className='h-100'>
                        <Container fluid>
                            <div id='two-column-menu'></div>
                            <ul className='navbar-nav' id='navbar-nav'>
                                <VerticalLayout layoutType={layoutType} menuItems={menuItems} />
                            </ul>
                        </Container>
                    </SimpleBar>
                    <div className='sidebar-background'></div>
                </React.Fragment>
            </div>
            <div className='vertical-overlay'></div>
        </React.Fragment>
    );
};

export default Sidebar;
