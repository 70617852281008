import React, { useEffect, useState } from 'react';
import { Card, CardBody, Nav, TabContent } from 'reactstrap';

import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import { getPaymentMethods } from '@src/services/PaymentService';
import globalStore from '@src/stores/globalStore';
import { errorToast, getLoggedUser } from '@src/utils/helpers';

import TabLink from '@src/components/TabLink';
import { getStripeUser } from '@src/services/PaymentService';
import { useLocation } from 'react-router-dom';
import BillingHistoryTab from './partials/BillingHistoryTab';
import OverviewTab from './partials/OverviewTab';
import PaymentMethodsTab from './partials/PaymentMethodsTab';
import SubscriptionsTab from './partials/SubscriptionsTab';

const BillingScreen = () => {
    document.title = 'Billing | Connections';

    const loggedUser = getLoggedUser();
    const setLoading = globalStore((state) => state.setLoading);
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [customActiveTab, setcustomActiveTab] = useState(query.get('tab') || '1');
    const [paymentMethods, setPaymentMethods] = useState<IStripePaymentMethod[]>([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<IStripePaymentMethod>();
    const [refreshBillingHistoryData, setRefreshBillingHistoryData] = useState<boolean>(false);

    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (refreshBillingHistoryData) setRefreshBillingHistoryData(false);
    }, [refreshBillingHistoryData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        console.log(params.has('tab'));
        if (params.has('tab')) {
            const tab = params.get('tab') || '1';
            console.log(tab);
            if (customActiveTab !== tab) {
                setcustomActiveTab(tab);
            }
        }
    }, [location]); // Re-run the effect when 'location' changes

    const getData = async () => {
        setLoading(true);
        const responses = await Promise.all([
            getPaymentMethods(loggedUser.stripeCustomerId!),
            getStripeUser(loggedUser.stripeCustomerId!)
        ]);
        try {
            const paymentMethodsResponse = responses[0];
            const stripeUserResponse = responses[1];

            if (paymentMethodsResponse.success) {
                console.log(paymentMethodsResponse.data);
                const tempPaymentMethods = paymentMethodsResponse.data.reverse();
                const defaultIndex = tempPaymentMethods.findIndex(
                    (pm) => pm.id == stripeUserResponse.data.invoiceSettings.defaultPaymentMethod
                );
                if (defaultIndex > -1) {
                    const defaultPM = tempPaymentMethods.splice(defaultIndex, 1)[0];
                    tempPaymentMethods.unshift(defaultPM);
                    setDefaultPaymentMethod(defaultPM);
                }
                setPaymentMethods(tempPaymentMethods);
            } else {
                errorToast(paymentMethodsResponse.message);
            }
        } catch (error) {}

        setLoading(false);
    };

    return (
        <React.Fragment>
            {/* <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div> */}
            {/* <div className='pt-4 pb-lg-4 profile-wrapper'>
                <Row className='g-4'>
                    <Col>
                        <div className='p-2'>
                            <h3 className='text-white'>Billing</h3>
                        </div>
                    </Col>
                </Row>
            </div> */}

            <div className='row page-titles mx-0'>
                <div className='col-sm-9 p-md-0'>
                    <div className='welcome-tex'>
                        <h4>Billing</h4>
                    </div>
                </div>
            </div>

            <Card>
                <CardBody>
                    <Nav tabs className='nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3'>
                        <TabLink
                            index='1'
                            label='Overview'
                            customActiveTab={customActiveTab}
                            toggleCustom={toggleCustom}
                        />
                        <TabLink
                            index='2'
                            label='Payment Methods'
                            customActiveTab={customActiveTab}
                            toggleCustom={toggleCustom}
                        />
                        <TabLink
                            index='3'
                            label='Subscriptions'
                            customActiveTab={customActiveTab}
                            toggleCustom={toggleCustom}
                        />
                        <TabLink
                            index='4'
                            label='Billing History'
                            customActiveTab={customActiveTab}
                            toggleCustom={toggleCustom}
                        />
                    </Nav>

                    <TabContent activeTab={customActiveTab} className=''>
                        <OverviewTab
                            defaultPaymentMethod={defaultPaymentMethod}
                            setRefreshBillingHistoryData={setRefreshBillingHistoryData}
                        />
                        <PaymentMethodsTab
                            paymentMethods={paymentMethods}
                            defaultPaymentMethod={defaultPaymentMethod}
                            getData={getData}
                        />
                        <SubscriptionsTab />
                        <BillingHistoryTab paymentMethods={paymentMethods} refreshData={refreshBillingHistoryData} />
                    </TabContent>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default BillingScreen;
