import { FormButton } from '@src/components/FormButton';
import { IMunicipalityRequest } from '@src/interfaces/IMunicipality';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IMunicipalitiesRequestsAddEditModal {
    show: boolean;
    formValidation: FormikProps<IMunicipalityRequest>;
    loading: boolean;
    onHide(): void;
}

const MunicipalitiesRequestsAddEditModal: React.FC<IMunicipalitiesRequestsAddEditModal> = ({
    show,
    formValidation,
    loading,
    onHide
}) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    return (
        <Modal isOpen={show} id='category-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    //To prevent the form to be sent when category press enter when selecting the address
                    if (document.activeElement?.id == 'address') return;
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.municipalityRequestId ? 'Edit' : 'Add'} Municipality</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='isoCode' className='form-label'>
                                Iso Code <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='isoCode'
                                type='text'
                                placeholder='Enter ISO Code'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.isoCode}
                                invalid={formValidation.touched.isoCode && !!formValidation.errors.isoCode}
                            />
                            {formValidation.touched.isoCode && !!formValidation.errors.isoCode && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.isoCode}</div>
                                </FormFeedback>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default MunicipalitiesRequestsAddEditModal;
