import { logout } from '@src/services/UserService';
import { RoutesName, SideBarClientItems } from '@src/utils/enums';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavItem, UncontrolledTooltip } from 'reactstrap';

export const ClientSideBar: React.FC<{}> = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const sidebarMenu = [
        { icon: 'mdi mdi-home-variant-outline', name: SideBarClientItems.home, to: RoutesName.dashboard },
        { icon: 'mdi mdi-file-document-outline', name: SideBarClientItems.billing, to: RoutesName.billing },
        {
            icon: 'mdi mdi-book-information-variant',
            name: SideBarClientItems.questionnaire,
            to: RoutesName.questionnaire
        }
    ];
    const currentPath = location.pathname;
    const activeIndex = sidebarMenu.findIndex((item) => item.to === currentPath);

    const [openSidebar, setOpenSidebar] = useState(false);
    const [activeMenu, setActiveMenu] = useState(activeIndex);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [openSidebar]);

    useEffect(() => {
        setActiveMenu(activeIndex);
    }, [currentPath]);

    const handleResize = () => {
        setIsSidebarVisible(window.innerWidth > 768 || openSidebar);
    };
    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <>
            <div role='button' className='client-side-hamburger-icon' onClick={toggleSidebar}>
                <i className='mdi mdi-menu'></i>
            </div>
            <div className={`deznav`} style={{ display: isSidebarVisible ? 'flex' : 'none' }}>
                <div className='deznav-scroll'>
                    <Nav className='flex-column align-items-end'>
                        {sidebarMenu.map((item, index) => (
                            <>
                                <NavItem
                                    key={index}
                                    id={`client-side-item-${index}`}
                                    className={'py-2 ps-2 w-100 d-flex justify-content-end align-items-center'}
                                    onClick={() => {
                                        setOpenSidebar(false);
                                    }}
                                >
                                    <Link
                                        style={{ width: '100%' }}
                                        className={'p-2 ps-4 ' + (activeMenu === index ? 'active-menu-item' : '')}
                                        to={item.to}
                                        onClick={() => {
                                            setActiveMenu(index);
                                        }}
                                    >
                                        <i className={item.icon + ' fs-24 text-white'}></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip placement='right' target={`client-side-item-${index}`}>
                                    {item.name}
                                </UncontrolledTooltip>
                            </>
                        ))}
                    </Nav>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <hr className='text-white w-100 ' />
                    <div id='side-logout-btn' role='button' className='ai-icon  py-3' onClick={() => logout(navigate)}>
                        <i className='mdi mdi-logout text-white fs-24 align-middle me-1'></i>
                    </div>
                    <UncontrolledTooltip placement='right' target='side-logout-btn'>
                        Logout
                    </UncontrolledTooltip>
                </div>
            </div>
        </>
    );
};
