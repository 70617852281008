export const globals = {
    embedIframeCode: `<iframe src="${process.env.REACT_APP_WEB_URL}/chat/{0}" width="400" height="600" />`,
    embedCode: `<script src="${process.env.REACT_APP_CHAT_URL}/embed.js" data-bot-id="{0}"></script>`,
    fullScreenChatUrl: `${process.env.REACT_APP_WEB_URL}/chat/{0}`,
    invalidAddressMsg: 'Please select a valid address, city, state',
    authTokenKey: '_u8d3Ks',
    userSessionKey: '_JiUdw',
    adminSessionKey: '_eAdlse',
    threadSessionKey: '_d3aK59aew',
    leadSessionKey: '_90Jaeielzmn',
    googleAccessTokenKey: '_iJnx7d89sl',
    perPageBots: 4,
    perPageBotsAdmin: 8,
    perPageTables: 10,
    waitTimeToCloseSession: 10,
    googleContactScope: 'https://www.googleapis.com/auth/contacts.readonly',
    googleContactsEndpoint:
        'https://people.googleapis.com/v1/people/me/connections?pageSize=1000&sortOrder=FIRST_NAME_ASCENDING&personFields=names,emailAddresses,phoneNumbers',
    municipalityAccessRoleId: 'UCBkbPlzpK5RkSjatnlk',
    botMunicipalitySubCategoryId: 'zStT7M1zgKoPyvgebYvM',
    showMunicipalityOptions: false,
    minAmountToRecharge: 20,
    minAmountToRechargeAdmin: 1,
    termsUrl: 'https://connections.site/Terms',
    privacyUrl: 'https://connections.site/Privacy'
};
