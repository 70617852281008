import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from '@src/assets/images/users/user-dummy-img.jpg';
import { logout } from '@src/services/UserService';
import { RoutesName } from '@src/utils/enums';
import { getLoggedUser } from '@src/utils/helpers';
import { useNavigate } from 'react-router-dom';

const ProfileDropdown = () => {
    const loggedUser = getLoggedUser();
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const navigate = useNavigate();

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className='ms-sm-3 header-item topbar-user'
            >
                <DropdownToggle tag='button' type='button' className='btn shadow-none'>
                    <span className='d-flex align-items-center'>
                        <div>
                            <img className='rounded-circle header-profile-user' src={avatar1} alt='Header Avatar' />
                        </div>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='ms-1 user-name-text fs-15'>
                                <b className='text-black'>{loggedUser?.name}</b>
                            </span>
                            <span className='ms-1 fs-11 user-name-text'>Business Profile</span>
                        </div>
                    </span>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                    {/* <h6 className='dropdown-header'>Welcome {loggedUser?.name}!</h6> */}
                    <DropdownItem href={RoutesName.profile}>
                        <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
                        <span className='align-middle'>Profile</span>
                    </DropdownItem>

                    {/* <DropdownItem href={RoutesName.billing}>
                        <i className='mdi mdi-cash text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Billing</span>
                    </DropdownItem>

                    <DropdownItem href={RoutesName.faqs}>
                        <i className='mdi mdi-lifebuoy text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Help</span>
                    </DropdownItem> */}

                    <div className='dropdown-divider'></div>

                    <DropdownItem onClick={() => logout(navigate)}>
                        <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle' data-key='t-logout'>
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
