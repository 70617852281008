import { FormButton } from '@src/components/FormButton';
import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { RoutesName } from '@src/utils/enums';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

interface IBotCategoryInfoModal {
    show: boolean;
    selectedItem?: IBotCategory;
    onHide(): void;
}

const BotCategoryInfoModal: React.FC<IBotCategoryInfoModal> = ({ show, onHide, selectedItem }) => {
    return (
        <Modal isOpen={show} id='category-info-modal' centered toggle={onHide}>
            <ModalBody>
                <div className='d-flex justify-content-between align-items-start'>
                    <div>
                        <h4>{selectedItem?.name}</h4>
                        <Table className='table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Description:
                                    </th>
                                    <td className='text-muted'>{selectedItem?.description}</td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Sub Categories:
                                    </th>
                                    <td className='text-muted'>
                                        {selectedItem?.botSubcategories?.map((subCat: IBotSubCategory) => (
                                            <Link
                                                key={subCat.botSubCategoryId}
                                                to={`${RoutesName.adminBotSubCategories}/${selectedItem.botCategoryId}/${subCat.botSubCategoryId}`}
                                                className='me-1'
                                            >
                                                <Badge color='primary' pill>
                                                    {subCat.name}
                                                </Badge>
                                            </Link>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Instructions:
                                    </th>
                                    <td className='text-muted'>
                                        <div className='prompt-instructions-text'>
                                            {selectedItem?.instructions || 'N/A'}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Created At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.createdAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Updated At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.updatedAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='text-center mb-2'>
                        <i className='mdi mdi-robot-outline  m-2 display-3 text-primary' />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <FormButton value='Aceptar' fitParent={false} onClick={onHide} />
            </ModalFooter>
        </Modal>
    );
};

export default BotCategoryInfoModal;
