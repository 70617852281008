import { FormButton } from '@src/components/FormButton';
import IBotPrompt from '@src/interfaces/IBotPrompt';
import moment from 'moment';
import React from 'react';
import { Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

interface IBotsPromptsInfoModal {
    show: boolean;
    selectedItem?: IBotPrompt;
    onHide(): void;
}

const BotsPromptsInfoModal: React.FC<IBotsPromptsInfoModal> = ({ show, onHide, selectedItem }) => {
    return (
        <Modal isOpen={show} id='category-info-modal' centered toggle={onHide} size='xl'>
            <ModalBody>
                <div className='d-flex justify-content-between align-items-start'>
                    <div>
                        <h4>{selectedItem?.name}</h4>
                        <Table className='table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Instructions:
                                    </th>
                                    <td className='text-muted'>
                                        <div className='prompt-instructions-text'>{selectedItem?.instructions}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Created At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.createdAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Updated At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.updatedAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='text-center mb-2'>
                        <i className='ri-map-2-line  m-2 display-3 text-primary'></i>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <FormButton value='Aceptar' fitParent={false} onClick={onHide} />
            </ModalFooter>
        </Modal>
    );
};

export default BotsPromptsInfoModal;
