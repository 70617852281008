import { FormButton } from '@src/components/FormButton';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import { IMunicipalityRequest } from '@src/interfaces/IMunicipality';
import { FormikProps } from 'formik';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { FormFeedback, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IRequestMunicipalityModal {
    show: boolean;
    loading: boolean;
    requestMuniValidation: FormikProps<IMunicipalityRequest>;
    setShowMunicipalityModal(value: React.SetStateAction<boolean>): void;
    validateAddressField(): boolean | undefined;
    onAddressSelected(place: IGooglePlace): void;
}

const RequestMunicipalityModal: React.FC<IRequestMunicipalityModal> = ({
    show,
    loading,
    setShowMunicipalityModal,
    requestMuniValidation,
    validateAddressField,
    onAddressSelected
}) => (
    <Modal isOpen={show} id='new-municipality-modal' centered toggle={() => setShowMunicipalityModal(false)}>
        <form
            id='request-form'
            onSubmit={(e) => {
                e.preventDefault();
                //To prevent the form to be sent when user press enter when selecting the address
                if (document.activeElement?.id == 'address-municipality') return;
                requestMuniValidation.handleSubmit();
                return false;
            }}
            action='#'
        >
            <ModalBody>
                <div className='mt-4 pt-4'>
                    <h4>Request to add a new municipality</h4>

                    <p className='text-muted'>
                        Search and select the municipality you would like to request to be added
                    </p>
                    <div className='text-center mb-2'>
                        <i className='ri-community-line m-2 display-3 text-primary' />
                    </div>
                    <div className='mb-3'>
                        <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            id='address-municipality'
                            name='address-municipality'
                            className={'form-control ' + (validateAddressField() && 'is-invalid')}
                            placeholder='Enter the municipality'
                            onPlaceSelected={onAddressSelected}
                            options={{
                                componentRestrictions: { country: 'us' },
                                fields: ['address_components', 'formatted_address'],
                                types: ['geocode']
                            }}
                        />
                        {validateAddressField() && (
                            <FormFeedback type='invalid'>
                                {requestMuniValidation.errors.name ||
                                    requestMuniValidation.errors.state ||
                                    requestMuniValidation.errors.zipcode}
                            </FormFeedback>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <FormButton
                    value={'Send Request'}
                    type='submit'
                    loading={loading}
                    fitParent={false}
                    form='request-form'
                />
                <FormButton
                    value='Cancel'
                    className='btn btn-light'
                    onClick={() => setShowMunicipalityModal(false)}
                    fitParent={false}
                />
            </ModalFooter>
        </form>
    </Modal>
);

export default RequestMunicipalityModal;
