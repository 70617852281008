import { IGHLContact } from '@src/interfaces/IGHLContact';
import { IRegistrationStore } from '@src/interfaces/IStore';
import { IRegistrationUser } from '@src/interfaces/IUser';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export default create<IRegistrationStore>()(
    persist(
        (set) => ({
            registrationUser: {} as IRegistrationUser,
            ghlContact: {} as IGHLContact,
            setRegistrationUser: (user: IRegistrationUser) => set(() => ({ registrationUser: user })),
            setGHLContact: (contact: IGHLContact) => set(() => ({ ghlContact: contact }))
        }),
        {
            name: 'registration-storage' // unique name
        }
    )
);
