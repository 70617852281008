import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IQuestion from '@src/interfaces/IQuestion';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IQuestionsAddEditModal {
    show: boolean;
    formValidation: FormikProps<IQuestion>;
    loading: boolean;
    onHide(): void;
}

const QuestionsAddEditModal: React.FC<IQuestionsAddEditModal> = ({ show, formValidation, loading, onHide }) => {
    const options: any[] = [{ label: 'Open Text', value: 'text' }];
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    useEffect(() => {
        if (typeof formValidation.values.type !== 'object') {
            if (!formValidation.values.type) formValidation.setFieldValue('type', options[0]);
            else
                formValidation.setFieldValue(
                    'type',
                    options.find((o) => o.value === formValidation.values.type)
                );
        }
    }, [formValidation.values.type]);

    return (
        <Modal isOpen={show} id='category-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    //To prevent the form to be sent when category press enter when selecting the address
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.questionId ? 'Edit' : 'Add'} Question</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='question' className='form-label'>
                                Question <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='question'
                                type='text'
                                placeholder='Enter the question'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.question}
                                invalid={formValidation.touched.question && !!formValidation.errors.question}
                            />
                            {formValidation.touched.question && !!formValidation.errors.question && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.question}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='type' className='form-label'>
                                Type <span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='type'
                                value={formValidation.values.type}
                                onChange={(type: any) => {
                                    formValidation.setFieldValue('type', type);
                                }}
                                onBlur={formValidation.handleBlur}
                                options={options}
                                classNames={{
                                    control: () =>
                                        formValidation.touched.type && !!formValidation.errors.type
                                            ? 'form-control is-invalid'
                                            : ''
                                }}
                            />
                            {/* To show the error for react-select */}
                            <FormSelectFeedback
                                show={formValidation.touched.type && !!formValidation.errors.type}
                                message={formValidation.errors.type}
                            />
                        </div>

                        {formValidation.values.questionId && (
                            <div className='mb-3'>
                                <Label htmlFor='order' className='form-label'>
                                    Order <span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    name='order'
                                    type='number'
                                    placeholder='Enter the order of this question'
                                    onChange={formValidation.handleChange}
                                    onBlur={formValidation.handleBlur}
                                    value={formValidation.values.order}
                                    invalid={formValidation.touched.order && !!formValidation.errors.order}
                                />
                                {formValidation.touched.order && !!formValidation.errors.order && (
                                    <FormFeedback type='invalid'>
                                        <div>{formValidation.errors.order}</div>
                                    </FormFeedback>
                                )}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default QuestionsAddEditModal;
