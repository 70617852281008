import logoLight from '@src/assets/images/logo_connections.png';
import { RoutesName } from '@src/utils/enums';
import React from 'react';
import { Link } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { Col, Row } from 'reactstrap';
import RegistrationStepOne from './Steps/One/RegistrationStepOne';
import RegistrationStepTwo from './Steps/Two/RegistrationStepTwo';

document.title = ' Sign up | Connections';

export const RegistrationWizard: React.FC = () => {
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-16 fw-semibold'>Register now to start using Connections!</p>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5}>
                    <Wizard startIndex={0}>
                        <RegistrationStepOne />
                        <RegistrationStepTwo />
                    </Wizard>

                    <div className='mt-4 text-center'>
                        <p className='mb-0'>
                            Already have an account?{' '}
                            <Link to={RoutesName.login} className='fw-semibold text-primary text-decoration-underline'>
                                Sign In
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default RegistrationWizard;
