import { useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import globalStore from '@src/stores/globalStore';
import IUser from '@src/interfaces/IUser';
import { getBotsByUser } from '@src/services/BotService';
import IBot from '@src/interfaces/IBot';
import { errorToast } from '@src/utils/helpers';
import { globals } from '@src/utils/constants';

interface IUseBotsTab {
    user?: IUser;
}

const useBotsTab = ({ user }: IUseBotsTab) => {
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const [bots, setBots] = useState<Array<IBot>>([]);
    const [filteredBots, setFilteredBots] = useState<Array<IBot>>();

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        const { success, data, message } = await getBotsByUser(user?.userId!);

        if (success) {
            if (data.length && currentPage == 0) setCurrentPage(1);
            setBots(data);
        } else errorToast(message);
        setLoading(false);
    };

    useEffect(() => {
        const start = (currentPage - 1) * globals.perPageBotsAdmin;
        const end = currentPage * globals.perPageBotsAdmin;
        const filtered = bots?.slice(start, end);
        setFilteredBots(filtered);
    }, [currentPage, bots]);

    return {
        loading,
        bots,
        filteredBots,
        currentPage,
        setCurrentPage
    };
};

export default useBotsTab;
