import { FormButton } from '@src/components/FormButton';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Form, Row, Spinner } from 'reactstrap';
import usePhoneVerification from './usePhoneVerification';

interface IPhoneVerificationProps {
    isEmbed?: boolean;
    phone?: string;
    token?: string;
    afterVerification?: () => void;
}
const PhoneVerification: React.FC<IPhoneVerificationProps> = ({ isEmbed, phone, token, afterVerification }) => {
    document.title = 'Verify phone | Connections';
    const { formValidation, errorMsg, loading, loggedUser, onDigitChange, handleResendPhoneCode } =
        usePhoneVerification({ phone, token, afterVerification });

    document.title = 'Register | Connections';

    return (
        <>
            <div id='request-otp'></div>

            <Row className='justify-content-center'>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        console.log(formValidation.errors);
                        formValidation.handleSubmit();
                        return false;
                    }}
                    className='d-flex justify-content-center'
                    action='#'
                >
                    <Col md={isEmbed ? 12 : 8}>
                        <Card className='mt-4' style={isEmbed ? { boxShadow: 'none' } : {}}>
                            <CardBody className='p-4'>
                                <div className='mb-4'>
                                    <div className='avatar-lg mx-auto'>
                                        <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
                                            <i className='ri-smartphone-line'></i>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2 mt-4'>
                                    <div className='text-muted text-center mb-4 mx-lg-3'>
                                        <h4>Verify Your Phone</h4>
                                        <p>
                                            Please enter the 6 digit code sent to{' '}
                                            <span className='fw-semibold'>{loggedUser?.phone}</span>
                                        </p>
                                    </div>

                                    <Row>
                                        <Col className='col-2'></Col>
                                        <Col className='col-2'>
                                            <Cleave
                                                id='digit1'
                                                name='digit1'
                                                className={
                                                    'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                    (formValidation.touched.digit1 && !!formValidation.errors.digit1
                                                        ? 'is-invalid form-control'
                                                        : '')
                                                }
                                                options={{
                                                    numericOnly: true,
                                                    blocks: [1]
                                                }}
                                                value={formValidation.values.digit1 || ''}
                                                onChange={(e: any) => onDigitChange(e, 2)}
                                            />
                                        </Col>

                                        <Col className='col-2'>
                                            <Cleave
                                                id='digit2'
                                                name='digit2'
                                                className={
                                                    'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                    (formValidation.touched.digit2 && !!formValidation.errors.digit2
                                                        ? 'is-invalid form-control'
                                                        : '')
                                                }
                                                options={{
                                                    numericOnly: true,
                                                    blocks: [1]
                                                }}
                                                value={formValidation.values.digit2 || ''}
                                                onChange={(e: any) => onDigitChange(e, 3)}
                                            />
                                        </Col>

                                        <Col className='col-2'>
                                            <Cleave
                                                id='digit3'
                                                name='digit3'
                                                className={
                                                    'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                    (formValidation.touched.digit3 && !!formValidation.errors.digit3
                                                        ? 'is-invalid form-control'
                                                        : '')
                                                }
                                                options={{
                                                    numericOnly: true,
                                                    blocks: [1]
                                                }}
                                                value={formValidation.values.digit3 || ''}
                                                onChange={(e: any) => onDigitChange(e, 4)}
                                            />
                                        </Col>

                                        <Col className='col-2'>
                                            <Cleave
                                                id='digit4'
                                                name='digit4'
                                                className={
                                                    'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                    (formValidation.touched.digit4 && !!formValidation.errors.digit4
                                                        ? 'is-invalid form-control'
                                                        : '')
                                                }
                                                options={{
                                                    numericOnly: true,
                                                    blocks: [1]
                                                }}
                                                value={formValidation.values.digit4 || ''}
                                                onChange={(e: any) => onDigitChange(e, 5)}
                                            />
                                        </Col>
                                    </Row>
                                    {!!errorMsg && (
                                        <Alert color='danger' className='mt-4'>
                                            {errorMsg}
                                        </Alert>
                                    )}

                                    <div className='mt-4 text-center'>
                                        <FormButton
                                            value='Verify your Phone'
                                            fitParent={false}
                                            loading={loading}
                                            type='submit'
                                        />
                                    </div>

                                    <div className='mt-3 text-center'>
                                        <p className='mb-0'>
                                            Didn't receive a code or expired?
                                            <Link
                                                to='#'
                                                onClick={loading ? undefined : handleResendPhoneCode}
                                                className='fw-semibold text-primary text-decoration-underline ms-2'
                                            >
                                                Resend {loading && <Spinner size='sm' className='ms-2'></Spinner>}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Form>
            </Row>
        </>
    );
};

export default PhoneVerification;
