import logoLight from '@src/assets/images/logo_connections.png';
import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import Loader from '@src/components/Loader';
import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import Autocomplete from 'react-google-autocomplete';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import useRegister from './useRegister';

const Register = () => {
    const {
        formValidation,
        errorMsg,
        loading,
        referralObj,
        categories,
        validateAddressField,
        onAddressSelected,
        onAddressChanged
    } = useRegister();

    document.title = 'Register | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-16 fw-semibold'>Create your own assistant for your business!</p>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                        <CardBody className='p-4'>
                            <div className='text-center mt-2'>
                                <h5 className='text-primary'>Create New Account</h5>
                                <p className='text-muted'>Register now to start using Connections!.</p>
                            </div>
                            {!!errorMsg && <Alert color='danger'> {errorMsg} </Alert>}
                            <div className='p-2 mt-4'>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        //To prevent the form to be sent when user press enter when selecting the address
                                        if (document.activeElement?.id == 'address') return;

                                        if (validateAddressField()) formValidation.handleSubmit();
                                        formValidation.setFieldTouched('category', true);
                                        formValidation.setFieldTouched('subCategory', true);

                                        return false;
                                    }}
                                    action='#'
                                >
                                    <div className='mb-3'>
                                        <Label htmlFor='category' className='form-label'>
                                            Category <span className='text-danger'>*</span>
                                        </Label>
                                        <Select
                                            name='category'
                                            value={formValidation.values.category}
                                            onChange={(category) => formValidation.setFieldValue('category', category)}
                                            getOptionLabel={(c: IBotCategory) => c.name}
                                            getOptionValue={(c: IBotCategory) => c.botCategoryId}
                                            options={categories}
                                            onBlur={() => formValidation.setFieldTouched('category', true)}
                                            classNames={{
                                                control: () =>
                                                    formValidation.touched.category && !!formValidation.errors.category
                                                        ? 'form-control is-invalid'
                                                        : ''
                                            }}
                                        />
                                        {/* To show the error for react-select */}
                                        <FormSelectFeedback
                                            show={formValidation.touched.category && !!formValidation.errors.category}
                                            message={formValidation.errors.category}
                                        />
                                    </div>

                                    {!!formValidation.values.category && (
                                        <div className='mb-3'>
                                            <Label htmlFor='subCategory' className='form-label'>
                                                Sub Category <span className='text-danger'>*</span>
                                            </Label>
                                            <Select
                                                name='subCategory'
                                                value={formValidation.values.subCategory}
                                                onChange={(subCategory) =>
                                                    formValidation.setFieldValue('subCategory', subCategory)
                                                }
                                                getOptionLabel={(c: IBotSubCategory) => c.name}
                                                getOptionValue={(c: IBotSubCategory) => c.botSubCategoryId}
                                                options={formValidation.values.category?.botSubcategories || []}
                                                onBlur={() => formValidation.setFieldTouched('subCategory', true)}
                                                classNames={{
                                                    control: () =>
                                                        formValidation.touched.subCategory &&
                                                        !!formValidation.errors.subCategory
                                                            ? 'form-control is-invalid'
                                                            : ''
                                                }}
                                            />
                                            {/* To show the error for react-select */}
                                            <FormSelectFeedback
                                                show={
                                                    formValidation.touched.subCategory &&
                                                    !!formValidation.errors.subCategory
                                                }
                                                message={formValidation.errors.subCategory}
                                            />
                                        </div>
                                    )}

                                    <div className='mb-3'>
                                        <Label htmlFor='name' className='form-label'>
                                            Name <span className='text-danger'>*</span>
                                        </Label>
                                        <Input
                                            name='name'
                                            type='text'
                                            placeholder='Enter your name'
                                            value={formValidation.values.name}
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            invalid={formValidation.touched.name && !!formValidation.errors.name}
                                        />
                                        {formValidation.touched.name && !!formValidation.errors.name && (
                                            <FormFeedback type='invalid'>
                                                <div>{formValidation.errors.name}</div>
                                            </FormFeedback>
                                        )}
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor='phone' className='form-label'>
                                            Phone <span className='text-danger'>*</span>
                                        </Label>
                                        <Cleave
                                            id='phone'
                                            name='phone'
                                            className={
                                                'form-control ' +
                                                (formValidation.touched.phone && !!formValidation.errors.phone
                                                    ? 'is-invalid form-control'
                                                    : '')
                                            }
                                            placeholder='(xxxx) xxx-xxx'
                                            options={{
                                                numericOnly: true,
                                                // prefix: '+1',
                                                // blocks: [2, 3, 3, 4]
                                                delimiterLazyShow: true,
                                                delimiters: ['(', ') ', '-'],
                                                blocks: [0, 3, 3, 4]
                                            }}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            value={formValidation.values.phone || ''}
                                        />
                                        {formValidation.touched.phone && !!formValidation.errors.phone && (
                                            <FormFeedback type='invalid' style={{ display: 'block' }}>
                                                {formValidation.errors.phone}
                                            </FormFeedback>
                                        )}
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor='email' className='form-label'>
                                            Email <span className='text-danger'>*</span>
                                        </Label>
                                        <Input
                                            name='email'
                                            type='email'
                                            className='form-control'
                                            placeholder='Enter email address'
                                            value={formValidation.values.email}
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            invalid={formValidation.touched.email && !!formValidation.errors.email}
                                        />
                                        {formValidation.touched.email && !!formValidation.errors.email && (
                                            <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                                        )}
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor='email' className='form-label'>
                                            Address <span className='text-danger'>*</span>
                                        </Label>

                                        <Autocomplete
                                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                            id='address'
                                            name='address'
                                            className={'form-control ' + (!validateAddressField() && 'is-invalid')}
                                            placeholder='Enter your address'
                                            onPlaceSelected={onAddressSelected}
                                            onChange={onAddressChanged}
                                            options={{
                                                componentRestrictions: { country: 'us' },
                                                types: ['address'],
                                                fields: ['address_components', 'formatted_address']
                                            }}
                                        />
                                        {!validateAddressField() && (
                                            <FormFeedback type='invalid'>{globals.invalidAddressMsg}</FormFeedback>
                                        )}
                                    </div>

                                    <div className='mb-3'>
                                        <Label className='form-label' htmlFor='password'>
                                            Password <span className='text-danger'>*</span>
                                        </Label>
                                        <div className='position-relative auth-pass-inputgroup mb-3'>
                                            <Input
                                                name='password'
                                                value={formValidation.values.password || ''}
                                                type={'password'}
                                                className='form-control pe-5'
                                                placeholder='Enter Password'
                                                onChange={formValidation.handleChange}
                                                onBlur={formValidation.handleBlur}
                                                invalid={
                                                    formValidation.touched.password && !!formValidation.errors.password
                                                }
                                            />
                                            {formValidation.touched.password && !!formValidation.errors.password && (
                                                <FormFeedback type='invalid'>
                                                    {formValidation.errors.password}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor='confirmPassword' className='form-label'>
                                            Confirm Password <span className='text-danger'>*</span>
                                        </Label>
                                        <Input
                                            name='confirmPassword'
                                            type='password'
                                            placeholder='Confirm Password'
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            value={formValidation.values.confirmPassword || ''}
                                            invalid={
                                                formValidation.touched.confirmPassword &&
                                                !!formValidation.errors.confirmPassword
                                            }
                                        />
                                        {formValidation.touched.confirmPassword &&
                                            !!formValidation.errors.confirmPassword && (
                                                <FormFeedback type='invalid'>
                                                    <div>{formValidation.errors.confirmPassword}</div>
                                                </FormFeedback>
                                            )}
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor='referralBy' className='form-label'>
                                            Referral Code
                                        </Label>
                                        <Input
                                            id='referralBy'
                                            name='referralBy'
                                            className='form-control'
                                            placeholder='Add a referral code'
                                            onChange={(e) => {
                                                formValidation.setFieldValue('referralBy', {
                                                    referralCode: e.target.value
                                                });
                                            }}
                                            onBlur={formValidation.handleBlur}
                                            value={formValidation.values.referralBy?.referralCode || ''}
                                            disabled={referralObj?.referral_code}
                                        />
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor='website' className='form-label'>
                                            Website
                                        </Label>
                                        <Input
                                            id='website'
                                            name='website'
                                            className='form-control'
                                            placeholder='Add your website url'
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            value={formValidation.values.website || ''}
                                        />
                                    </div>

                                    <div className='mb-4 mt-4'>
                                        <p className='mb-0 fs-12 text-muted fst-italic'>
                                            By registering you agree to Connections{' '}
                                            <Link
                                                to='#'
                                                className='text-primary text-decoration-underline fst-normal fw-medium'
                                            >
                                                Terms of Use
                                            </Link>
                                        </p>
                                    </div>

                                    <div className='mt-4'>
                                        <FormButton value='Register' type='submit' loading={loading} />
                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>

                    <div className='mt-4 text-center'>
                        <p className='mb-0'>
                            Already have an account ?{' '}
                            <Link to={RoutesName.login} className='fw-semibold text-primary text-decoration-underline'>
                                Sign In
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
            <Loader show={loading} />
        </>
    );
};

export default Register;
