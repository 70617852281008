import OkCancelModal from '@src/components/OkCancelModal';
import PlaceHolder from '@src/components/PlaceHolder';
import IUser from '@src/interfaces/IUser';
import { globals } from '@src/utils/constants';
import { roundNumber } from '@src/utils/helpers';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import Decimal from 'decimal.js';
import React from 'react';
import { Alert, Col, FormFeedback, Row, TabPane } from 'reactstrap';
import ClientsInfo from './ClientsInfo';
import useOverviewTab from './useOverviewTab';

interface IOverviewTab {
    setRefreshBillingHistoryData: (value: boolean) => void;
    user?: IUser;
}

const OverviewTab: React.FC<IOverviewTab> = ({ setRefreshBillingHistoryData, user }) => {
    const {
        rechargeAmount,
        showAddBalance,
        loading,
        autoCharge,
        usageBlance,
        setShowAddBalance,
        onRechargeAmountChange,
        onAddBalance
    } = useOverviewTab({ setRefreshBillingHistoryData, user });

    if (loading)
        return (
            <TabPane tabId='1'>
                <PlaceHolder />
            </TabPane>
        );

    return (
        <>
            <TabPane tabId='1'>
                <Row>
                    <Col>
                        <ClientsInfo user={user} autoChargeEnabled={!!autoCharge} />
                    </Col>
                    <Col>
                        <h4 className='fw-semibold fs-22'>Current Credit balance</h4>
                        <h1 className='text-primary display-1'>${roundNumber(user?.creditBalance || 0, 6)}</h1>
                        <div
                            className='d-flex align-items-center'
                            role='button'
                            onClick={() => setShowAddBalance(true)}
                        >
                            <i className='ri-add-circle-line fs-1 me-2 text-primary'></i> Add Balance
                        </div>
                        <hr />
                        <h4 className='fw-semibold fs-22'>Usage balance</h4>
                        <h1 className='text-warning display-3'>${roundNumber(usageBlance || 0, 6)}</h1>
                    </Col>
                </Row>
            </TabPane>

            <OkCancelModal
                show={showAddBalance}
                type='none'
                yesText='Add balance'
                cancelText='Cancel'
                onAccept={onAddBalance}
                onCancel={() => setShowAddBalance(false)}
                loading={loading}
                title='Add balance'
                subTitle={"This will add more credit balance to this client's account."}
                bodyContent={
                    <div className='mb-3'>
                        <Row className='d-flex justify-content-center mt-2'>
                            <Col className='col-3'>
                                <Cleave
                                    id='recharge-amount'
                                    name='recharge-amount'
                                    className={
                                        'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                        (rechargeAmount < globals.minAmountToRechargeAdmin
                                            ? 'is-invalid form-control'
                                            : '')
                                    }
                                    options={{
                                        delimiterLazyShow: true,
                                        delimiters: ['$'],
                                        blocks: [0, 10],
                                        numericOnly: true
                                    }}
                                    value={rechargeAmount}
                                    onChange={onRechargeAmountChange}
                                />
                            </Col>
                            {rechargeAmount < globals.minAmountToRechargeAdmin && (
                                <FormFeedback type='invalid' style={{ display: 'block' }}>
                                    The minimun amount to add is ${globals.minAmountToRechargeAdmin}
                                </FormFeedback>
                            )}
                        </Row>
                        <Alert color='success' className='w-100 d-flex flex-column align-items-start mt-2'>
                            <span className='text-muted'>
                                Current balance is: &nbsp;&nbsp;&nbsp;
                                <b>${roundNumber(user?.creditBalance || 0, 6)}</b>
                            </span>
                            <span className='text-muted'>
                                New balance will be:&nbsp;
                                <b>
                                    $
                                    {roundNumber(
                                        new Decimal(user?.creditBalance || 0).add(rechargeAmount).toNumber(),
                                        6
                                    )}
                                </b>
                            </span>
                        </Alert>
                    </div>
                }
            />
        </>
    );
};

export default OverviewTab;
