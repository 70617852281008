import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IBotSubCategoryAddEditModal {
    show: boolean;
    formValidation: FormikProps<IBotSubCategory>;
    loading: boolean;
    categories: Array<IBotCategory>;
    onHide(): void;
}

const BotSubCategoryAddEditModal: React.FC<IBotSubCategoryAddEditModal> = ({
    show,
    onHide,
    formValidation,
    loading,
    categories
}) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    return (
        <Modal isOpen={show} id='category-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    //To prevent the form to be sent when category press enter when selecting the address
                    if (document.activeElement?.id === 'address') return;
                    formValidation.handleSubmit();
                    formValidation.setFieldTouched('botCategory', true);
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.botSubCategoryId ? 'Edit' : 'Add'} Sub Category</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='category' className='form-label'>
                                Category <span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='category'
                                value={formValidation.values.botCategory}
                                onChange={(category) => {
                                    formValidation.setFieldValue('botCategory', category);
                                    formValidation.setFieldValue('botCategoryId', category?.botCategoryId);
                                }}
                                onBlur={() => formValidation.setFieldTouched('botCategory', true)}
                                getOptionLabel={(c: IBotCategory) => c.name}
                                getOptionValue={(c: IBotCategory) => c.botCategoryId}
                                options={categories}
                                classNames={{
                                    control: () =>
                                        formValidation.touched.botCategory && !!formValidation.errors.botCategory
                                            ? 'form-control is-invalid'
                                            : ''
                                }}
                            />
                            {/* To show the error for react-select */}
                            <FormSelectFeedback
                                show={formValidation.touched.botCategory && !!formValidation.errors.botCategory}
                                message={formValidation.errors.botCategory}
                            />
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='description' className='form-label'>
                                Description <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                id='description'
                                name='description'
                                className='form-control'
                                placeholder='Enter a description'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.description}
                                invalid={formValidation.touched.description && !!formValidation.errors.description}
                            />
                            {formValidation.touched.description && !!formValidation.errors.description && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.description}</div>
                                </FormFeedback>
                            )}
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='message-text' className='col-form-label'>
                                Instructions
                            </label>
                            <p className='text-muted'>
                                You can add instrucction/prompt for AI Assistants created in this category, for example
                                questions that it may received and how to answer those questions.
                            </p>
                            <Input
                                name='instructions'
                                type='textarea'
                                value={formValidation.values.instructions || ''}
                                className='form-control'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                rows={10}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default BotSubCategoryAddEditModal;
