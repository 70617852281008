import 'cleave.js/dist/addons/cleave-phone.in';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import botOrangeImg from '@src/assets/images/bot-orange-tablet.jpg';
import { FormButton } from '@src/components/FormButton';
import useQuestionnaire from './useQuestionnaire';

const Questionnaire = () => {
    document.title = 'Questionnaire | Connections';

    const { questions, loggedUser, answers, onInputChange, onSubmit, onBlur } = useQuestionnaire();

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <div className='pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper'>
                <Row className='g-4'>
                    <Col>
                        <div className='p-2'></div>
                    </Col>
                </Row>
            </div>

            <Row className='d-flex justify-content-center'>
                <Col sm={12} md={8} lg={8}>
                    <Card className='mt-n4'>
                        <CardBody className='p-4'>
                            <CardHeader className='mb-4'>
                                <div className='d-flex align-items-center'>
                                    <div className='profile-user me-4'>
                                        <img
                                            src={botOrangeImg}
                                            className='rounded-circle avatar-xl img-thumbnail user-profile-image'
                                            alt='user-profile'
                                        />
                                    </div>
                                    {!loggedUser.questionanaire && (
                                        <span className='text-muted'>
                                            Before continuing, please help us anwering the following questions:
                                        </span>
                                    )}

                                    {!!loggedUser.questionanaire && (
                                        <span className='text-muted'>You can edit your answers here:</span>
                                    )}
                                </div>
                            </CardHeader>

                            <Form onSubmit={onSubmit} action='#' hidden={!questions.length}>
                                {questions.map((question) => (
                                    <div className='mb-3' key={question.questionId}>
                                        <Label htmlFor={question.questionId} className='form-label'>
                                            {question.question} <span className='text-danger'>*</span>
                                        </Label>
                                        <Input
                                            name={question.questionId}
                                            type='textarea'
                                            rows={4}
                                            onChange={(e) => onInputChange(question.questionId, e.target.value)}
                                            onBlur={() => onBlur(question.questionId)}
                                            value={answers[question.questionId].answer || ''}
                                            invalid={
                                                answers[question.questionId].isTouched &&
                                                !answers[question.questionId].answer
                                            }
                                        />
                                        {answers[question.questionId].isTouched &&
                                            !answers[question.questionId].answer && (
                                                <FormFeedback type='invalid'>
                                                    <div>Please answer this question</div>
                                                </FormFeedback>
                                            )}
                                    </div>
                                ))}

                                <div className='hstack gap-2 justify-content-start'>
                                    <FormButton type='submit' value='Save Answers' />
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Questionnaire;
