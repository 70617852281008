import Loader from '@src/components/Loader';
import globalStore from '@src/stores/globalStore';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

const PublicLayout = ({ children }: { children: any }) => {
    const loading = globalStore((state) => state.loading);
    const { pathname } = useLocation();
    const isAdmin = pathname.toLowerCase().includes('admin');
    return (
        <React.Fragment>
            <Loader show={loading} />
            <div className='auth-page-wrapper pt-5'>
                <div className={`auth-one-bg-position ${isAdmin ? 'admin-' : ''}auth-one-bg`} id='auth-particles'>
                    <div className='bg-overlay'></div>

                    <div className='shape'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            version='1.1'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            viewBox='0 0 1440 120'
                        >
                            <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                        </svg>
                    </div>
                </div>

                <div className='auth-page-content'>
                    <Container>{children}</Container>
                </div>

                <footer className='footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='text-center'>
                                    <p className='mb-0 text-muted'>&copy; {new Date().getFullYear()} Connections</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default PublicLayout;
