//import Scss
import '@src/assets/scss/themes.scss';
import 'react-toastify/dist/ReactToastify.css';

import MainRoutes from '@src/routes/MainRoutes';
import { ToastContainer } from 'react-toastify';

export default () => (
    <>
        <MainRoutes />
        <ToastContainer />
    </>
);
