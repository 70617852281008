import { TreeItem, TreeView } from '@mui/x-tree-view';
import IUser, { TInvitedUser } from '@src/interfaces/IUser';
import { getUserInvitesAccepted } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { errorToast } from '@src/utils/helpers';
import { useEffect, useState } from 'react';
import { Badge, Col, Row, TabPane } from 'reactstrap';

interface IInvitedUsersTab {
    user?: IUser;
}

const InvitedUsersTab: React.FC<IInvitedUsersTab> = ({ user }) => {
    const setLoading = globalStore((state) => state.setLoading);
    const [invitedUsers, setInvitedUsers] = useState<Array<TInvitedUser>>([]);
    const [totalUsers, setTotalUsers] = useState<number>(0);

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        setLoading(true);
        try {
            const { success, data, message } = await getUserInvitesAccepted(user?.userId!);
            if (success) {
                setInvitedUsers(data);
                setTotalUsers(getTotalusers(data));
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const getTotalusers = (invitedUsers: Array<TInvitedUser>): number => {
        let invitedCount = invitedUsers.length || 0;
        invitedUsers.forEach((user) => {
            let childUsersCount = 0;
            if (Array.isArray(user.invitesAccepted)) {
                childUsersCount = getTotalusers(user.invitesAccepted);
            }
            user.totalInvitesAccepted = childUsersCount;
            invitedCount += childUsersCount;
        });
        return invitedCount;
    };

    const renderTree = (users: Array<TInvitedUser>) => {
        return users.map((user) => (
            <TreeItem
                key={user.userId}
                nodeId={user.userId!}
                label={
                    <Row>
                        <Col className='d-flex align-items-center'>
                            <span
                                className={
                                    'fs-20 me-2 text-muted mdi mdi-account' +
                                    (user.totalInvitesAccepted ? '-group' : '')
                                }
                            />
                            {user.name}
                            {user.totalInvitesAccepted ? (
                                <Badge color='primary' pill className='fs-10 ms-2'>
                                    {user.totalInvitesAccepted}
                                </Badge>
                            ) : null}{' '}
                        </Col>
                    </Row>
                }
            >
                {Array.isArray(user.invitesAccepted) ? renderTree(user.invitesAccepted) : null}
            </TreeItem>
        ));
    };

    return (
        <TabPane tabId='6'>
            <Row className='mb-4'>
                <Col className='h5'>
                    Total users joined by accepting <span className='text-muted'>{user?.name}</span> invitation:{' '}
                    <b>{totalUsers}</b>
                </Col>
            </Row>
            <Row>
                <TreeView
                    aria-label='rich object'
                    defaultCollapseIcon={<i className='ri-arrow-down-s-fill fs-20'></i>}
                    defaultExpandIcon={<i className='ri-arrow-right-s-fill fs-20'></i>}
                >
                    {renderTree(invitedUsers)}
                </TreeView>
            </Row>
        </TabPane>
    );
};

export default InvitedUsersTab;
