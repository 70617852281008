import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    approveMunicipalityRequest,
    createMunicipalityRequest,
    deleteMunicipalityRequest,
    getMunicipalityRequests,
    updateMunicipalityRequest
} from '@src/services/MunicipalityRequestService';
import { IMunicipalityRequest } from '@src/interfaces/IMunicipality';

const useAdminMunicipalityRequestsRequests = () => {
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [municipalityRequests, setMunicipalityRequests] = useState<Array<IMunicipalityRequest>>([]);
    const [selectedMunicipalityRequest, setSelectedMunicipalityRequest] = useState<IMunicipalityRequest>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const formValidation = useFormik({
        initialValues: {} as IMunicipalityRequest,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            isoCode: Yup.string().required('Please enter the ISO code'),
            state: Yup.object().required('Please enter the state')
        }),
        onSubmit: async (values: IMunicipalityRequest, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            let response;
            if (values.municipalityRequestId) response = await updateMunicipalityRequest(values);
            else response = await createMunicipalityRequest(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast(' MunicipalityRequest information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);

        try {
            const { success, data, message } = await getMunicipalityRequests();
            if (success) {
                setMunicipalityRequests(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (municipalityRequest: IMunicipalityRequest) => {
        setSelectedMunicipalityRequest(municipalityRequest);
        setShowInfoModal(true);
    };

    const onAddClick = (municipalityRequest: IMunicipalityRequest) => {
        setSelectedMunicipalityRequest(municipalityRequest);
        formValidation.resetForm();
        setShowSaveModal(true);
    };

    const onEditClick = (municipalityRequest: IMunicipalityRequest) => {
        setSelectedMunicipalityRequest(municipalityRequest);
        formValidation.setValues({ ...municipalityRequest });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (municipalityRequest: IMunicipalityRequest) => {
        setSelectedMunicipalityRequest(municipalityRequest);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteMunicipalityRequest(
            selectedMunicipalityRequest?.municipalityRequestId!
        );

        if (success) {
            await getData();
            sucessToast(' MunicipalityRequest deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedMunicipalityRequest(undefined);
    };

    const handleApproved = async () => {
        setLoading(true);
        const { success, message } = await approveMunicipalityRequest(selectedMunicipalityRequest!);

        if (success) {
            await getData();
            sucessToast(' MunicipalityRequest approved successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowSaveModal(false);
        setSelectedMunicipalityRequest(undefined);
    };

    return {
        municipalityRequests,
        loading,
        selectedMunicipalityRequest,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        showSaveModal,
        formValidation,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        setShowSaveModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete,
        handleApproved
    };
};

export default useAdminMunicipalityRequestsRequests;
