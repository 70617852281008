import IAutoCharge from '@src/interfaces/IAutoCharge';
import IBot from '@src/interfaces/IBot';
import IBotCategory from '@src/interfaces/IBotCategory';
import IBotPrompt from '@src/interfaces/IBotPrompt';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { IStepOneData } from '@src/interfaces/IGeneric';
import IMunicipality from '@src/interfaces/IMunicipality';
import IQuestion from '@src/interfaces/IQuestion';
import IState from '@src/interfaces/IState';
import { globals } from './constants';
import { getLoggedUser } from './helpers';

export const botInitValues: IBot = {
    botId: '',
    name: '',
    instructions: '',
    userId: '',
    meta: {},
    avatar: '',
    avatarInput: {} as File,
    startImage: '',
    startImageInput: {} as File,
    description: "I'm {0} and I'll be your assistant.",
    welcomeMessage: "Hello, I'm {0}, how may I help you today?",
    documentsInputs: [] as Array<File | undefined | null>
};

export const forgotPassInitValues = {
    email: ''
};

export const loginInitValues = {
    email: '',
    password: ''
};

export const registerInitValues = {
    name: '',
    phone: '',
    email: '',
    fullAddress: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    currentPassword: '',
    password: '',
    confirmPassword: '',
    acceptedAgreement: false
};

export const registrationVerificationInitValues = {
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: ''
};

export const resetPassInitValues = {
    password: '',
    confirmPassword: ''
};

export const requestMuniInitValues = {
    name: '',
    state: '',
    zipcode: '',
    userId: '',
    isoCode: ''
};

export const leadInitValues = {
    name: '',
    phone: '',
    email: '',
    botId: ''
};

export const botCategoryInitValues: IBotCategory = {
    botCategoryId: '',
    name: '',
    description: ''
};

export const botSubCategoryInitValues: IBotSubCategory = {
    botSubCategoryId: '',
    botCategory: undefined,
    name: '',
    description: ''
};

export const municipalityInitValues: IMunicipality = {
    muniId: '',
    name: '',
    isoCode: '',
    stateId: '',
    fileLink: '',
    fileContent: ''
};

export const stateInitValues: IState = {
    stateId: '',
    name: '',
    isoCode: ''
};

export const botsPromptInitValues: IBotPrompt = {
    promptId: '',
    name: '',
    instructions: ''
};

export const autoChargeInitValues: IAutoCharge = {
    autoChargeId: '',
    minBalance: 0,
    amountToCharge: globals.minAmountToRecharge,
    user: getLoggedUser(),
    isActive: false
};

export const questionInitValues: IQuestion = {
    questionId: '',
    question: '',
    type: '',
    order: 0
};

export const registrationStepOneInitValues: IStepOneData = {
    name: '',
    companyName: '',
    phone: '',
    acceptedAgreement: false
};
