import HttpService from '@src/utils/HttpService';
import IBotPrompt from '@src/interfaces/IBotPrompt';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createBotPrompt = async (prompt: IBotPrompt) => {
    const response = { data: {} as IBotPrompt, success: false, message: '' };

    try {
        const endPoint = '/prompts/create';
        const formatApiData = objToSnake(prompt);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBotPrompt;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotPrompt = async (promptId: string) => {
    const response = { data: {} as IBotPrompt, success: false, message: '' };

    try {
        const endPoint = `/prompts/${promptId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IBotPrompt;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotPrompts = async () => {
    const response = { data: [] as Array<IBotPrompt>, success: false, message: '' };

    try {
        const endPoint = `/prompts`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => {
            const tempBot = objToCamel(item) as IBotPrompt;
            return tempBot;
        });
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateBotPrompt = async (prompt: IBotPrompt) => {
    const response = { data: {} as IBotPrompt, success: false, message: '' };

    try {
        const endPoint = `/prompts/update/${prompt.promptId}`;
        const formatApiData = objToSnake(prompt);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBotPrompt;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteBotPrompt = async (promptId: string) => {
    const response = { data: {} as IBotPrompt, success: false, message: '' };

    try {
        const endPoint = `/prompts/${promptId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBotPrompt;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
