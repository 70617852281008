import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { questionInitValues } from '@src/utils/initValues';
import IQuestion from '@src/interfaces/IQuestion';
import { createQuestion, deleteQuestion, getQuestionnaire, updateQuestion } from '@src/services/QuestionnaireService';
import { IObj } from '@src/interfaces/IGeneric';

const useAdminQuestions = () => {
    const loading = globalStore((question) => question.loading);
    const setLoading = globalStore((question) => question.setLoading);

    const [questions, setQuestions] = useState<Array<IQuestion>>([]);
    const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: questionInitValues,
        validationSchema: Yup.object({
            question: Yup.string().required('Please enter the question'),
            type: Yup.object().required('Please enter the type'),
            order: Yup.number().test({
                name: 'order-selected',
                message: 'Please enter a valid number',
                test: (val) => !selectedQuestion?.questionId || typeof val === 'number'
            })
        }),
        onSubmit: async (values: IQuestion, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            let response;
            values.type = (values.type as IObj).value;
            if (values.questionId) response = await updateQuestion(values);
            else response = await createQuestion(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast(' Question information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        console.log('getting data');
        const { success, data, message } = await getQuestionnaire();

        try {
            if (success) {
                setQuestions(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (question: IQuestion) => {
        setSelectedQuestion(question);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedQuestion(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (question: IQuestion) => {
        setSelectedQuestion(question);
        formValidation.setValues({ ...question });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (question: IQuestion) => {
        setSelectedQuestion(question);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteQuestion(selectedQuestion?.questionId!);

        if (success) {
            await getData();
            sucessToast(' question deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedQuestion(undefined);
    };

    return {
        questions,
        loading,
        selectedQuestion,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminQuestions;
