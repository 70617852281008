import PhoneVerification from '@src/pages/app/PhoneVerification';
import { globals } from '@src/utils/constants';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import htmlParse from 'html-react-parser';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { Link } from 'react-router-dom';
import { Alert, Form, FormFeedback, Input, Label, Modal, ModalBody } from 'reactstrap';
import WizardControls from '../../Controls';
import useRegistrationStepTwo from './useRegistrationStepTwo';

const RegistrationStepTwo: React.FC = () => {
    const {
        formValidation,
        errorMsg,
        disableNext,
        showPhoneVerification,
        phoneToken,
        setShowPhoneVerification,
        validateAddressField,
        onAddressSelected,
        onAddressChanged,
        beforeSubmit,
        validateStepData
    } = useRegistrationStepTwo();

    return (
        <div>
            <WizardControls disableNext={disableNext} processNext={validateStepData}>
                {!!errorMsg && <Alert color='danger'> {htmlParse(errorMsg)} </Alert>}
                <div className='p-2 mt-4'>
                    <Form onSubmit={validateStepData} action='#'>
                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Email <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='email'
                                type='email'
                                className='form-control'
                                placeholder='Enter email address'
                                value={formValidation.values.email}
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                invalid={formValidation.touched.email && !!formValidation.errors.email}
                            />
                            {formValidation.touched.email && !!formValidation.errors.email && (
                                <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Address <span className='text-danger'>*</span>
                            </Label>

                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                id='address'
                                name='address'
                                defaultValue={formValidation.values.fullAddress}
                                className={'form-control ' + (!validateAddressField() && 'is-invalid')}
                                placeholder='Enter your address'
                                onPlaceSelected={onAddressSelected}
                                onChange={onAddressChanged}
                                options={{
                                    componentRestrictions: { country: 'us' },
                                    types: ['address'],
                                    fields: ['address_components', 'formatted_address']
                                }}
                            />
                            {!validateAddressField() && (
                                <FormFeedback type='invalid'>{globals.invalidAddressMsg}</FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label className='form-label' htmlFor='password'>
                                Password <span className='text-danger'>*</span>
                            </Label>
                            <div className='position-relative auth-pass-inputgroup mb-3'>
                                <Input
                                    name='password'
                                    value={formValidation.values.password || ''}
                                    type={'password'}
                                    className='form-control pe-5'
                                    placeholder='Enter Password'
                                    onChange={formValidation.handleChange}
                                    onBlur={formValidation.handleBlur}
                                    invalid={formValidation.touched.password && !!formValidation.errors.password}
                                />
                                {formValidation.touched.password && !!formValidation.errors.password && (
                                    <FormFeedback type='invalid'>{formValidation.errors.password}</FormFeedback>
                                )}
                            </div>
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='confirmPassword' className='form-label'>
                                Confirm Password <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='confirmPassword'
                                type='password'
                                placeholder='Confirm Password'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.confirmPassword || ''}
                                invalid={
                                    formValidation.touched.confirmPassword && !!formValidation.errors.confirmPassword
                                }
                            />
                            {formValidation.touched.confirmPassword && !!formValidation.errors.confirmPassword && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.confirmPassword}</div>
                                </FormFeedback>
                            )}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='phone' className='form-label'>
                                Phone <span className='text-danger'>*</span>
                            </Label>
                            <Cleave
                                id='phone'
                                name='phone'
                                className={
                                    'form-control ' +
                                    (formValidation.touched.phone && !!formValidation.errors.phone
                                        ? 'is-invalid form-control'
                                        : '')
                                }
                                placeholder='(xxxx) xxx-xxx'
                                options={{
                                    numericOnly: true,
                                    // prefix: '+1',
                                    // blocks: [2, 3, 3, 4]
                                    delimiterLazyShow: true,
                                    delimiters: ['(', ') ', '-'],
                                    blocks: [0, 3, 3, 4]
                                }}
                                onBlur={formValidation.handleBlur}
                                onChange={formValidation.handleChange}
                                value={formValidation.values.phone || ''}
                            />
                            {formValidation.touched.phone && !!formValidation.errors.phone && (
                                <FormFeedback type='invalid' style={{ display: 'block' }}>
                                    {formValidation.errors.phone}
                                </FormFeedback>
                            )}
                        </div>

                        <div className='form-check mt-5'>
                            <Input
                                className='form-check-input'
                                type='checkbox'
                                id='acceptedAgreement'
                                name='acceptedAgreement'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                checked={formValidation.values.acceptedAgreement}
                            />
                            <p className='mb-0 fs-12 text-muted fst-italic'>
                                By registering you agree to our{' '}
                                <Link
                                    to={globals.termsUrl}
                                    target='_blank'
                                    className='text-primary text-decoration-underline fst-normal fw-medium'
                                >
                                    Terms of Use
                                </Link>{' '}
                                And our{' '}
                                <Link
                                    to={globals.privacyUrl}
                                    target='_blank'
                                    className='text-primary text-decoration-underline fst-normal fw-medium'
                                >
                                    Privacy Policy
                                </Link>
                            </p>
                        </div>
                    </Form>
                </div>
                <Modal isOpen={showPhoneVerification} centered toggle={() => setShowPhoneVerification(false)}>
                    <ModalBody className='text-center'>
                        <PhoneVerification
                            isEmbed
                            phone={formValidation.values.phone}
                            token={phoneToken}
                            afterVerification={beforeSubmit}
                        />
                    </ModalBody>
                </Modal>
            </WizardControls>
        </div>
    );
};

export default RegistrationStepTwo;
