import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Nav, Row, TabContent } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import { getPaymentMethods } from '@src/services/PaymentService';
import globalStore from '@src/stores/globalStore';
import { errorToast } from '@src/utils/helpers';

import TabLink from '@src/components/TabLink';
import IUser from '@src/interfaces/IUser';
import { getUser } from '@src/services/UserService';
import { RoutesName } from '@src/utils/enums';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BillingHistoryTab from './partials/BillingHistoryTab';
import BotsTab from './partials/BotsTab';
import InvitedUsersTab from './partials/InvitedUsersTab';
import OverviewTab from './partials/OverviewTab';
import QuestionnaireTab from './partials/QuestionnaireTab';
import SubscriptionsTab from './partials/SubscriptionsTab';

const AdminClientsInfo = () => {
    document.title = 'Client Info | Connections';

    const setLoading = globalStore((state) => state.setLoading);
    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = useParams();

    const [customActiveTab, setcustomActiveTab] = useState('1');
    const [paymentMethods, setPaymentMethods] = useState<IStripePaymentMethod[]>([]);
    const [refreshBillingHistoryData, setRefreshBillingHistoryData] = useState<boolean>(false);
    const [user, setUser] = useState<IUser | undefined>(location.state?.user);

    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (refreshBillingHistoryData) setRefreshBillingHistoryData(false);
    }, [refreshBillingHistoryData]);

    const getData = async () => {
        setLoading(true);
        let tempUser = _.cloneDeep(user);
        if (!user?.userId) {
            if (userId) {
                const { success, data } = await getUser(userId);
                if (success) {
                    setUser(data);
                    tempUser = data;
                }
            }
        }
        //If no user, redirect to clients list
        if (!tempUser?.userId) navigate(RoutesName.adminClients);

        const paymentMethodsResponse = await getPaymentMethods(tempUser?.stripeCustomerId!);
        try {
            if (paymentMethodsResponse.success) {
                const tempPaymentMethods = paymentMethodsResponse.data.reverse();
                setPaymentMethods(tempPaymentMethods);
            } else {
                errorToast(paymentMethodsResponse.message);
            }
        } catch (error) {}

        setLoading(false);
    };

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>User Info</h2>
                                </div>
                                <Nav tabs className='nav nav-tabs nav-tabs-custom nav-primary nav-justified mt-6 mb-3'>
                                    <TabLink
                                        index='1'
                                        label='Overview'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />
                                    <TabLink
                                        index='2'
                                        label='AI Assistants'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />
                                    <TabLink
                                        index='3'
                                        label='Subscriptions'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />
                                    <TabLink
                                        index='4'
                                        label='Billing History'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />

                                    <TabLink
                                        index='5'
                                        label='Questionnaire'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />

                                    <TabLink
                                        index='6'
                                        label='Invited Users'
                                        customActiveTab={customActiveTab}
                                        toggleCustom={toggleCustom}
                                    />
                                </Nav>

                                <TabContent activeTab={customActiveTab} className='p-4'>
                                    <OverviewTab
                                        setRefreshBillingHistoryData={setRefreshBillingHistoryData}
                                        user={user}
                                    />
                                    <BotsTab user={user} />
                                    <SubscriptionsTab user={user} />
                                    <BillingHistoryTab
                                        paymentMethods={paymentMethods}
                                        refreshData={refreshBillingHistoryData}
                                        user={user}
                                    />
                                    <QuestionnaireTab user={user} />
                                    <InvitedUsersTab user={user} />
                                </TabContent>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdminClientsInfo;
