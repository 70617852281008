import { logout, resendEmailCode } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { RoutesName } from '@src/utils/enums';
import { errorToast, getLoggedUser, isEmailVerified, sucessToast } from '@src/utils/helpers';
import 'cleave.js/dist/addons/cleave-phone.in';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useEmailVerification = () => {
    const loggedUser = getLoggedUser();
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const navigate = useNavigate();

    let emailCheckInterval: NodeJS.Timer;

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoading(true);
        console.log('on init', await isEmailVerified());
        if (await isEmailVerified()) {
            navigate(RoutesName.dashboard, { replace: true });
        } else {
            console.log('creating check interval');
            createEmailCheckInterval();
        }
        setLoading(false);
    };

    const createEmailCheckInterval = () => {
        clearInterval(emailCheckInterval);
        emailCheckInterval = setInterval(async () => {
            console.log('checking email verification');
            if (await isEmailVerified()) {
                clearInterval(emailCheckInterval);
                navigate(RoutesName.dashboard, { replace: true });
                sucessToast('Email Verification completed!');
            }
        }, 1000);
    };

    const handleResendEmailCode = async () => {
        setLoading(true);
        const { success, message } = await resendEmailCode(loggedUser.userId!);
        if (success) {
            sucessToast('Verification email was sent successfully!');
        } else {
            errorToast(message);
        }
        setLoading(false);
    };

    return {
        loading,
        loggedUser,
        logout,
        handleResendEmailCode,
        navigate
    };
};

export default useEmailVerification;
