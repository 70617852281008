import { Navigate } from 'react-router-dom';
import { RoutesName } from '@src/utils/enums';
import PublicLayout from './PublicLayout';
import { getLoggedUser } from '@src/utils/helpers';

const ParticlesAuth = ({ children }: { children: any }) => {
    const loggedUser = getLoggedUser(true);

    if (loggedUser?.isAdmin) {
        return <Navigate to={{ pathname: RoutesName.adminDashboard }} />;
    }

    return <PublicLayout>{children}</PublicLayout>;
};

export default ParticlesAuth;
