import React from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';

import botOrangeImg from '@src/assets/images/bot-orange-tablet.jpg';
import CustomPagination from '@src/components/CustomPagination';
import { EmailNotVerifiedAlert } from '@src/components/EmailNotVerifiedAlert';
import { FormButton } from '@src/components/FormButton';
import { NoBalance } from '@src/components/NoBalance';
import { NoPaymentMethod } from '@src/components/NoPaymentMethod';
import OkCancelModal from '@src/components/OkCancelModal';
import { globals } from '@src/utils/constants';
import { copyText } from '@src/utils/helpers';
import BotInfoCard from '../../../components/BotInfoCard';
import BotAddEditModal from './partials/BotAddEditModal';
import { InviteContactsModal } from './partials/InviteContactsModal';
import RequestMunicipalityModal from './partials/RequestMunicipalityModal';
import useDashboard from './useDashboard';

const Dashboard = () => {
    document.title = 'Dashboard | Connections';

    const {
        loggedUser,
        filteredBots,
        bots,
        currentPage,
        showBotModal,
        selectedBot,
        formValidation,
        requestMuniValidation,
        showMunicipalitiesDDL,
        municipalitiesOptions,
        loading,
        showDeleteModal,
        showMunicipalityModal,
        borderColors,
        googleContacts,
        filteredGoogleContacts,
        filterText,
        totalContactsSelected,
        hasMunicipalityAccess,
        defaultPaymentMethod,
        isEmailAlreadyVerified,
        setSelectedProduct,
        onOpenBotModal,
        onCloseBotModal,
        onEditBot,
        onDeleteBot,
        onFacebookLogin,
        onFacebookLogout,
        setCurrentPage,
        getAvatarPreview,
        getStartImagePreview,
        avatarOnChange,
        startImageOnChange,
        onChangeMunicipality,
        setShowDeleteModal,
        handleDelete,
        getMunicipalityDefault,
        validateAddressField,
        onAddressSelected,
        setShowMunicipalityModal,
        onShowMunRequest,
        googleLogin,
        setGoogleContacts,
        sendInviteToContacts,
        setFilterText,
        handleContactCheckChange,
        handleContactItemCheckChange
    } = useDashboard();

    return (
        <React.Fragment>
            <div className='pt-4 mb-1 profile-wrapper'>
                <div className='row page-titles mx-0'>
                    <div className='col-sm-9 p-md-0'>
                        <div className='welcome-tex'>
                            <h4 className='mb-2'>Hi {loggedUser.name}, welcome back!</h4>
                            <span className='me-2'>Account Category:</span>
                            <Badge className='badge bg-white rounded-pill border border-primary text-primary'>
                                {loggedUser.category?.name}
                            </Badge>
                            <span className='ms-2 me-2'>Sub Category:</span>
                            <Badge className='badge bg-white rounded-pill border border-primary text-primary'>
                                {loggedUser.subCategory?.name}
                            </Badge>

                            {bots.length === 0 && !loading && (
                                <div className='d-flex profile-wrapper my-4'>
                                    <div className='flex-shrink-0'>
                                        <FormButton onClick={onOpenBotModal}>
                                            <i className='ri-add-line align-bottom'></i> Add New AI Assistant
                                        </FormButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-sm-3 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex'>
                        <div className='p-2'>
                            {false && (
                                <>
                                    <h4 className='text-white'>
                                        Copy Referral Code{' '}
                                        <i
                                            className='ri-file-copy-line h4 text-white'
                                            role='button'
                                            onClick={() => copyText(loggedUser?.referralCode)}
                                        />
                                    </h4>
                                    <span className='text-white'> or </span>
                                </>
                            )}

                            <FormButton onClick={googleLogin} color='light' fitParent={false}>
                                <i className='ri-google-fill'></i> Invite your contacts
                            </FormButton>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
                <Col lg={12}>
                    <div>
                        {defaultPaymentMethod && (loggedUser.creditBalance || 0) <= 0 && !loading && <NoBalance />}

                        <Card>
                            <CardBody>
                                <Row>
                                    {!isEmailAlreadyVerified && !loading && <EmailNotVerifiedAlert />}
                                    {!defaultPaymentMethod && !loading && <NoPaymentMethod />}
                                    {bots.length == 0 && (
                                        <div className='mt-5 text-center'>
                                            <h4>With Connections</h4>
                                            <p className='text-muted'>
                                                you can Create your own assistant for your business!
                                            </p>
                                            <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                                                <img
                                                    src={botOrangeImg}
                                                    className='rounded-circle avatar-xxl img-thumbnail user-profile-image'
                                                    alt='user-profile'
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {filteredBots?.map((bot, key) => (
                                        <BotInfoCard
                                            keyVal={key}
                                            bot={bot}
                                            borderColors={borderColors}
                                            onEditBot={onEditBot}
                                            onDeleteBot={onDeleteBot}
                                            onFacebookLogin={onFacebookLogin}
                                            onFacebookLogout={onFacebookLogout}
                                        />
                                    ))}
                                    <CustomPagination
                                        total={Math.ceil((bots?.length || 0) / globals.perPageBots)}
                                        perPage={globals.perPageBots}
                                        currentPage={currentPage}
                                        changePage={setCurrentPage}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <BotAddEditModal
                show={showBotModal}
                formValidation={formValidation}
                loading={loading}
                selectedBot={selectedBot}
                showMunicipalitiesDDL={showMunicipalitiesDDL}
                municipalitiesOptions={municipalitiesOptions}
                getMunicipalityDefault={getMunicipalityDefault}
                onShowMunRequest={onShowMunRequest}
                onChangeMunicipality={onChangeMunicipality}
                avatarOnChange={avatarOnChange}
                startImageOnChange={startImageOnChange}
                getAvatarPreview={getAvatarPreview}
                getStartImagePreview={getStartImagePreview}
                onHide={onCloseBotModal}
                hasMunicipalityAccess={hasMunicipalityAccess}
                setSelectedProduct={setSelectedProduct}
                defaultPaymentMethod={defaultPaymentMethod}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br /><b>${selectedBot?.name}</b>?`}
                subTitle="If you continue, you won't be able to recover the information of this AI Assistant."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />

            <RequestMunicipalityModal
                show={showMunicipalityModal}
                loading={loading}
                requestMuniValidation={requestMuniValidation}
                setShowMunicipalityModal={setShowMunicipalityModal}
                validateAddressField={validateAddressField}
                onAddressSelected={onAddressSelected}
            />

            <InviteContactsModal
                show={!!googleContacts.length}
                loading={loading}
                filterText={filterText}
                googleContacts={googleContacts}
                filteredGoogleContacts={filteredGoogleContacts}
                totalContactsSelected={totalContactsSelected}
                setFilterText={setFilterText}
                handleContactCheckChange={handleContactCheckChange}
                handleContactItemCheckChange={handleContactItemCheckChange}
                sendInviteToContacts={sendInviteToContacts}
                setGoogleContacts={setGoogleContacts}
            />
        </React.Fragment>
    );
};

export default Dashboard;
