import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IBot from '@src/interfaces/IBot';
import 'cleave.js/dist/addons/cleave-phone.in';
import { FormikProps } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
    Accordion,
    AccordionItem,
    Collapse,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';

// import FacebookLogin from '@greatsumini/react-facebook-login';
import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import { NoBalance } from '@src/components/NoBalance';
import { NoPaymentMethod } from '@src/components/NoPaymentMethod';
import { IDDLMulti } from '@src/interfaces/IGeneric';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import { globals } from '@src/utils/constants';
import { capitalize, formatString } from '@src/utils/helpers';
import useBotAddEditModal from './useBotAddEditModal';

interface IBottAddEditModal {
    show: boolean;
    formValidation: FormikProps<IBot>;
    selectedBot?: IBot;
    loading: boolean;
    showMunicipalitiesDDL: boolean;
    municipalitiesOptions: Array<IDDLMulti>;
    hasMunicipalityAccess: boolean;
    defaultPaymentMethod?: IStripePaymentMethod;
    getMunicipalityDefault(): void;
    onShowMunRequest(): void;
    onChangeMunicipality(e: any): void;
    avatarOnChange(e: React.ChangeEvent<HTMLInputElement>): void;
    startImageOnChange(e: React.ChangeEvent<HTMLInputElement>): void;
    getAvatarPreview(): string | undefined;
    getStartImagePreview(): string | undefined;
    onHide(): void;
    setSelectedProduct(price: ISubscriptionProduct): void;
}

const BotAddEditModal: React.FC<IBottAddEditModal> = ({
    show,
    formValidation,
    loading,
    selectedBot,
    showMunicipalitiesDDL,
    municipalitiesOptions,
    hasMunicipalityAccess,
    defaultPaymentMethod,
    getMunicipalityDefault,
    onShowMunRequest,
    onChangeMunicipality,
    avatarOnChange,
    startImageOnChange,
    getAvatarPreview,
    getStartImagePreview,
    onHide,
    setSelectedProduct,
}) => {
    const { showAdvanced, userAcceptedToPay, internalLoading, products, loggedUser, setShowAdvanced, onSubscribe, documentOnChange,
        documentOnRemove,
        addDocumentElement,
        onMarkDocumentDeleted, } =
        useBotAddEditModal({ selectedBot, show, formValidation, setSelectedProduct });

    if (!userAcceptedToPay && (!selectedBot?.botId || !selectedBot?.isSubscriptionActive))
        return (
            <Modal isOpen={show} id='new-bot-modal' backdrop={'static'} centered>
                <ModalBody>
                    <div className='d-flex align-items-center'>
                        <div className='flex-grow-1'>
                            <h5 className='mb-1 fw-semibold'>AI Assistant Subscription Plan</h5>
                        </div>
                        <div className='avatar-sm'>
                            <div className='avatar-title bg-light rounded-circle text-primary'>
                                <i className='mdi mdi-robot-outline  m-2 display-6 text-primary' />
                            </div>
                        </div>
                    </div>

                    {products?.map((product, idx) => (
                        <div key={idx} className='p-3 mt-2' style={{ border: '1px solid #e3e3e3', borderRadius: 5 }}>
                            <div className='py-2 text-center'>
                                <h1 className='month'>
                                    <sup></sup>
                                    <div className='d-flex justify-content-center'>
                                        <small>$</small>
                                        <span className='ff-primary fw-bold'>
                                            {product.price.toString().split('.')[0]}
                                        </span>
                                        <span className='fs-10'>{product.price.toString().split('.')[1]}</span>
                                        <div>
                                            <span className='fs-13 text-muted'>
                                                /{capitalize(product.recurringIntervalDescription)}
                                            </span>
                                        </div>
                                    </div>
                                </h1>
                            </div>
                            <p>Features:</p>
                            <ul className='list-unstyled text-muted vstack gap-3 ff-primary ms-3'>
                                <li>
                                    <div className='d-flex'>
                                        <i className='ri-checkbox-circle-fill fs-15 align-middle text-success me-1'></i>
                                        <div className='flex-grow-1'>
                                            Easy chat <b>integration</b> on your website
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex'>
                                        <i className='ri-checkbox-circle-fill fs-15 align-middle text-success me-1'></i>
                                        <div className='flex-grow-1'>
                                            Chat <b>security</b> to only be accessed from your website
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {!!defaultPaymentMethod && (loggedUser.creditBalance || 0) >= product.price && (
                                <FormButton
                                    value='Subscribe'
                                    onClick={() => onSubscribe(product)}
                                    disabled={internalLoading}
                                />
                            )}
                            {defaultPaymentMethod && (loggedUser.creditBalance || 0) < product.price && <NoBalance />}
                        </div>
                    ))}
                    <div className='mb-3 mt-3'>{!defaultPaymentMethod && <NoPaymentMethod />}</div>
                </ModalBody>
                <ModalFooter>
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Modal>
        );

    return (
        <Modal isOpen={show} id='new-bot-modal' backdrop={'static'} centered>
            <form
                id='bot-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    console.log(formValidation.errors);
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalHeader className='modal-title' id='myModalLabel' toggle={onHide}>
                    {selectedBot ? 'Edit' : 'Add new'} AI Assistant
                </ModalHeader>
                <ModalBody>
                    <div className='mb-3'>
                        <label htmlFor='name' className='col-form-label'>
                            Name <span className='text-danger'>*</span>
                        </label>
                        <Input
                            name='name'
                            value={formValidation.values.name || ''}
                            className='form-control'
                            onChange={formValidation.handleChange}
                            onBlur={formValidation.handleBlur}
                            invalid={formValidation.touched.name && !!formValidation.errors.name}
                        />
                        {formValidation.touched.name && !!formValidation.errors.name && (
                            <FormFeedback type='invalid'>{formValidation.errors.name}</FormFeedback>
                        )}
                    </div>
                    <div className='mb-3'>
                        <Label htmlFor='avatar' className='form-label'>
                            Avatar
                            <p className='text-muted'>We recommend using a square image</p>
                        </Label>
                        <div className='d-flex'>
                            <div className='w-100 me-4'>
                                <Input
                                    id='avatar'
                                    name='avatarInput'
                                    className='form-control'
                                    type='file'
                                    accept='image/*'
                                    onChange={avatarOnChange}
                                    invalid={formValidation.touched.avatar && !!formValidation.errors.avatar}
                                />
                            </div>
                            {!!formValidation.values.avatar && !formValidation.values.avatarInput && (
                                <div className='avatar-sm'>
                                    <img
                                        src={formValidation.values.avatar}
                                        alt='img'
                                        className='img-thumbnail rounded-circle'
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = userDummayImage;
                                        }}
                                    />
                                </div>
                            )}
                            {!!formValidation.values.avatarInput && (
                                <div className='avatar-sm'>
                                    <img
                                        src={getAvatarPreview()}
                                        alt='img'
                                        className='img-thumbnail rounded-circle'
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = userDummayImage;
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='description' className='col-form-label'>
                            Description <span className='text-danger'>*</span>
                            <p className='text-muted'>This will be shown before starting the conversation</p>
                        </label>
                        <Input
                            name='description'
                            value={formatString(formValidation.values.description, formValidation.values.name) || ''}
                            className='form-control'
                            onChange={formValidation.handleChange}
                            onBlur={formValidation.handleBlur}
                            invalid={formValidation.touched.description && !!formValidation.errors.description}
                        />
                        {formValidation.touched.description && !!formValidation.errors.description && (
                            <FormFeedback type='invalid'>{formValidation.errors.description}</FormFeedback>
                        )}
                    </div>
                    <div className='mb-3'>
                        <Label htmlFor='start-image' className='form-label'>
                            Start Image
                            <p className='text-muted'>
                                This will be shown before starting the conversation. We recommend using a jpg image with
                                the size of 500x250px
                            </p>
                        </Label>
                        <div className='d-flex'>
                            <div className='w-100 me-4'>
                                <Input
                                    id='start-image'
                                    name='startImageInput'
                                    className='form-control'
                                    type='file'
                                    accept='image/*'
                                    onChange={startImageOnChange}
                                    invalid={formValidation.touched.startImage && !!formValidation.errors.startImage}
                                />
                            </div>
                            {!!formValidation.values.startImage && !formValidation.values.startImageInput && (
                                <div className='text-center' style={{ height: '37px' }}>
                                    <img
                                        src={formValidation.values.startImage}
                                        style={{
                                            borderRadius: 5,
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            width: '70px',
                                            height: '100%'
                                        }}
                                    />
                                </div>
                            )}
                            {!!formValidation.values.startImageInput && (
                                <div className='text-center' style={{ height: '37px' }}>
                                    <img
                                        src={getStartImagePreview()}
                                        style={{
                                            borderRadius: 5,
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            width: '70px',
                                            height: '100%'
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='welcomeMessage' className='col-form-label'>
                            Welcome message <span className='text-danger'>*</span>
                            <p className='text-muted'>
                                This will be the first message the user will see after starting a conversation
                            </p>
                        </label>
                        <Input
                            name='welcomeMessage'
                            value={formatString(formValidation.values.welcomeMessage, formValidation.values.name) || ''}
                            className='form-control'
                            onChange={formValidation.handleChange}
                            onBlur={formValidation.handleBlur}
                            invalid={formValidation.touched.welcomeMessage && !!formValidation.errors.welcomeMessage}
                        />
                        {formValidation.touched.welcomeMessage && !!formValidation.errors.welcomeMessage && (
                            <FormFeedback type='invalid'>{formValidation.errors.welcomeMessage}</FormFeedback>
                        )}
                    </div>

                    {globals.showMunicipalityOptions && showMunicipalitiesDDL && hasMunicipalityAccess && (
                        <div className='mb-3'>
                            <label htmlFor='message-text' className='col-form-label'>
                                Municipality <span className='text-danger'>*</span>
                            </label>

                            <Select
                                onChange={onChangeMunicipality}
                                options={municipalitiesOptions as any}
                                defaultValue={getMunicipalityDefault()}
                                classNames={{
                                    control: () =>
                                        formValidation.touched.meta && !!formValidation.errors.meta
                                            ? 'form-control is-invalid'
                                            : ''
                                }}
                            />
                            {/* To show the error for react-select */}
                            <FormSelectFeedback
                                show={formValidation.touched.meta && !!formValidation.errors.meta}
                                message={formValidation.errors.meta as string}
                            />

                            <p className='mt-3 text-muted'>
                                Cannot find your Municipality?
                                <Link
                                    to='#'
                                    className='fw-semibold text-primary text-decoration-underline ms-2'
                                    onClick={onShowMunRequest}
                                >
                                    Make a request
                                </Link>
                            </p>
                        </div>
                    )}

                    <Accordion open={''} toggle={() => null}>
                        <AccordionItem>
                            <h2 className='accordion-header' id='headingOne'>
                                <button
                                    className={'accordion-button ' + (!showAdvanced ? 'collapsed' : '')}
                                    type='button'
                                    onClick={() => setShowAdvanced(!showAdvanced)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Label className='form-label'>Advanced Settings</Label>
                                </button>
                            </h2>

                            <Collapse isOpen={showAdvanced} className='accordion-collapse' id='collapseOne'>
                                <div className='accordion-body'>
                                    <div className='mb-3'>
                                        <label htmlFor='allowedDomain' className='col-form-label'>
                                            Allow embed chat only on this domain
                                        </label>
                                        <Input
                                            name='allowedDomain'
                                            value={formValidation.values.allowedDomain || ''}
                                            className='form-control pe-5'
                                            placeholder='https://www.example.com'
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            invalid={
                                                formValidation.touched.allowedDomain &&
                                                !!formValidation.errors.allowedDomain
                                            }
                                        />
                                        {formValidation.touched.allowedDomain &&
                                            !!formValidation.errors.allowedDomain && (
                                                <FormFeedback type='invalid'>
                                                    {formValidation.errors.allowedDomain}
                                                </FormFeedback>
                                            )}
                                    </div>
                                    <Label className='form-label'>Knowledge documents</Label>
                                    <p className='text-muted'>
                                        You can add documents (pdf, doc, ppt, txt) to feed your Bot Assistant with more
                                        information
                                    </p>
                                    <div className={'d-flex '}>
                                        {formValidation?.values.documents?.map((doc, idx) => {
                                            const colorClass = formValidation.values.deletedDocuments?.includes(doc.id)
                                                ? 'danger'
                                                : 'primary';
                                            const icon =
                                                colorClass === 'danger'
                                                    ? 'ri-arrow-go-back-line'
                                                    : 'ri-delete-bin-line';
                                            return (
                                                <div className='d-flex me-2' key={'form-doc-' + idx}>
                                                    <Link to={doc.url || '#'} target='_blank'>
                                                        <span
                                                            className={`badge rounded-pill border border-${colorClass} me-1 text-${colorClass}`}
                                                        >
                                                            doc-{idx + 1}
                                                        </span>
                                                    </Link>
                                                    <i
                                                        className={'fs-15 ' + icon}
                                                        role='button'
                                                        onClick={() => onMarkDocumentDeleted(doc.id)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {!!formValidation.values.deletedDocuments?.length && (
                                        <div className='text-muted'>
                                            Documents to be deleted:{' '}
                                            <b>{formValidation.values.deletedDocuments?.length}</b>
                                        </div>
                                    )}
                                    <div className={formValidation?.values.documents ? 'mb-2' : ''}></div>
                                    <div
                                        className='d-flex align-items-center mb-2'
                                        role='button'
                                        onClick={addDocumentElement}
                                    >
                                        <i className='ri-add-circle-line fs-24 me-1' /> Add document
                                    </div>
                                    {formValidation.values.documentsInputs?.map((document, idx) => (
                                        <div key={idx} id={`document-div-${idx}`} className='d-flex mb-2'>
                                            <div className='w-100 me-2'>
                                                <Input
                                                    id={`document-${idx}`}
                                                    name={`document-${idx}`}
                                                    className='form-control'
                                                    type='file'
                                                    accept='.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf'
                                                    onChange={(e) => documentOnChange(e, idx)}
                                                />
                                            </div>
                                            <i
                                                className='ri-delete-bin-line fs-24'
                                                role='button'
                                                onClick={() => documentOnRemove(idx)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value={(selectedBot?.botId ? 'Update' : 'Save') + ' AI Assistant'}
                        type='submit'
                        loading={loading}
                        fitParent={false}
                        form='bot-form'
                    />

                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default BotAddEditModal;
