import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import IAnswer from '@src/interfaces/IAnswer';
import IQuestion from '@src/interfaces/IQuestion';
import { getQuestionnaire, saveAnwers } from '@src/services/QuestionnaireService';
import globalStore from '@src/stores/globalStore';
import registrationStore from '@src/stores/registrationStore';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, errorToast, getLoggedUser, sucessToast } from '@src/utils/helpers';
import _ from 'lodash';
const useQuestionnaire = () => {
    let loggedUser = getLoggedUser();
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const setRegistrationUser = registrationStore((state) => state.setRegistrationUser);

    const [questions, setQuestions] = useState([] as Array<IQuestion>);
    const [answers, setAnswers] = useState({} as { [key: string]: IAnswer });
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);

        try {
            const { success, data, message } = await getQuestionnaire();

            if (success) {
                setQuestions(data);
                if (loggedUser.questionanaire) {
                    const ans = {} as { [key: string]: IAnswer };
                    data.forEach((question) => {
                        const scrappedAnswer = loggedUser.questionanaire?.find(
                            (a) => a.questionId === question.questionId
                        );
                        ans[question.questionId] = {
                            questionId: question.questionId,
                            answer: scrappedAnswer?.answer || '',
                            isTouched: !!scrappedAnswer?.answer
                        } as IAnswer;
                    });
                    setAnswers(_.cloneDeep(ans));
                }
            } else {
                errorToast(message);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const onInputChange = (questionId: string, value: string) => {
        answers[questionId].answer = value;
        setAnswers(_.cloneDeep(answers));
    };

    const onBlur = (questionId: string) => {
        answers[questionId].isTouched = true;
        setAnswers(_.cloneDeep(answers));
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isValid = true;
        Object.values(answers).forEach((answer) => {
            answer.isTouched = true;
            if (!answer.answer) isValid = false;
        });
        if (isValid) {
            await handleSubmit();
        } else {
            setAnswers(_.cloneDeep(answers));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const answersArray = Object.values(answers);
        const { success, message } = await saveAnwers(loggedUser, answersArray);
        setLoading(false);

        if (success) {
            localStorage.setItem(globals.userSessionKey, encryptData({ ...loggedUser, questionanaire: answersArray }));

            if (!loggedUser.questionanaire) {
                sucessToast('Thank you for your answers!');
                //Clean registration user
                setRegistrationUser({} as any);
                navigate(RoutesName.dashboard, { replace: true });
            } else {
                sucessToast('Answers were saved!');
            }
        } else {
            errorToast(message);
        }
    };

    return {
        questions,
        loading,
        answers,
        loggedUser,
        onBlur,
        onSubmit,
        onInputChange,
        handleSubmit,
        navigate
    };
};

export default useQuestionnaire;
