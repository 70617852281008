import { useEffect, useState } from 'react';
import 'cleave.js/dist/addons/cleave-phone.in';
import { globals } from '@src/utils/constants';
import { createPaymentIntent } from '@src/services/PaymentService';
import { encryptData, errorToast, getLoggedUser, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useStripe } from '@stripe/react-stripe-js';
import { updateUserFields, getUser } from '@src/services/UserService';
import { createAutoCharge, getAutoChargeByUser, updateAutoCharge } from '@src/services/AutoChargeService';
import IAutoCharge from '@src/interfaces/IAutoCharge';
import { autoChargeInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Decimal from 'decimal.js';

interface IUseOverviewTab {
    setRefreshBillingHistoryData: (value: boolean) => void;
}
const useOverviewTab = ({ setRefreshBillingHistoryData }: IUseOverviewTab) => {
    const loggedUser = getLoggedUser();
    const setLoading = globalStore((state) => state.setLoading);
    const loading = globalStore((state) => state.loading);
    const stripe = useStripe();

    const [showEnableAutoRecharge, setShowEnableAutoRecharge] = useState(false);
    const [showAddBalance, setShowAddBalance] = useState(false);
    const [rechargeAmount, setRechargeAmount] = useState(globals.minAmountToRecharge);
    const [autoCharge, setAutoCharge] = useState<IAutoCharge>(autoChargeInitValues);

    useEffect(() => {
        getData();
    }, []);

    const autoChargeForm = useFormik({
        initialValues: autoChargeInitValues,
        validationSchema: Yup.object({
            minBalance: Yup.number()
                .min(0, 'Please add a minimun balance to trigger auto recharge')
                .typeError('Please add a minimun balance to trigger auto recharge')
                .required('Please add the minimun balance to trigger auto recharge'),
            amountToCharge: Yup.number()
                .min(globals.minAmountToRecharge, `The minimun amount to add is $${globals.minAmountToRecharge}`)
                .required(`The minimun amount to add is $${globals.minAmountToRecharge}`)
        }),
        onSubmit: async (values: IAutoCharge, { resetForm }) => {
            console.log('submit', values);

            setLoading(true);

            let response;
            values.isActive = true;
            if (values?.autoChargeId) response = await updateAutoCharge(values);
            else response = await createAutoCharge(values);

            if (response.success) {
                setAutoCharge(response.data);
                sucessToast('Auto recharge settings updated successfully!');
                setShowEnableAutoRecharge(false);
                autoChargeForm.setValues(response.data);
            } else errorToast(response.message);
            setLoading(false);
        }
    });

    const getData = async () => {
        setLoading(true);
        const promises = await Promise.all([getUser(loggedUser.userId!), getAutoChargeByUser(loggedUser.userId!)]);
        const userResult = promises[0];
        const autoChargeResult = promises[1];

        if (userResult.success) {
            loggedUser.creditBalance = userResult.data.creditBalance;
            localStorage.setItem(globals.userSessionKey, encryptData(loggedUser));
        } else errorToast(userResult.message);

        if (autoChargeResult.success) {
            setAutoCharge(autoChargeResult.data);
            autoChargeForm.setValues(_.cloneDeep(autoChargeResult.data));
        }
        setLoading(false);
    };

    const onRechargeAmountChange = (e: any) => {
        setRechargeAmount(parseInt(e.target.rawValue.toString()) || 0);
    };

    const onAddBalance = async () => {
        if (rechargeAmount < globals.minAmountToRecharge) {
            errorToast('The minimun amount to add is $' + globals.minAmountToRecharge);
            return;
        }
        setLoading(true);

        const {
            success,
            data: clientSecret,
            message
        } = await createPaymentIntent(loggedUser.stripeCustomerId!, rechargeAmount, 'Adding balance');
        if (success) {
            const result = await stripe?.confirmCardPayment(clientSecret);
            if (result?.paymentIntent) {
                const newCreditBalance = new Decimal(loggedUser?.creditBalance || 0).add(rechargeAmount).toNumber();
                const updateResult = await updateUserFields(loggedUser?.userId!, {
                    creditBalance: newCreditBalance
                });
                if (updateResult.success) {
                    loggedUser.creditBalance = updateResult.data.creditBalance;
                    localStorage.setItem(globals.userSessionKey, encryptData(loggedUser));
                    sucessToast('Balance added successfully!');
                    setShowAddBalance(false);
                    setRefreshBillingHistoryData(true);
                    setRechargeAmount(globals.minAmountToRecharge);
                } else errorToast(updateResult.message);
            } else errorToast(result?.error.message);
        } else errorToast(message);
        setLoading(false);
    };

    const handleDisableAutoRecharge = async () => {
        setLoading(true);

        const response = await updateAutoCharge({ ...autoCharge, isActive: false });

        if (response.success) {
            setAutoCharge(response.data);
            sucessToast('Auto recharge settings updated successfully!');
            setShowEnableAutoRecharge(false);
        } else errorToast(response.message);
        setLoading(false);
    };

    const onShowAutoRechargeModal = () => {
        autoChargeForm.setValues(_.cloneDeep(autoCharge));
        setShowEnableAutoRecharge(true);
    };

    return {
        loggedUser,
        autoCharge,
        rechargeAmount,
        showAddBalance,
        showEnableAutoRecharge,
        autoChargeForm,
        loading,
        setShowAddBalance,
        onShowAutoRechargeModal,
        setShowEnableAutoRecharge,
        onRechargeAmountChange,
        onAddBalance,
        handleDisableAutoRecharge
    };
};

export default useOverviewTab;
