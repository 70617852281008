import { RoutesName } from '@src/utils/enums';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

export const NoBalance = () => {
    return (
        <Alert color='warning'>
            You don't have enough credit balance. Please go to the{' '}
            <b>
                <Link className='text-primary' to={RoutesName.billing}>
                    Billing -&gt; Overview
                </Link>
            </b>{' '}
            tab and add <b>credit balance</b> to your account.
        </Alert>
    );
};
