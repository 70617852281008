import { FormButton } from '@src/components/FormButton';
import IUser from '@src/interfaces/IUser';
import moment from 'moment';
import React from 'react';
import { Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

interface IUsersInfoModal {
    show: boolean;
    onHide(): void;
    selectedItem?: IUser;
}

const AdminUsersInfoModal: React.FC<IUsersInfoModal> = ({ show, onHide, selectedItem }) => {
    return (
        <Modal isOpen={show} id='user-info-modal' centered toggle={onHide}>
            <ModalBody>
                <div className='d-flex justify-content-between align-items-start'>
                    <div>
                        <h4>{selectedItem?.name}</h4>
                        <Table className='table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        E-mail:
                                    </th>
                                    <td className='text-muted'>{selectedItem?.email}</td>
                                </tr>
                                <tr className='align-items-center'>
                                    <th className='ps-0' scope='row'>
                                        Is Verified:
                                    </th>
                                    <td className='text-muted'>
                                        {selectedItem?.isVerified ? (
                                            <i className='ri-checkbox-circle-line fs-24 text-success mb-n3' />
                                        ) : (
                                            <i className='ri-error-warning-line fs-24 text-warning' />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Created At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.createdAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Updated At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.updatedAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='text-center mb-2'>
                        <i className='ri-shield-user-line m-2 display-3 text-primary' />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <FormButton value='Aceptar' fitParent={false} onClick={onHide} />
            </ModalFooter>
        </Modal>
    );
};

export default AdminUsersInfoModal;
