import React from 'react';

interface IFormSelectFeedback {
    show: boolean | undefined;
    message?: string;
}

const FormSelectFeedback: React.FC<IFormSelectFeedback> = ({ show, message }) => {
    return (
        <div
            className='invalid-feedback'
            style={{
                display: show ? 'block' : 'none'
            }}
        >
            {message}
        </div>
    );
};

export default FormSelectFeedback;
