import IRole from '@src/interfaces/IRole';
import IUser from '@src/interfaces/IUser';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Table } from 'reactstrap';

interface IClientsInfo {
    user?: IUser;
    autoChargeEnabled: boolean;
}

const ClientsInfo: React.FC<IClientsInfo> = ({ user, autoChargeEnabled }) => {
    return (
        <div className='d-flex justify-content-between align-items-start'>
            <div>
                <h4>{user?.name}</h4>
                <Table className='table-borderless mb-0'>
                    <tbody>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Phone:
                            </th>
                            <td className='text-muted'>{user?.phone}</td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                E-mail:
                            </th>
                            <td className='text-muted'>{user?.email}</td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Address:
                            </th>
                            <td className='text-muted'>{user?.fullAddress}</td>
                        </tr>
                        <tr className=''>
                            <th className='ps-0' scope='row'>
                                Is Verified:
                            </th>
                            <td className=''>
                                {user?.isVerified ? (
                                    <i className='ri-checkbox-circle-line fs-20 text-success mb-n3' />
                                ) : (
                                    <i className='ri-error-warning-line fs-20 text-warning' />
                                )}
                            </td>
                        </tr>
                        <tr className=''>
                            <th className='ps-0' scope='row'>
                                Auto recharge enabled:
                            </th>
                            <td className=''>
                                {autoChargeEnabled ? (
                                    <i className='ri-checkbox-circle-line fs-20 text-success mb-n3' />
                                ) : (
                                    <i className='ri-error-warning-line fs-20 text-warning' />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Open AI Key:
                            </th>
                            <td className='text-muted'>{user?.openaiApiKey || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Referral Code:
                            </th>
                            <td className='text-muted'>{user?.referralCode || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Referral By Code:
                            </th>
                            <td className='text-muted'>
                                {user?.referralBy?.userId ? (
                                    <Link
                                        to={RoutesName.adminClientsInfo.replace(
                                            ':userId',
                                            user?.referralBy?.userId || ''
                                        )}
                                        target='_blank'
                                    >
                                        {user?.referralBy?.name}
                                    </Link>
                                ) : (
                                    'N/A'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Category:
                            </th>
                            <td>
                                <Link to={`${RoutesName.adminBotCategories}/${user?.category?.botCategoryId}`}>
                                    {user?.category?.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Sub Category:
                            </th>
                            <td>
                                <Link
                                    to={`${RoutesName.adminBotSubCategories}/${user?.category?.botCategoryId}/${user?.subCategory?.botSubCategoryId}`}
                                >
                                    {user?.subCategory?.name}
                                </Link>
                            </td>
                        </tr>
                        {globals.showMunicipalityOptions && !!user?.roles?.length && (
                            <tr>
                                <th className='ps-0' scope='row'>
                                    Roles:
                                </th>
                                <td className='text-muted'>
                                    {user?.roles?.map((role: IRole) => (
                                        <Badge key={role.roleId} color='primary' pill>
                                            {role.name}
                                        </Badge>
                                    ))}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th className='ps-0' scope='row'>
                                Created At:
                            </th>
                            <td className='text-muted'>{moment(user?.createdAt).format('MM/DD/YYYY hh:mm A')}</td>
                        </tr>
                        <tr>
                            <th className='ps-0' scope='row'>
                                Updated At:
                            </th>
                            <td className='text-muted'>{moment(user?.updatedAt).format('MM/DD/YYYY hh:mm A')}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ClientsInfo;
