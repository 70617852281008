import profileBg from '@src/assets/images/bg-abstract.jpg';
import { FormButton } from '@src/components/FormButton';
import { IGHLContact } from '@src/interfaces/IGHLContact';
import { createGHLContact } from '@src/services/GoHighLevelService';
import { ImportUser } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { errorToast, generateUniqueId, sucessToast } from '@src/utils/helpers';
import { useState } from 'react';
import { Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap';

const AdminImportUser = () => {
    document.title = 'Import User | Connections';

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const [jsonText, setJsonText] = useState('');

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setJsonText(e.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const data = JSON.parse(jsonText);
            data.password = generateUniqueId();

            const { success, message } = await ImportUser(data);
            if (success) {
                const ghlUser: IGHLContact = {
                    name: data.name,
                    phone: data.phone,
                    tags: ['trial']
                };
                const ghlResponse = await createGHLContact(ghlUser);
                if (ghlResponse.success) {
                    sucessToast(
                        'User created successfully. An email will be sent to the user to complete the registration.'
                    );
                    setJsonText('');
                } else errorToast(ghlResponse.message);
            } else {
                errorToast(message);
            }
        } catch (error: any) {
            errorToast('Invalid JSON data: ', error);
        }
        setLoading(false);
    };

    return (
        <>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>Import User</h2>
                                </div>
                                <div className='mt-6'>
                                    <label htmlFor='allowedDomain' className='col-form-label d-block'>
                                        Paste the json data here to create the user:
                                    </label>

                                    <Input
                                        name='instructions'
                                        type='textarea'
                                        value={jsonText}
                                        className='form-control'
                                        onChange={handleTextChange}
                                        rows={20}
                                    />
                                </div>
                            </CardBody>
                            <CardFooter>
                                <FormButton
                                    value={'Create user'}
                                    type='button'
                                    onClick={handleSubmit}
                                    loading={loading}
                                    fitParent={false}
                                    form='bot-form'
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default AdminImportUser;
