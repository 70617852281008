import { FormButton } from '@src/components/FormButton';
import IUser from '@src/interfaces/IUser';
import 'cleave.js/dist/addons/cleave-phone.in';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IUsersAddEditModal {
    show: boolean;
    formValidation: FormikProps<IUser>;
    loading: boolean;
    onHide(): void;
}

const AdminUsersAddEditModal: React.FC<IUsersAddEditModal> = ({ show, formValidation, loading, onHide }) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    return (
        <Modal isOpen={show} id='user-info-modal' centered backdrop={'static'}>
            <Form
                id='user-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.userId ? 'Edit ' : 'Add'} User</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter your name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name || ''}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Email <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                id='email'
                                name='email'
                                className='form-control'
                                placeholder='Enter email address'
                                type='email'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.email || ''}
                                invalid={formValidation.touched.email && !!formValidation.errors.email}
                            />
                            {formValidation.touched.email && !!formValidation.errors.email && (
                                <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='user-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AdminUsersAddEditModal;
