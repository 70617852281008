import { FormButton } from '@src/components/FormButton';
import OkCancelModal from '@src/components/OkCancelModal';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import { getInitialCCDigitsPlaceholder, pad } from '@src/utils/helpers';
import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Link } from 'react-router-dom';
import { Alert, Col, Label, Modal, ModalBody, ModalFooter, Row, TabPane } from 'reactstrap';
import usePaymentMethodsTab from './usePaymentMethodsTab';

interface IPaymentMethodsTab {
    paymentMethods: Array<IStripePaymentMethod>;
    defaultPaymentMethod?: IStripePaymentMethod | null;
    getData(): Promise<void>;
}

const PaymentMethodsTab: React.FC<IPaymentMethodsTab> = ({ paymentMethods, defaultPaymentMethod, getData }) => {
    const {
        onOpenPMModal,
        loggedUser,
        showDeletePMModal,
        selectedCard,
        loading,
        showPMModal,
        onSetDefaultPM,
        onDeletePM,
        handleDelete,
        setShowDeletePMModal,
        handleAddPM,
        setShowPMModal
    } = usePaymentMethodsTab({ paymentMethods, getData });

    return (
        <>
            <TabPane tabId='2' className='mx-4'>
                <div className='mb-4'>
                    <FormButton onClick={onOpenPMModal} fitParent={false}>
                        <i className='ri-add-line align-bottom'></i> Add New Card
                    </FormButton>
                </div>
                <Row className='mx-0'>
                    {!paymentMethods.length && <Alert color='info'>No payment methods added yet!</Alert>}

                    {paymentMethods.map((paymentMethod, idx) => (
                        <Col md={4} key={idx} className='d-flex flex-column align-items-center'>
                            <div>
                                <Cards
                                    preview
                                    issuer={paymentMethod.card.brand}
                                    expiry={`${pad(paymentMethod.card.expMonth)}${paymentMethod.card.expYear}`}
                                    cvc={'000'}
                                    name={loggedUser.name}
                                    number={
                                        getInitialCCDigitsPlaceholder(paymentMethod.card.brand) +
                                        paymentMethod.card.last4
                                    }
                                />
                                {paymentMethod.id === defaultPaymentMethod?.id && (
                                    <div className='position-absolute ribbon-box' style={{ top: 0 }}>
                                        <div className='ribbon-two ribbon-two-primary'>
                                            <span>Default</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='mb-2' />
                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                {defaultPaymentMethod?.id != paymentMethod.id && (
                                    <>
                                        <Link to='#' className='me-2' onClick={() => onSetDefaultPM(paymentMethod)}>
                                            Set as default
                                        </Link>
                                        |
                                        <Link
                                            to='#'
                                            className='ms-2 text-danger'
                                            onClick={() => onDeletePM(paymentMethod)}
                                        >
                                            Delete
                                        </Link>
                                    </>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </TabPane>
            <OkCancelModal
                title={`Are you sure you want to delete the card: <br /><b>${selectedCard?.card.brand} with ending: ${selectedCard?.card.last4}</b>?`}
                subTitle='You can later add more payment methods.'
                show={showDeletePMModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeletePMModal(false)}
                cancelText='Cancel'
                loading={loading}
            />

            <Modal isOpen={showPMModal} id='new-bot-modal' backdrop={'static'} centered>
                <ModalBody>
                    <div>
                        <div className='mb-3 '>
                            <Label htmlFor='name' className='form-label mb-3'>
                                Payment method <span className='text-danger'>*</span>
                            </Label>
                            <CardElement className='pe-8' options={{ hidePostalCode: true }} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton value='Save' onClick={handleAddPM} fitParent={false} />
                    <FormButton value='Cancel' color='light' onClick={() => setShowPMModal(false)} fitParent={false} />
                </ModalFooter>
            </Modal>
        </>
    );
};

export default PaymentMethodsTab;
