import HttpService from '@src/utils/HttpService';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createBotSubCategory = async (subCategory: IBotSubCategory) => {
    const response = { data: {} as IBotSubCategory, success: false, message: '' };

    try {
        const endPoint = '/subcategories/create';
        const formatApiData = objToSnake(subCategory);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBotSubCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotSubCategoryByCategory = async (categoryId: string, subCategoryId: string) => {
    const response = { data: {} as IBotSubCategory, success: false, message: '' };

    try {
        const endPoint = `/subcategories/${categoryId}/${subCategoryId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBotSubCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotSubCategories = async () => {
    const response = { data: [] as Array<IBotSubCategory>, success: false, message: '' };

    try {
        const endPoint = `/subcategories`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item));
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as Array<IBotSubCategory>;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotSubCategoriesByCategory = async (categoryId: string) => {
    const response = { data: [] as Array<IBotSubCategory>, success: false, message: '' };

    try {
        const endPoint = `/subcategories/${categoryId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item));
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as Array<IBotSubCategory>;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateBotSubCategory = async (subCategory: IBotSubCategory) => {
    const response = { data: {} as IBotSubCategory, success: false, message: '' };

    try {
        const endPoint = `/subcategories/update/${subCategory.botSubCategoryId}`;
        const formatApiData = objToSnake(subCategory);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBotSubCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteBotSubCategory = async (botSubCategory: IBotSubCategory) => {
    const response = { data: {} as IBotSubCategory, success: false, message: '' };

    try {
        const endPoint = `/subcategories/${botSubCategory.botCategory?.botCategoryId}/${botSubCategory.botSubCategoryId}`;

        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data) as IBotSubCategory;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
