import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { Link } from 'react-router-dom';
import useAdminLeads from './useAdminLeads';
import { CustomTable } from '@src/components/CustomTable';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import 'cleave.js/dist/addons/cleave-phone.in';
import moment from 'moment-timezone';
import ILead from '@src/interfaces/ILead';
import OkCancelModal from '@src/components/OkCancelModal';
import LeadAddEditModal from './partials/LeadAddEditModal';

const AdminLeadsList = () => {
    document.title = 'Leads | Connections';

    const {
        leads,
        selectedLead,
        formValidation,
        showDeleteModal,
        onEditClick,
        showAddEditModal,
        loading,
        setShowDeleteModal,
        handleDelete,
        onDeleteClick,
        setShowAddEditModal
    } = useAdminLeads();

    const columnHelper = createColumnHelper<ILead>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Name',
            accessorKey: 'name'
        },
        {
            header: 'Email',
            accessorKey: 'email'
        },
        {
            header: 'Phone',
            accessorKey: 'phone'
        },
        {
            header: 'Created At',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        columnHelper.display({
            header: 'Actions',
            size: 60,
            cell: ({ row }) => (
                <ul className='list-inline hstack gap-2 mb-0'>
                    <li className='list-inline-item edit'>
                        <Link
                            to='#'
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onEditClick(row.original)}
                        >
                            <i className='ri-pencil-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            className='text-danger d-inline-block remove-item-btn'
                            onClick={() => onDeleteClick(row.original)}
                        >
                            <i className='ri-delete-bin-5-fill fs-16'></i>
                        </Link>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>Leads</h2>
                                </div>

                                <CustomTable data={leads} columns={columns} className='mt-6' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <LeadAddEditModal
                show={showAddEditModal}
                onHide={() => setShowAddEditModal(false)}
                loading={loading}
                formValidation={formValidation}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br /><b>${selectedLead?.name}</b>?`}
                subTitle="If you continue, you won't be able to recover the information of this record."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </React.Fragment>
    );
};

export default AdminLeadsList;
