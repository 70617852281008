import { FormButton } from '@src/components/FormButton';
import { NoPaymentMethod } from '@src/components/NoPaymentMethod';
import OkCancelModal from '@src/components/OkCancelModal';
import PlaceHolder from '@src/components/PlaceHolder';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import { globals } from '@src/utils/constants';
import { roundNumber } from '@src/utils/helpers';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import Decimal from 'decimal.js';
import React from 'react';
import { Alert, Col, Form, FormFeedback, Label, Row, TabPane } from 'reactstrap';
import useOverviewTab from './useOverviewTab';

interface IOverviewTab {
    defaultPaymentMethod?: IStripePaymentMethod;
    setRefreshBillingHistoryData: (value: boolean) => void;
}

const OverviewTab: React.FC<IOverviewTab> = ({ defaultPaymentMethod, setRefreshBillingHistoryData }) => {
    const {
        loggedUser,
        autoCharge,
        rechargeAmount,
        showAddBalance,
        showEnableAutoRecharge,
        autoChargeForm,
        loading,
        setShowAddBalance,
        onShowAutoRechargeModal,
        setShowEnableAutoRecharge,
        onRechargeAmountChange,
        onAddBalance,
        handleDisableAutoRecharge
    } = useOverviewTab({ setRefreshBillingHistoryData });

    if (loading)
        return (
            <TabPane tabId='1'>
                <PlaceHolder />
            </TabPane>
        );

    return (
        <>
            <TabPane tabId='1'>
                <div className='p-5'>
                    {!defaultPaymentMethod && <NoPaymentMethod />}
                    <h2>Pay as you go!</h2>
                    <h4 className='fw-semibold fs-22 mt-5'>Your credit balance</h4>
                    <h1 className='text-primary display-1'>${roundNumber(loggedUser?.creditBalance || 0, 6)}</h1>
                    <div
                        className='d-flex align-items-center'
                        style={{ width: 'fit-content' }}
                        role='button'
                        onClick={() => setShowAddBalance(true)}
                    >
                        <i className='ri-add-circle-line fs-1 me-2 text-primary'></i> Add to your Balance
                    </div>
                    <hr />
                    <div className='d-flex align-items-center'>
                        <span>Auto recharge:</span>
                        <span className='text-muted ms-3'>{autoCharge.isActive ? 'on' : 'off'}</span>
                        <span
                            className={'mdi mdi-circle ms-2 fs-20 text-' + (autoCharge.isActive ? 'success' : 'danger')}
                        ></span>
                    </div>
                    {autoCharge.isActive ? (
                        <>
                            <p className='text-muted mt-2'>
                                When your credit balance drops below <b>${autoCharge.minBalance}</b>, an automatic
                                recharge process will be initiated to add <b>${autoCharge.amountToCharge}</b> to your
                                balance.
                            </p>
                            <FormButton fitParent={false} onClick={onShowAutoRechargeModal}>
                                Update auto recharge options
                            </FormButton>
                        </>
                    ) : (
                        <>
                            <p className='text-muted mt-2'>
                                When your credit balance reaches $0, the functionality of your AI Assistants will cease.
                                To ensure an uninterrupted service, we recommend enabling automatic recharge to maintain
                                a sufficient credit balance automatically.
                            </p>
                            <FormButton
                                fitParent={false}
                                color='success'
                                onClick={() => setShowEnableAutoRecharge(true)}
                            >
                                Enable auto recharge
                            </FormButton>
                        </>
                    )}
                </div>
            </TabPane>

            <OkCancelModal
                show={showAddBalance}
                type='none'
                yesText='Add balance'
                hideYesButton={!defaultPaymentMethod}
                cancelText='Cancel'
                onAccept={onAddBalance}
                onCancel={() => setShowAddBalance(false)}
                loading={loading}
                title='Add balance'
                subTitle={
                    defaultPaymentMethod
                        ? 'This will add more credit balance to your account for your AI Assistants services.'
                        : ''
                }
                bodyContent={
                    defaultPaymentMethod ? (
                        <div className='mb-3'>
                            <Row className='d-flex justify-content-center mt-2'>
                                <Col className='col-3'>
                                    <Cleave
                                        id='recharge-amount'
                                        name='recharge-amount'
                                        className={
                                            'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                            (rechargeAmount < globals.minAmountToRecharge
                                                ? 'is-invalid form-control'
                                                : '')
                                        }
                                        options={{
                                            delimiterLazyShow: true,
                                            delimiters: ['$'],
                                            blocks: [0, 10],
                                            numericOnly: true
                                        }}
                                        value={rechargeAmount}
                                        onChange={onRechargeAmountChange}
                                    />
                                </Col>
                                {rechargeAmount < globals.minAmountToRecharge && (
                                    <FormFeedback type='invalid' style={{ display: 'block' }}>
                                        The minimun amount to add is ${globals.minAmountToRecharge}
                                    </FormFeedback>
                                )}
                            </Row>
                            <Alert color='primary' className='mt-3'>
                                Default Payment method: <b>{defaultPaymentMethod?.card?.brand}</b> ending in{' '}
                                {defaultPaymentMethod?.card?.last4}
                            </Alert>
                            <span className='text-muted w-100 d-block text-start'>
                                You can change the default payment method on the <b>Billing -&gt; Payment methods </b>{' '}
                                tab.
                            </span>

                            <Alert color='success' className='w-100 d-flex flex-column align-items-start mt-2'>
                                <span className='text-muted'>
                                    Your current balance is: &nbsp;&nbsp;&nbsp;
                                    <b>${roundNumber(loggedUser?.creditBalance || 0, 6)}</b>
                                </span>
                                <span className='text-muted'>
                                    Your new balance will be:&nbsp;
                                    <b>
                                        $
                                        {roundNumber(
                                            new Decimal(loggedUser?.creditBalance || 0).add(rechargeAmount).toNumber(),
                                            6
                                        )}
                                    </b>
                                </span>
                            </Alert>
                        </div>
                    ) : (
                        <NoPaymentMethod />
                    )
                }
            />

            <OkCancelModal
                show={showEnableAutoRecharge}
                type='none'
                loading={loading}
                yesText={autoCharge.isActive ? 'Save' : 'Enable'}
                hideYesButton={!defaultPaymentMethod}
                cancelText='Cancel'
                onAccept={autoChargeForm.handleSubmit}
                onCancel={() => setShowEnableAutoRecharge(false)}
                title='Auto recharge balance'
                subTitle={
                    defaultPaymentMethod
                        ? 'This will enable auto recharge balance to ensure an uninterrupted service for your AI Assistants!'
                        : ''
                }
                bodyContent={
                    defaultPaymentMethod ? (
                        <Form
                            id='auto-recharge-form'
                            onSubmit={(e) => {
                                e.preventDefault();
                                console.log(autoChargeForm.errors);
                                autoChargeForm.handleSubmit();
                                return false;
                            }}
                        >
                            <div className='mb-3'>
                                <Label htmlFor='name' className='form-label'>
                                    Minimun balance to trigger auto recharge <span className='text-danger'>*</span>
                                </Label>
                                <Row className='d-flex justify-content-center mt-2'>
                                    <Col md={3}>
                                        <Cleave
                                            id='minBalance'
                                            name='minBalance'
                                            className={
                                                'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                (autoChargeForm.errors.minBalance ? 'is-invalid form-control' : '')
                                            }
                                            options={{
                                                delimiterLazyShow: true,
                                                delimiters: ['$'],
                                                blocks: [0, 10],
                                                numericOnly: true
                                            }}
                                            value={autoChargeForm.values.minBalance || 0}
                                            onChange={(e: any) =>
                                                autoChargeForm.setFieldValue('minBalance', parseInt(e.target.rawValue))
                                            }
                                        />
                                    </Col>
                                    {autoChargeForm.errors.minBalance && (
                                        <FormFeedback type='invalid' style={{ display: 'block' }}>
                                            {autoChargeForm.errors.minBalance}
                                        </FormFeedback>
                                    )}
                                </Row>
                            </div>
                            <div className='mb-3'>
                                <Label htmlFor='name' className='form-label'>
                                    Amount to be added when auto charge triggers<span className='text-danger'>*</span>
                                </Label>
                                <Row className='d-flex justify-content-center mt-2'>
                                    <Col md={3}>
                                        <Cleave
                                            id='amountToCharge'
                                            name='amountToCharge'
                                            className={
                                                'form-control form-control-lg bg-light border-light text-center p-0 ' +
                                                (autoChargeForm.errors.amountToCharge ? 'is-invalid form-control' : '')
                                            }
                                            options={{
                                                delimiterLazyShow: true,
                                                delimiters: ['$'],
                                                blocks: [0, 10],
                                                numericOnly: true
                                            }}
                                            value={autoChargeForm.values.amountToCharge || 0}
                                            onChange={(e: any) =>
                                                autoChargeForm.setFieldValue(
                                                    'amountToCharge',
                                                    parseInt(e.target.rawValue) || 0
                                                )
                                            }
                                        />
                                    </Col>
                                    {autoChargeForm.errors.amountToCharge && (
                                        <FormFeedback type='invalid' style={{ display: 'block' }}>
                                            {autoChargeForm.errors.amountToCharge}
                                        </FormFeedback>
                                    )}
                                </Row>
                                {autoCharge.isActive && (
                                    <FormButton
                                        className='mt-3'
                                        color='warning'
                                        onClick={handleDisableAutoRecharge}
                                        value='Disable'
                                    />
                                )}
                            </div>
                        </Form>
                    ) : (
                        <NoPaymentMethod />
                    )
                }
            />
        </>
    );
};

export default OverviewTab;
