import classnames from 'classnames';
import 'cleave.js/dist/addons/cleave-phone.in';
import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { RoutesName } from '@src/utils/enums';
import useAdminProfile from './useAdminProfile';

const AdminProfile = () => {
    document.title = 'Profile | Connections';

    const { formInfoValidation, formPasswordValidation, activeTab, validateAddressField, tabChange, navigate } =
        useAdminProfile();

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <div className='pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper'>
                <Row className='g-4'>
                    <Col>
                        <div className='p-2'>
                            <h3 className='text-white mb-1'>Profile</h3>
                        </div>
                    </Col>
                </Row>
            </div>

            <Row className='mt-5'>
                <Col xxl={12}>
                    <Card className='mt-xxl-n5'>
                        <CardHeader>
                            <Nav className='nav-tabs-custom rounded card-header-tabs border-bottom-0' role='tablist'>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => tabChange(1)}
                                        type='button'
                                    >
                                        <i className='fas fa-home'></i>
                                        Personal Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to='#'
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => tabChange(2)}
                                        type='button'
                                    >
                                        <i className='far fa-user'></i>
                                        Change Password
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className='p-4'>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            //To prevent the form to be sent when user press enter when selecting the address
                                            if (document.activeElement?.id == 'address') return;
                                            if (validateAddressField()) formInfoValidation.handleSubmit();
                                            return false;
                                        }}
                                        action='#'
                                    >
                                        <Col lg={6}>
                                            <div className='mb-3'>
                                                <Label htmlFor='name' className='form-label'>
                                                    Name <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    name='name'
                                                    type='text'
                                                    placeholder='Enter your name'
                                                    onChange={formInfoValidation.handleChange}
                                                    onBlur={formInfoValidation.handleBlur}
                                                    value={formInfoValidation.values.name || ''}
                                                    invalid={
                                                        formInfoValidation.touched.name &&
                                                        !!formInfoValidation.errors.name
                                                    }
                                                />
                                                {formInfoValidation.touched.name &&
                                                    !!formInfoValidation.errors.name && (
                                                        <FormFeedback type='invalid'>
                                                            <div>{formInfoValidation.errors.name}</div>
                                                        </FormFeedback>
                                                    )}
                                            </div>
                                            <div className='mb-4'>
                                                <Label htmlFor='email' className='form-label'>
                                                    Email
                                                </Label>
                                                <p>{formInfoValidation.values.email}</p>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className='hstack gap-2 justify-content-start'>
                                                <button type='submit' className='btn btn-primary'>
                                                    Save changes
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-light'
                                                    onClick={() => navigate(RoutesName.adminDashboard)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form>
                                </TabPane>

                                <TabPane tabId={2}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            formPasswordValidation.handleSubmit();
                                            return false;
                                        }}
                                        action='#'
                                    >
                                        <Col lg={4}>
                                            <div className='mb-3'>
                                                <Label className='form-label' htmlFor='password-input'>
                                                    Current Password <span className='text-danger'>*</span>
                                                </Label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <Input
                                                        name='currentPassword'
                                                        value={formPasswordValidation.values.currentPassword || ''}
                                                        type={'password'}
                                                        className='form-control pe-5'
                                                        placeholder='Enter Current Password'
                                                        onChange={formPasswordValidation.handleChange}
                                                        onBlur={formPasswordValidation.handleBlur}
                                                        invalid={
                                                            formPasswordValidation.touched.currentPassword &&
                                                            !!formPasswordValidation.errors.currentPassword
                                                        }
                                                    />
                                                    {formPasswordValidation.touched.currentPassword &&
                                                        !!formPasswordValidation.errors.currentPassword && (
                                                            <FormFeedback type='invalid'>
                                                                {formPasswordValidation.errors.currentPassword}
                                                            </FormFeedback>
                                                        )}
                                                </div>
                                            </div>

                                            <div className='mb-3'>
                                                <Label className='form-label' htmlFor='password-input'>
                                                    Password <span className='text-danger'>*</span>
                                                </Label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <Input
                                                        name='password'
                                                        value={formPasswordValidation.values.password || ''}
                                                        type={'password'}
                                                        className='form-control pe-5'
                                                        placeholder='Enter Password'
                                                        onChange={formPasswordValidation.handleChange}
                                                        onBlur={formPasswordValidation.handleBlur}
                                                        invalid={
                                                            formPasswordValidation.touched.password &&
                                                            !!formPasswordValidation.errors.password
                                                        }
                                                    />
                                                    {formPasswordValidation.touched.password &&
                                                        !!formPasswordValidation.errors.password && (
                                                            <FormFeedback type='invalid'>
                                                                {formPasswordValidation.errors.password}
                                                            </FormFeedback>
                                                        )}
                                                </div>
                                            </div>

                                            <div className='mb-2'>
                                                <Label htmlFor='confirmPassword' className='form-label'>
                                                    Confirm Password <span className='text-danger'>*</span>
                                                </Label>
                                                <Input
                                                    name='confirmPassword'
                                                    type='password'
                                                    placeholder='Confirm Password'
                                                    onChange={formPasswordValidation.handleChange}
                                                    onBlur={formPasswordValidation.handleBlur}
                                                    value={formPasswordValidation.values.confirmPassword || ''}
                                                    invalid={
                                                        formPasswordValidation.touched.confirmPassword &&
                                                        !!formPasswordValidation.errors.confirmPassword
                                                    }
                                                />
                                                {formPasswordValidation.touched.confirmPassword &&
                                                    !!formPasswordValidation.errors.confirmPassword && (
                                                        <FormFeedback type='invalid'>
                                                            <div>{formPasswordValidation.errors.confirmPassword}</div>
                                                        </FormFeedback>
                                                    )}
                                            </div>
                                        </Col>
                                        <Col lg={12} className='mt-4'>
                                            <div className='hstack gap-2 justify-content-start'>
                                                <button type='submit' className='btn btn-primary'>
                                                    Change Password
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-light'
                                                    onClick={() => navigate(RoutesName.adminDashboard)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdminProfile;
