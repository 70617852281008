import HttpService from '@src/utils/HttpService';
import IBotCategory from '@src/interfaces/IBotCategory';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createBotCategory = async (category: IBotCategory) => {
    const response = { data: {} as IBotCategory, success: false, message: '' };

    try {
        const endPoint = '/categories/create';
        const formatApiData = objToSnake(category);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBotCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotCategory = async (categoryId: string) => {
    const response = { data: {} as IBotCategory, success: false, message: '' };

    try {
        const endPoint = `/categories/${categoryId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBotCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotCategories = async () => {
    const response = { data: [] as Array<IBotCategory>, success: false, message: '' };

    try {
        const endPoint = `/categories`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item));
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as Array<IBotCategory>;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateBotCategory = async (category: IBotCategory) => {
    const response = { data: {} as IBotCategory, success: false, message: '' };

    try {
        const endPoint = `/categories/update/${category.botCategoryId}`;
        const formatApiData = objToSnake(category);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBotCategory;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteBotCategory = async (botCategoryId: string) => {
    const response = { data: {} as IBotCategory, success: false, message: '' };

    try {
        const endPoint = `/categories/${botCategoryId}`;

        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data) as IBotCategory;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
