import { RoutesName } from '@src/utils/enums';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

export const NoPaymentMethod = () => {
    return (
        <Alert color='warning'>
            You don't have a default Payment method yet. Please go to the{' '}
            <b>
                <Link className='text-primary' to={RoutesName.billing + '?tab=2'}>
                    Billing -&gt; Payment methods
                </Link>
            </b>{' '}
            tab and add one.
        </Alert>
    );
};
