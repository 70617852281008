import 'cleave.js/dist/addons/cleave-phone.in';
import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

// Formik formValidation
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IGHLContact } from '@src/interfaces/IGHLContact';
import { IStepThreeData } from '@src/interfaces/IGeneric';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import { createGHLContact } from '@src/services/GoHighLevelService';
import { createUser, login, sendPhoneCode } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import registrationStore from '@src/stores/registrationStore';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, errorToast, splitAddress, sucessToast } from '@src/utils/helpers';
import { registerInitValues } from '@src/utils/initValues';
import { jwtDecode } from 'jwt-decode';

const useRegistrationStepThree = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const referralToken = searchParams.get('key') || '';
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const registrationUser = registrationStore((state) => state.registrationUser);
    const setGHLContact = registrationStore((state) => state.setGHLContact);

    const referralObj = useMemo(() => (referralToken ? (jwtDecode(referralToken) as any) : undefined), []);
    const [errorMsg, setErrorMsg] = useState('');
    const [forceRefresh, setForceRefresh] = useState(false);
    const [disableNext, setDisableNext] = useState(true);
    const [showPhoneVerification, setShowPhoneVerification] = React.useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [phoneToken, setPhoneToken] = useState('');

    const formValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            email: Yup.string().email('Please enter a valid email address').required('Please enter your email'),
            fullAddress: Yup.string().required(globals.invalidAddressMsg),
            address: Yup.string().required(globals.invalidAddressMsg),
            city: Yup.string().required(globals.invalidAddressMsg),
            state: Yup.string().required(globals.invalidAddressMsg),
            zipcode: Yup.string().required(globals.invalidAddressMsg),
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required('Confirm password is required'),
            phone: Yup.string().required('Please enter your phone'),
            acceptedAgreement: Yup.boolean().oneOf([true], 'Please accept the agreement')
        }),
        onSubmit: async (values: IStepThreeData) => {
            console.log('submit', values);
            if (loading) return;

            setLoading(true);
            setShowPhoneVerification(false);
            setErrorMsg('');
            values.name = registrationUser.name;
            values.companyName = registrationUser.companyName;
            values.category = registrationUser.category;
            values.subCategory = registrationUser.subCategory;
            values.isVerified = true;
            const { data, success, message } = await createUser(values);
            if (success) {
                const ghlUser: IGHLContact = {
                    name: registrationUser.name,
                    phone: values.phone,
                    email: values.email,
                    companyName: registrationUser.companyName,
                    address1: values.address,
                    city: values.city,
                    state: values.state,
                    postalCode: values.zipcode,
                    tags: ['free_trial']
                };
                const ghlResponse = await createGHLContact(ghlUser);
                if (ghlResponse.success) {
                    setGHLContact(ghlResponse.data);
                }

                localStorage.setItem(globals.userSessionKey, encryptData(data));
                await login(values.email, values.password);

                navigate(RoutesName.dashboard, { replace: true });
                sucessToast('Verification completed. Welcome to Connections!');
            } else {
                setErrorMsg(message);
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        formValidation.setFieldValue('referralBy', {
            user_id: referralObj?.user_id,
            referralCode: referralObj?.referral_code
        });
        formValidation.setFieldValue('email', referralObj?.email || '');
    }, [referralObj]);

    useEffect(() => {
        setDisableNext(!formValidation.values.acceptedAgreement);
    }, [formValidation.values.acceptedAgreement]);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    useEffect(() => {
        formValidation.setFieldValue('subCategory', null);
    }, [formValidation.values.category]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        if (registrationUser.email) formValidation.setFieldValue('email', registrationUser.email);

        if (registrationUser.fullAddress) {
            formValidation.setFieldValue('fullAddress', registrationUser.fullAddress);
            formValidation.setFieldValue('address', registrationUser.address);
            formValidation.setFieldValue('city', registrationUser.city);
            formValidation.setFieldValue('state', registrationUser.state);
            formValidation.setFieldValue('zipcode', registrationUser.zipcode);
        }

        console.log(registrationUser);

        setLoading(false);
    };

    const validateAddressField = () => {
        //We only evaluate the zip code, because if theres no zipcode, it means the rest is not there
        return (
            !formValidation.touched.address || (!formValidation.errors.fullAddress && !formValidation.errors.zipcode)
        );
    };

    const onAddressSelected = (place: IGooglePlace) => {
        const addressFields = splitAddress(place);
        formValidation.setFieldValue('address', addressFields?.address);
        formValidation.setFieldValue('city', addressFields?.city);
        formValidation.setFieldValue('state', addressFields?.state);
        formValidation.setFieldValue('zipcode', addressFields?.zipcode);

        setForceRefresh(true);
    };

    const onAddressChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        formValidation.setFieldValue('fullAddress', e.target.value);
        //If user types the address without selecting it, we remove the zip to trigger the validation error
        formValidation.setFieldValue('zipcode', '');

        setForceRefresh(true);
    };

    const beforeSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        //To prevent the form to be sent when user press enter when selecting the address
        if (document.activeElement?.id == 'address') return false;

        if (validateAddressField()) formValidation.handleSubmit();

        return false;
    };

    const validateStepData = async () => {
        setLoading(true);
        await formValidation.validateForm();
        console.log('formValidation.isValid', formValidation.isValid);
        if (formValidation.isValid && validateAddressField()) {
            if (!codeSent) {
                const { success, data, message } = await sendPhoneCode(formValidation.values.phone);
                if (success) {
                    sucessToast('Phone code verification was sent successfully!');
                    setShowPhoneVerification(true);
                    setCodeSent(true);
                    setPhoneToken(data);
                } else {
                    errorToast(message);
                }
            }
            setShowPhoneVerification(true);
        } else {
            formValidation.setTouched({
                email: true,
                fullAddress: true,
                address: true,
                city: true,
                state: true,
                zipcode: true,
                password: true,
                confirmPassword: true,
                phone: true
            });
        }
        setLoading(false);
        return false;
    };

    return {
        formValidation,
        errorMsg,
        loading,
        referralObj,
        disableNext,
        showPhoneVerification,
        phoneToken,
        setShowPhoneVerification,
        validateAddressField,
        onAddressSelected,
        onAddressChanged,
        beforeSubmit,
        validateStepData
    };
};

export default useRegistrationStepThree;
