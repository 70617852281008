import searchAnimation from '@src/assets/animations/searching-animation.json';
import Lottie from 'lottie-react';
import React from 'react';

export const SearchAnimation: React.FC<{}> = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div id='search-animation-container'>
                <Lottie animationData={searchAnimation} loop={true} className='' style={{ transform: 'scaleX(-1)' }} />
            </div>
        </div>
    );
};
