import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from '@src/assets/images/users/user-dummy-img.jpg';
import { useNavigate } from 'react-router-dom';
import { logout } from '@src/services/UserService';
import { getLoggedUser } from '@src/utils/helpers';
import { RoutesName } from '@src/utils/enums';

const AdminProfileDropdown = () => {
    const loggedUser = getLoggedUser(true);
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const navigate = useNavigate();

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className='ms-sm-3 header-item'>
                <DropdownToggle tag='button' type='button' className='btn shadow-none'>
                    <span className='d-flex align-items-center'>
                        <img className='rounded-circle header-profile-user' src={avatar1} alt='Header Avatar' />
                    </span>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                    <h6 className='dropdown-header'>{loggedUser?.name || '<Unknow>'}</h6>
                    <DropdownItem href={RoutesName.adminProfile}>
                        <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
                        <span className='align-middle'>Profile</span>
                    </DropdownItem>

                    <div className='dropdown-divider'></div>

                    <DropdownItem onClick={() => logout(navigate, true)}>
                        <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle' data-key='t-logout'>
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default AdminProfileDropdown;
