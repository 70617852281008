import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import IBot from '@src/interfaces/IBot';
import { IFacebookResponse } from '@src/interfaces/IFacebook';
import { IObj } from '@src/interfaces/IGeneric';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { copyText, errorToast, formatString } from '@src/utils/helpers';
import moment from 'moment';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { FormButton } from './FormButton';

interface IBotInfoCard {
    keyVal: string | number;
    bot: IBot;
    borderColors: IObj;
    onEditBot(bot: IBot): void;
    onDeleteBot(bot: IBot): void;
    onFacebookLogin(res: IFacebookResponse, bot: IBot): void;
    onFacebookLogout(bot: IBot): void;
}

const BotInfoCard: React.FC<IBotInfoCard> = ({
    keyVal,
    bot,
    borderColors,
    onEditBot,
    onDeleteBot,
    onFacebookLogin,
    onFacebookLogout
}) => {
    const downloadQR = async () => {
        const qr = document.getElementById(bot.botId?.replaceAll('asst_', '') || '');
        if (!qr) {
            return errorToast('Something went wrong');
        }

        const svgData = new XMLSerializer().serializeToString(qr);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            if (ctx) {
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
            }
            const pngFile = canvas.toDataURL('image/png', 1.0);

            const downloadLink = document.createElement('a');
            downloadLink.download = 'qrcode';
            downloadLink.href = `${pngFile}`;
            downloadLink.target = '_blank';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            downloadLink.remove();
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };

    return (
        <Col lg={12} key={keyVal + bot.botId!}>
            <Card className='profile-project-card' style={{ boxShadow: 'none', borderLeftWidth: 1 }}>
                <CardBody className='p-4'>
                    <Row>
                        <Col xs={9}>
                            <div className='flex-grow-1 text-muted overflow-hidden'>
                                <h5 className='fs-14 text-truncate'>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar-sm h-auto'>
                                            <img
                                                src={bot.avatar || userDummayImage}
                                                alt='bot-avatar'
                                                className='img-thumbnail rounded-circle'
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = userDummayImage;
                                                }}
                                            />
                                        </div>
                                        <div className='w-100 ms-2 me-4'>
                                            <span className='text-body'>{bot.name}</span>
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div>
                                <h5 className='fs-12 text-muted text-muted mb-1'>
                                    <b>Created: </b>
                                    <span className='fs-11'>{moment(bot.createdAt).fromNow()}</span>
                                </h5>
                            </div>
                            <div>
                                <h5 className='fs-12 text-muted text-muted mb-1'>
                                    <b>Last updated: </b>
                                    <span className='fs-11'>{moment(bot.updatedAt).fromNow()}</span>
                                </h5>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <div className='d-flex justify-content-end'>
                                        <i
                                            className='ri-pencil-fill mx-2 h4'
                                            role='button'
                                            onClick={() => onEditBot(bot)}
                                        />
                                        <Link
                                            to={RoutesName.messagesWithBotId.replace(
                                                ':botId',
                                                bot.botId!.replaceAll('asst_', '')
                                            )}
                                            target='_blank'
                                            className='mx-2'
                                        >
                                            <i className='ri-message-2-fill h4' role='button' />
                                        </Link>
                                        <i
                                            className='ri-delete-bin-7-fill mx-2 h4'
                                            role='button'
                                            onClick={() => onDeleteBot(bot)}
                                        />
                                        <i
                                            className='ri-file-download-fill mx-2 me-0 h4'
                                            role='button'
                                            onClick={downloadQR}
                                        />
                                    </div>
                                    <div
                                        className='d-flex justify-content-end'
                                        style={{
                                            backgroundColor: 'white'
                                        }}
                                    >
                                        <Link
                                            to={formatString(
                                                globals.fullScreenChatUrl,
                                                bot.botId?.replaceAll('asst_', '')
                                            )}
                                            target='_blank'
                                        >
                                            <QRCode
                                                id={bot.botId?.replaceAll('asst_', '')}
                                                size={100}
                                                value={formatString(
                                                    globals.fullScreenChatUrl,
                                                    bot.botId?.replaceAll('asst_', '')
                                                )}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {!bot.isSubscriptionActive && (
                        <Alert color='danger' className='mt-2'>
                            Your subscription for this AI Assistant is inactive, which means, your AI Assistant won't be
                            available for your customers.
                            <br />
                            Please activate the subscription either by clicking the <b>edit</b> button.
                        </Alert>
                    )}
                    <div style={{ display: bot.isSubscriptionActive ? 'block' : 'none' }}>
                        {(!bot.facebookPages || !bot.facebookPages.length) && (
                            <div className='mb-3'>
                                <label htmlFor='allowedDomain' className='col-form-label d-block'>
                                    Connect your AI Assistant to your Facebook Page
                                </label>
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''} // Replace with your Facebook App ID
                                    fields='name,email,accounts'
                                    cookie={true}
                                    scope='page_events,pages_messaging,pages_manage_engagement,pages_show_list'
                                    callback={(res: IFacebookResponse) => onFacebookLogin(res, bot)}
                                    icon='fa-facebook'
                                    textButton='  Connect'
                                    cssClass='btn btn-primary'
                                />
                            </div>
                        )}
                        {!!bot.facebookPages && !!bot.facebookPages.length && (
                            <div className='mb-3'>
                                <label htmlFor='allowedDomain' className='col-form-label d-block'>
                                    Disconnect your AI Assistant from your Facebook Page
                                </label>
                                <div className='mb-2'>
                                    {bot.facebookPages.map((page) => (
                                        <span key={page.id} className='badge border border-primary text-primary'>
                                            {page.name}
                                        </span>
                                    ))}
                                </div>
                                <FormButton
                                    onClick={() => onFacebookLogout(bot)}
                                    className='btn button-facebook'
                                    fitParent={false}
                                >
                                    <i className='ri-facebook-fill me-2' />
                                    Disconnect
                                </FormButton>
                            </div>
                        )}
                        <div>
                            <Label htmlFor='text-area-ecode' className='form-label'>
                                Embed code
                                <i
                                    className='ri-file-copy-line m-2 h4'
                                    role='button'
                                    onClick={() => copyText(formatString(globals.embedCode, bot.botId))}
                                />
                            </Label>
                            <textarea
                                className='form-control'
                                id='text-area-ecode'
                                rows={2}
                                disabled
                                value={formatString(globals.embedCode, bot.botId?.replaceAll('asst_', ''))}
                            />
                        </div>

                        <div style={{ visibility: !bot.allowedDomain ? 'visible' : 'hidden' }}>
                            <Label htmlFor='text-area-icode' className='form-label'>
                                Iframe code
                                <i
                                    className='ri-file-copy-line m-2 h4'
                                    role='button'
                                    onClick={() =>
                                        copyText(
                                            formatString(globals.embedIframeCode, bot.botId?.replaceAll('asst_', ''))
                                        )
                                    }
                                />
                            </Label>
                            <textarea
                                className='form-control'
                                id='text-area-icode'
                                rows={2}
                                disabled
                                value={formatString(globals.embedIframeCode, bot.botId?.replaceAll('asst_', ''))}
                            />
                        </div>

                        {!bot.allowedDomain && (
                            <Alert color='warning' className='mt-2'>
                                Your AI Assistant can be used on <b>any domain</b>. If you want to secure it, please
                                edit the AI Assistant and Add the "Allowed Domain" in the <b>advanced settings</b>{' '}
                                <br />
                                Note: if you do this nobody will be able to use your AI Assistant on another{' '}
                                <b>domain</b> or inside of an <b>iframe</b>.
                            </Alert>
                        )}
                        {bot.allowedDomain && (
                            <p className='text-muted mt-2'>
                                <b>Important:</b> Your AI Assistant can only be use on: <b>{bot.allowedDomain}</b>
                                <br />
                                The use of this AI Assistant on an <b>iframe</b> will be <b>denied</b>.
                                <br />
                                But the AI Assistant can still be used in <b>full screen mode</b> by using the
                                <b> QR code image</b> generated above.
                            </p>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default BotInfoCard;
