import Billing from '@src/pages/app/Billing';
import Dashboard from '@src/pages/app/Dashboard';
import Messages from '@src/pages/app/Messages';
import PhoneVerification from '@src/pages/app/PhoneVerification';
import Profile from '@src/pages/app/Profile';
import Questionnaire from '@src/pages/app/Questionnaire';
import Protected from '@src/partials/ProtectedLayout';
import { RoutesName } from '@src/utils/enums';

export const privateRoutes = [
    { path: RoutesName.dashboard, component: <Protected children={<Dashboard />} /> },
    { path: RoutesName.messages, component: <Protected children={<Messages />} /> },
    { path: RoutesName.messagesWithBotId, component: <Protected children={<Messages />} /> },
    { path: RoutesName.billing, component: <Protected children={<Billing />} /> },
    { path: RoutesName.profile, component: <Protected children={<Profile />} /> },
    { path: RoutesName.questionnaire, component: <Protected children={<Questionnaire />} /> },
    { path: RoutesName.phoneVerification, component: <Protected children={<PhoneVerification />} /> }
];
