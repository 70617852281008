import { Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

import logoLight from '@src/assets/images/logo_connections.png';
import { FormButton } from '@src/components/FormButton';
import { RoutesName } from '@src/utils/enums';
import { Link } from 'react-router-dom';
import useLogin from './useLogin';

const Login = () => {
    const { formValidation, errorMsg, loading, passwordShow, setPasswordShow } = useLogin();

    document.title = ' Sign in | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-16 fw-semibold'>Create your own assistant for your business!</p>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                        <CardBody className='p-4'>
                            <div className='text-center mt-2'>
                                <h5 className='text-primary'>Welcome!</h5>
                                <p className='text-muted'>Sign in to continue.</p>
                            </div>
                            {!!errorMsg && <Alert color='danger'> {errorMsg} </Alert>}
                            <div className='p-2 mt-4'>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        console.log(formValidation.errors);
                                        formValidation.handleSubmit();
                                        return false;
                                    }}
                                    action='#'
                                >
                                    <div className='mb-3'>
                                        <Label htmlFor='email' className='form-label'>
                                            Email
                                        </Label>
                                        <Input
                                            name='email'
                                            className='form-control'
                                            placeholder='Enter email'
                                            type='email'
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            value={formValidation.values.email || ''}
                                            invalid={formValidation.touched.email && !!formValidation.errors.email}
                                        />
                                        {formValidation.touched.email && !!formValidation.errors.email && (
                                            <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                                        )}
                                    </div>

                                    <div className='mb-3'>
                                        <div className='float-end'>
                                            <Link to='/forgot-password' className='text-muted'>
                                                Forgot password?
                                            </Link>
                                        </div>
                                        <Label className='form-label' htmlFor='password-input'>
                                            Password
                                        </Label>
                                        <div className='position-relative auth-pass-inputgroup mb-3'>
                                            <Input
                                                name='password'
                                                value={formValidation.values.password || ''}
                                                type={passwordShow ? 'text' : 'password'}
                                                className='form-control pe-5'
                                                placeholder='Enter Password'
                                                onChange={formValidation.handleChange}
                                                onBlur={formValidation.handleBlur}
                                                invalid={
                                                    formValidation.touched.password && !!formValidation.errors.password
                                                }
                                            />
                                            {formValidation.touched.password && !!formValidation.errors.password && (
                                                <FormFeedback type='invalid'>
                                                    {formValidation.errors.password}
                                                </FormFeedback>
                                            )}
                                            <button
                                                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                                                type='button'
                                                id='password-addon'
                                                onClick={() => setPasswordShow(!passwordShow)}
                                            >
                                                <i className='ri-eye-fill align-middle'></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <FormButton value='Sign In' type='submit' loading={loading} />
                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>

                    <div className='mt-4 text-center'>
                        <p className='mb-0'>
                            Don't have an account?{' '}
                            <Link
                                to={RoutesName.registrationWizard}
                                className='fw-semibold text-primary text-decoration-underline'
                            >
                                Register
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Login;
