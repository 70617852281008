import { IGoogleContact } from '@src/interfaces/IGoogleContact';
import HttpService from '@src/utils/HttpService';
import { globals } from '@src/utils/constants';

export const getContacts = async (accessToken: string) => {
    const response = { data: [] as Array<IGoogleContact>, success: false, message: '' };

    try {
        const endPoint = globals.googleContactsEndpoint;

        const { data } = await HttpService.get(endPoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        console.log('data', data);
        response.data = data.connections;
        response.data = response.data.filter((c) => c.names && (c.emailAddresses || c.phoneNumbers));
        response.success = true;
    } catch (error: any) {
        console.error(error);
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
