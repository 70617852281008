import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IBotCategory from '@src/interfaces/IBotCategory';
import React from 'react';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label } from 'reactstrap';
import WizardControls from '../../Controls';
import useRegistrationStepOne from './useRegistrationStepOne';

const RegistrationStepOne: React.FC = () => {
    const { formValidation, categories } = useRegistrationStepOne();

    return (
        <WizardControls processNext={formValidation.handleSubmit}>
            <div className='p-2 mt-4'>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        formValidation.handleSubmit();
                        formValidation.setFieldTouched('category', true);
                        formValidation.setFieldTouched('subCategory', true);
                        return false;
                    }}
                    action='#'
                >
                    <div className='mb-3'>
                        <Label htmlFor='name' className='form-label'>
                            Legal Name <span className='text-danger'>*</span>
                        </Label>
                        <Input
                            name='name'
                            type='text'
                            placeholder='Enter your legal name'
                            value={formValidation.values.name}
                            onChange={formValidation.handleChange}
                            onBlur={formValidation.handleBlur}
                            invalid={formValidation.touched.name && !!formValidation.errors.name}
                        />
                        {formValidation.touched.name && !!formValidation.errors.name && (
                            <FormFeedback type='invalid'>
                                <div>{formValidation.errors.name}</div>
                            </FormFeedback>
                        )}
                    </div>

                    <div className='mb-3'>
                        <Label htmlFor='website' className='form-label'>
                            Business Name <span className='text-danger'>*</span>
                        </Label>
                        <Input
                            id='companyName'
                            name='companyName'
                            type='url'
                            className='form-control'
                            placeholder='Add your business name'
                            onChange={formValidation.handleChange}
                            onBlur={formValidation.handleBlur}
                            value={formValidation.values.companyName || ''}
                            invalid={formValidation.touched.companyName && !!formValidation.errors.companyName}
                        />

                        {formValidation.touched.companyName && !!formValidation.errors.companyName && (
                            <FormFeedback type='invalid'>
                                <div>{formValidation.errors.companyName}</div>
                            </FormFeedback>
                        )}
                    </div>
                    <div className='mb-3'>
                        <Label htmlFor='category' className='form-label'>
                            Category <span className='text-danger'>*</span>
                        </Label>
                        <Select
                            name='category'
                            value={formValidation.values.category}
                            onChange={(category) => formValidation.setFieldValue('category', category)}
                            getOptionLabel={(c: IBotCategory) => c.name}
                            getOptionValue={(c: IBotCategory) => c.botCategoryId}
                            options={categories}
                            onBlur={() => formValidation.setFieldTouched('category', true)}
                            classNames={{
                                control: () =>
                                    formValidation.touched.category && !!formValidation.errors.category
                                        ? 'form-control is-invalid'
                                        : ''
                            }}
                        />
                        {/* To show the error for react-select */}
                        <FormSelectFeedback
                            show={formValidation.touched.category && !!formValidation.errors.category}
                            message={formValidation.errors.category}
                        />
                    </div>

                    {!!formValidation.values.category && (
                        <div className='mb-3'>
                            <Label htmlFor='subCategory' className='form-label'>
                                Sub Category <span className='text-danger'>*</span>
                            </Label>
                            {formValidation.values.category?.botSubcategories?.map((subCategory) => (
                                <div className='form-check mb-2'>
                                    <Input
                                        className='form-check-input'
                                        type='radio'
                                        name='subCategory'
                                        id={subCategory.botSubCategoryId}
                                        value={subCategory.botSubCategoryId}
                                        onChange={(subCategoryId) =>
                                            formValidation.setFieldValue(
                                                'subCategory',
                                                formValidation.values.category?.botSubcategories?.find(
                                                    (sc) => sc.botSubCategoryId === subCategoryId.target.value
                                                )
                                            )
                                        }
                                    />
                                    <Label className='form-check-label fw-normal' for={subCategory.botSubCategoryId}>
                                        {subCategory.name}
                                    </Label>
                                </div>
                            ))}

                            <FormSelectFeedback
                                show={formValidation.touched.subCategory && !!formValidation.errors.subCategory}
                                message={formValidation.errors.subCategory}
                            />
                        </div>
                    )}
                </Form>
            </div>
        </WizardControls>
    );
};

export default RegistrationStepOne;
