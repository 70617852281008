import { logout, resendPhoneCode, updateUser, validatePhoneCode } from '@src/services/UserService';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, errorToast, getLoggedUser, sucessToast } from '@src/utils/helpers';
import { registrationVerificationInitValues } from '@src/utils/initValues';
import 'cleave.js/dist/addons/cleave-phone.in';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

interface IPhoneVerificationProps {
    phone?: string;
    token?: string;
    afterVerification?: () => void;
}

const usePhoneVerification = ({ phone, token, afterVerification }: IPhoneVerificationProps) => {
    const loggedUser = getLoggedUser();

    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formValidation = useFormik({
        initialValues: registrationVerificationInitValues,
        validationSchema: Yup.object({
            digit1: Yup.string().required(),
            digit2: Yup.string().required(),
            digit3: Yup.string().required(),
            digit4: Yup.string().required()
        }),
        onSubmit: async (values) => {
            console.log('submit', values);
            setLoading(true);
            setErrorMsg('');

            try {
                const code = values.digit1 + values.digit2 + values.digit3 + values.digit4;
                const { data, success } = await validatePhoneCode(token ?? loggedUser.token!, code);
                if (success && data) {
                    if (afterVerification) afterVerification();
                    else {
                        loggedUser.isVerified = true;
                        const { data, success, message } = await updateUser(loggedUser);
                        if (success) {
                            localStorage.setItem(globals.userSessionKey, encryptData(data));

                            navigate(RoutesName.dashboard, { replace: true });

                            sucessToast('Phone verification completed!');
                        } else {
                            setErrorMsg(message);
                        }
                    }
                } else {
                    setErrorMsg('Invalid code.');
                }
            } catch (error: any) {
                console.error(error.message);
                setErrorMsg('There was an error, please try again');
            }

            setLoading(false);
        }
    });

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (loggedUser.isVerified) {
            navigate(RoutesName.dashboard, { replace: true });
        }
    };

    const getInputElement = (index: number) => {
        return document.getElementById('digit' + index) as HTMLInputElement;
    };

    const moveToNext = (index: number) => {
        console.log(index);
        if (index <= 4) {
            getInputElement(index).focus();
        }
    };

    const onDigitChange = (e: React.ChangeEvent<HTMLInputElement>, nextIndex: number) => {
        formValidation.handleChange(e);
        if (e.target.value) moveToNext(nextIndex);
    };

    const handleResendPhoneCode = async () => {
        setLoading(true);
        try {
            const { success, data, message } = await resendPhoneCode(
                token ?? loggedUser.token,
                phone ?? loggedUser.phone!
            );
            if (success) {
                loggedUser.token = data;
                localStorage.setItem(globals.userSessionKey, encryptData(loggedUser));
                sucessToast('Phone code verification was sent successfully!');
            } else {
                errorToast(message);
            }
        } catch (error: any) {
            setErrorMsg(error.message);
        }
        setLoading(false);
    };

    return {
        formValidation,
        errorMsg,
        loading,
        loggedUser,
        logout,
        onDigitChange,
        handleResendPhoneCode,
        navigate
    };
};

export default usePhoneVerification;
