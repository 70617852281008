import { FormButton } from '@src/components/FormButton';
import ILead from '@src/interfaces/ILead';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface ILeadAddEditModal {
    show: boolean;
    formValidation: FormikProps<ILead>;
    loading: boolean;
    onHide(): void;
}

const LeadAddEditModal: React.FC<ILeadAddEditModal> = ({ show, onHide, formValidation, loading }) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    return (
        <Modal isOpen={show} centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.leadId ? 'Edit' : 'Add'} Lead</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='phone' className='form-label'>
                                Phone <span className='text-danger'>*</span>
                            </Label>
                            <Cleave
                                id='phone'
                                name='phone'
                                className={
                                    'form-control ' +
                                    (formValidation.touched.phone && !!formValidation.errors.phone
                                        ? 'is-invalid form-control'
                                        : '')
                                }
                                placeholder='(xxxx) xxx-xxx'
                                options={{
                                    numericOnly: true,
                                    delimiterLazyShow: true,
                                    delimiters: ['(', ') ', '-'],
                                    blocks: [0, 3, 3, 4]
                                }}
                                onBlur={formValidation.handleBlur}
                                onChange={formValidation.handleChange}
                                value={formValidation.values.phone || ''}
                            />
                            {formValidation.touched.phone && !!formValidation.errors.phone && (
                                <FormFeedback type='invalid' style={{ display: 'block' }}>
                                    {formValidation.errors.phone}
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Email <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                id='email'
                                name='email'
                                className='form-control'
                                placeholder='Enter email address'
                                type='email'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.email || ''}
                                invalid={formValidation.touched.email && !!formValidation.errors.email}
                            />
                            {formValidation.touched.email && !!formValidation.errors.email && (
                                <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default LeadAddEditModal;
