import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import IBot from '@src/interfaces/IBot';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { formatString } from '@src/utils/helpers';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, Col } from 'reactstrap';

interface IBotInfoCard {
    keyVal: string | number;
    bot: IBot;
}

const BotInfoCard: React.FC<IBotInfoCard> = ({ keyVal, bot }) => {
    return (
        <Col xxl={3} sm={3} key={keyVal + bot.botId!}>
            <Card className='profile-project-card'>
                <CardBody className='p-4 shadow' style={{ minHeight: 100 }}>
                    <div className='flex-grow-1 text-muted overflow-hidden'>
                        <h5 className='fs-14 text-truncate'>
                            <div className='d-flex align-items-center'>
                                <div className='avatar-sm h-auto'>
                                    <img
                                        src={bot.avatar || userDummayImage}
                                        alt='bot-avatar'
                                        className='img-thumbnail rounded-circle'
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = userDummayImage;
                                        }}
                                    />
                                </div>
                                <div className='w-100 ms-2 me-4'>
                                    <span className='text-body'>{bot.name}</span>
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div>
                        <h5 className='fs-12 text-muted text-muted mb-1' style={{ display: 'inline-flex' }}>
                            <b>Status: </b>
                            <div className='d-flex align-items-center ms-2'>
                                <Badge
                                    color={
                                        bot.temporaryDisabled
                                            ? 'warning'
                                            : bot.isSubscriptionActive
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {bot.temporaryDisabled
                                        ? ' Disabled'
                                        : bot.isSubscriptionActive
                                        ? 'active'
                                        : 'inactive'}
                                </Badge>
                            </div>
                        </h5>
                        <h5 className='fs-12 text-muted text-muted mb-1'>
                            <b>Created: </b>
                            <span className='fs-11'>{moment(bot.createdAt).format('MM/DD/YYYY')}</span>
                        </h5>
                        <h5 className='fs-12 text-muted text-muted mb-1'>
                            <b>Last updated: </b>
                            <span className='fs-11'>{moment(bot.updatedAt).fromNow()}</span>
                        </h5>
                    </div>
                    <div className='d-flex justify-content-end mt-2 mb-n2'>
                        <div>
                            <div className='d-flex justify-content-end'>
                                <Link
                                    to={RoutesName.adminBotMessages.replace(':botId', bot.botId!)}
                                    target='_blank'
                                    className='me-2'
                                >
                                    <Button color='primary' className='btn-icon' outline>
                                        <i className='ri-message-2-line' />
                                    </Button>
                                </Link>
                                <Link to={formatString(globals.fullScreenChatUrl, bot.botId)} target='_blank'>
                                    <Button color='primary' className='btn-icon' outline>
                                        <i className='ri-external-link-line' />{' '}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default BotInfoCard;
