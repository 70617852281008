import AccessDenied from '@src/pages/app/AccessDenied';
import EmailVerification from '@src/pages/app/EmailVerification';
import ForgotPassword from '@src/pages/app/ForgotPassword';
import Login from '@src/pages/app/Login';
import NotFound from '@src/pages/app/NotFound';
import Register from '@src/pages/app/Register';
import { RegistrationWizard } from '@src/pages/app/Registration/Wizard';
import RegistrationVerification from '@src/pages/app/RegistrationVerification';
import ResetPassword from '@src/pages/app/ResetPassword';
import TestScrapper from '@src/pages/app/TestScrapper';
import BotEmbed from '@src/pages/botEmbed';
import PublicAuthLayout from '@src/partials/PublicAuthLayout';
import PublicLayout from '@src/partials/PublicLayout';
import { RoutesName } from '@src/utils/enums';

export const publicRoutes = [
    { path: '*', component: <PublicLayout children={<NotFound />} /> },
    { path: RoutesName.accessDenied, component: <PublicLayout children={<AccessDenied />} /> },
    { path: RoutesName.root, component: <PublicAuthLayout children={<Login />} /> },
    { path: RoutesName.login, component: <PublicAuthLayout children={<Login />} /> },
    { path: RoutesName.forgotPassword, component: <PublicAuthLayout children={<ForgotPassword />} /> },
    { path: RoutesName.register, component: <PublicAuthLayout children={<Register />} /> },
    { path: RoutesName.register, component: <PublicAuthLayout children={<Register />} /> },
    { path: RoutesName.registrationWizard, component: <PublicAuthLayout children={<RegistrationWizard />} /> },
    { path: RoutesName.registerVerification, component: <PublicAuthLayout children={<RegistrationVerification />} /> },
    { path: RoutesName.emailVerification, component: <PublicAuthLayout children={<EmailVerification />} /> },
    { path: RoutesName.resetPassword, component: <PublicAuthLayout children={<ResetPassword />} /> },
    { path: RoutesName.botEmbed, component: <BotEmbed /> },
    { path: RoutesName.testScrapper, component: <TestScrapper /> }
];
