import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Formik formValidation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { RoutesName } from '@src/utils/enums';
import { login } from '@src/services/UserService';
import { globals } from '@src/utils/constants';
import { loginInitValues } from '@src/utils/initValues';
import { encryptData } from '@src/utils/helpers';

const useAdminLogin = () => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formValidation = useFormik({
        initialValues: loginInitValues,
        validationSchema: Yup.object({
            email: Yup.string().email('Please enter a valid email address').required('Please Enter Your Email'),
            password: Yup.string().required('Please Enter Your Password')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setErrorMsg('');
            console.log('submit', values);

            try {
                const { data, success, message } = await login(values.email, values.password, true);
                if (success) {
                    localStorage.setItem(globals.adminSessionKey, encryptData(data));
                    document.documentElement.setAttribute('data-user-type', 'admin');
                    navigate(RoutesName.adminDashboard, { replace: true });
                } else {
                    setErrorMsg(message);
                }
            } catch (error: any) {
                setErrorMsg(error.message);
            }
            setLoading(false);
        }
    });
    return { formValidation, errorMsg, loading, passwordShow, setPasswordShow };
};

export default useAdminLogin;
