import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { botsPromptInitValues } from '@src/utils/initValues';
import IBotPrompt from '@src/interfaces/IBotPrompt';
import { createBotPrompt, deleteBotPrompt, getBotPrompts, updateBotPrompt } from '@src/services/BotPromptService';

const useAdminBotsPrompts = () => {
    const loading = globalStore((botsPrompt) => botsPrompt.loading);
    const setLoading = globalStore((botsPrompt) => botsPrompt.setLoading);

    const [botsPrompts, setBotsPrompts] = useState<Array<IBotPrompt>>([]);
    const [selectedBotPrompt, setSelectedBotPrompt] = useState<IBotPrompt>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: botsPromptInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            instructions: Yup.string().required('Please enter the Instructions')
        }),
        onSubmit: async (values: IBotPrompt, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            let response;
            if (values.promptId) response = await updateBotPrompt(values);
            else response = await createBotPrompt(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast(' BotsPrompt information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        console.log('getting data');
        const { success, data, message } = await getBotPrompts();

        try {
            if (success) {
                setBotsPrompts(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (botsPrompt: IBotPrompt) => {
        setSelectedBotPrompt(botsPrompt);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedBotPrompt(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (botsPrompt: IBotPrompt) => {
        setSelectedBotPrompt(botsPrompt);
        formValidation.setValues({ ...botsPrompt });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (botsPrompt: IBotPrompt) => {
        setSelectedBotPrompt(botsPrompt);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteBotPrompt(selectedBotPrompt?.promptId!);

        if (success) {
            await getData();
            sucessToast(' BotsPrompt deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedBotPrompt(undefined);
    };

    return {
        botsPrompts,
        loading,
        selectedBotPrompt,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminBotsPrompts;
