import { Card, CardBody, Col, Input, Label, Row, Form, FormFeedback, Alert } from 'reactstrap';

import { Link } from 'react-router-dom';
import logoLight from '@src/assets/images/logo_connections.png';
import useResetPassword from './useResetPassword';
import { FormButton } from '@src/components/FormButton';

const ResetPassword = () => {
    const { formValidation, errorMsg, successMsg, loading } = useResetPassword();

    document.title = ' Reset Password | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-16 fw-semibold'>Create your own assistant for your business!</p>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                        <CardBody className='p-4'>
                            <div className='text-center mt-2'>
                                <h5 className='text-primary'>Create new password</h5>
                                <p className='text-muted'>
                                    Your new password must be different from previous used password.
                                </p>
                            </div>

                            {!!errorMsg && (
                                <Alert color='danger' className='text-center'>
                                    {errorMsg}
                                </Alert>
                            )}
                            {!!successMsg && (
                                <Alert color='success' className='text-center'>
                                    {successMsg}
                                </Alert>
                            )}
                            <div className='p-2 mt-4'>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        console.log(formValidation.errors);
                                        formValidation.handleSubmit();
                                        return false;
                                    }}
                                    action='#'
                                >
                                    <div className='mb-3'>
                                        <Label className='form-label' htmlFor='password-input'>
                                            Password <span className='text-danger'>*</span>
                                        </Label>
                                        <div className='position-relative auth-pass-inputgroup mb-3'>
                                            <Input
                                                name='password'
                                                value={formValidation.values.password || ''}
                                                type={'password'}
                                                className='form-control pe-5'
                                                placeholder='Enter Password'
                                                onChange={formValidation.handleChange}
                                                onBlur={formValidation.handleBlur}
                                                invalid={
                                                    formValidation.touched.password && !!formValidation.errors.password
                                                }
                                            />
                                            {formValidation.touched.password && !!formValidation.errors.password && (
                                                <FormFeedback type='invalid'>
                                                    {formValidation.errors.password}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </div>

                                    <div className='mb-2'>
                                        <Label htmlFor='confirmPassword' className='form-label'>
                                            Confirm Password <span className='text-danger'>*</span>
                                        </Label>
                                        <Input
                                            name='confirmPassword'
                                            type='password'
                                            placeholder='Confirm Password'
                                            onChange={formValidation.handleChange}
                                            onBlur={formValidation.handleBlur}
                                            value={formValidation.values.confirmPassword || ''}
                                            invalid={
                                                formValidation.touched.confirmPassword &&
                                                !!formValidation.errors.confirmPassword
                                            }
                                        />
                                        {formValidation.touched.confirmPassword &&
                                            !!formValidation.errors.confirmPassword && (
                                                <FormFeedback type='invalid'>
                                                    <div>{formValidation.errors.confirmPassword}</div>
                                                </FormFeedback>
                                            )}
                                    </div>

                                    <div className='mt-4'>
                                        <FormButton value='Reset Password' type='submit' loading={loading} />
                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ResetPassword;
