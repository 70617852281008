import { FormButton } from '@src/components/FormButton';
import globalStore from '@src/stores/globalStore';
import registrationStore from '@src/stores/registrationStore';
import { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { Badge, Card, CardBody, CardFooter, CardHeader, Row } from 'reactstrap';

interface WizardControlsProps {
    children: React.ReactNode;
    disableNext?: boolean;
    processNext?: () => Promise<boolean> | void;
}

const WizardControls: React.FC<WizardControlsProps> = ({ children, processNext, disableNext }) => {
    const { nextStep, activeStep, stepCount, goToStep } = useWizard();
    const registrationUser = registrationStore((state) => state.registrationUser);
    const loading = globalStore((state) => state.loading);
    const steps = Array.from({ length: stepCount }, (_, i) => i);

    useEffect(() => {
        if (registrationUser) {
            if (registrationUser.name && registrationUser.companyName) goToStep(1);
        }
    }, []);

    const _handleNext = async () => {
        if (disableNext) return;

        if (processNext) {
            const success = await processNext();
            if (!success) return;
        }

        nextStep();
    };

    return (
        <Card>
            <CardHeader>
                <Row className='d-flex justify-content-center'>
                    {steps.map((step) => (
                        <Badge
                            key={'step-indicator-' + step}
                            color={activeStep === step ? 'primary' : 'dark-subtle'}
                            className={step === 0 ? '' : 'ms-4'}
                            style={{
                                width: '10px',
                                height: '10px',
                                padding: 0,
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}
                        />
                    ))}
                </Row>
            </CardHeader>
            <CardBody>{children}</CardBody>
            <CardFooter>
                <Row className='d-flex justify-content-end'>
                    <FormButton
                        style={{ width: '100px' }}
                        fitParent={false}
                        onClick={_handleNext}
                        loading={loading}
                        disabled={disableNext}
                    >
                        Continue
                    </FormButton>
                </Row>
            </CardFooter>
        </Card>
    );
};

export default WizardControls;
