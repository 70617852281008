import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { CustomTable } from '@src/components/CustomTable';
import { FormButton } from '@src/components/FormButton';
import OkCancelModal from '@src/components/OkCancelModal';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { RoutesName } from '@src/utils/enums';
import { copyText } from '@src/utils/helpers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import 'cleave.js/dist/addons/cleave-phone.in';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BotCategoryAddEditModal from './partials/BotSubCategoryAddEditModal';
import BotCategoryInfoModal from './partials/BotSubCategoryInfoModal';
import useAdminBotsSubCategories from './useAdminBotsSubCategories';

const AdminBotsSubCategories = () => {
    document.title = 'AI Assistant Sub Categories | Connections';

    const {
        categories,
        subCategories,
        selectedSubCategory,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        loading,
        formValidation,
        filteredSubCategoryId,
        setShowInfoModal,
        onAddClick,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    } = useAdminBotsSubCategories();

    const columnHelper = createColumnHelper<IBotSubCategory>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        columnHelper.display({
            header: 'Id',
            cell: ({ row }) => (
                <Link to='#' onClick={() => copyText(row.original.botSubCategoryId)} className='me-1'>
                    {row.original.botSubCategoryId}
                </Link>
            )
        }),
        {
            header: 'Name',
            accessorKey: 'name'
        },
        {
            header: 'Description',
            accessorKey: 'description'
        },
        columnHelper.display({
            header: 'Category',
            cell: ({ row }) => (
                <Link to={`${RoutesName.adminBotCategories}/${row.original.botCategory?.botCategoryId}`}>
                    {row.original.botCategory?.name}
                </Link>
            )
        }),
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY hh:mm A')
        },
        columnHelper.display({
            header: 'Actions',
            size: 100,
            cell: ({ row }) => (
                <ul className='list-inline hstack gap-2 mb-0'>
                    <li className='list-inline-item'>
                        <Link to='#' onClick={() => onInfoClick(row.original)} className='text-primary d-inline-block'>
                            <i className='ri-eye-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item edit'>
                        <Link
                            to='#'
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onEditClick(row.original)}
                        >
                            <i className='ri-pencil-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            className='text-danger d-inline-block remove-item-btn'
                            onClick={() => onDeleteClick(row.original)}
                        >
                            <i className='ri-delete-bin-5-fill fs-16'></i>
                        </Link>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>AI Assistant Sub Categories</h2>
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <div className='flex-shrink-0'>
                                        <FormButton onClick={onAddClick}>
                                            <i className='ri-add-line align-bottom'></i> Add New
                                        </FormButton>
                                    </div>
                                </div>
                                {!!filteredSubCategoryId && subCategories.length && (
                                    <div className='mt-2'>
                                        <Link to={RoutesName.adminBotSubCategories}>
                                            <span className='badge rounded-pill border border-primary text-primary d-inline-flex align-items-center'>
                                                {subCategories[0].name}{' '}
                                                <i className='ri-close-circle-line fs-15 ms-1'></i>
                                            </span>
                                        </Link>
                                    </div>
                                )}
                                <CustomTable data={subCategories} columns={columns} className='mt-3' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <BotCategoryInfoModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                selectedItem={selectedSubCategory}
            />

            <BotCategoryAddEditModal
                show={showAddEditModal}
                onHide={() => setShowAddEditModal(false)}
                loading={loading}
                formValidation={formValidation}
                categories={categories}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br /><b>${selectedSubCategory?.name}</b>?`}
                subTitle="If you continue, you won't be able to recover the information of this record."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </React.Fragment>
    );
};

export default AdminBotsSubCategories;
