import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { publicRoutes } from './PublicRoutes';
import { privateRoutes } from './PrivateRoutes';
import { adminRoutes } from './AdminRoutes';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    {publicRoutes.map((route, idx) => (
                        <Route path={route.path} element={route.component} key={idx} />
                    ))}
                </Route>
                <Route>
                    {privateRoutes.map((route, idx) => (
                        <Route path={route.path} element={route.component} key={idx} />
                    ))}
                </Route>
                <Route>
                    {adminRoutes.map((route, idx) => (
                        <Route path={route.path} element={route.component} key={idx} />
                    ))}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
