import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import placeHolderImage from '@src/assets/images/placeholder-image.jpg';
import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import IBot from '@src/interfaces/IBot';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { IFacebookPage, IFacebookResponse } from '@src/interfaces/IFacebook';
import { IDDLMulti, IObj } from '@src/interfaces/IGeneric';
import { IContactItemForApi, IGoogleContact } from '@src/interfaces/IGoogleContact';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import { IMunicipalityRequest } from '@src/interfaces/IMunicipality';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import IStripeUser from '@src/interfaces/IStripeUser';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import {
    addFacebookPages,
    createBot,
    deleteBot,
    getBotsByUser,
    removeFacebookPages,
    updateBot
} from '@src/services/BotService';
import { getBotSubCategoriesByCategory } from '@src/services/BotSubCategoryService';
import { getContacts } from '@src/services/GoogleService';
import { createMunicipalityRequest } from '@src/services/MunicipalityRequestService';
import { createSubscription, getPaymentMethods, getStripeUser } from '@src/services/PaymentService';
import { getStatesDDL } from '@src/services/StateService';
import { getUser, sendInvite } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import {
    decrypData,
    encryptData,
    errorToast,
    formatString,
    generateColor,
    getLoggedUser,
    infoToast,
    isEmailVerified,
    sucessToast,
    trimAll
} from '@src/utils/helpers';
import { botInitValues, requestMuniInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import { gapi } from 'gapi-script';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const useDashboard = () => {
    const loggedUser = getLoggedUser();
    const hasMunicipalityAccess = !!loggedUser.roles?.find((r) => r.roleId === globals.municipalityAccessRoleId);
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const retries = useRef(0);

    const [bots, setBots] = useState<Array<IBot>>([]);
    const [filteredBots, setFilteredBots] = useState<Array<IBot>>();
    const [subCategories, setSubCategories] = useState<Array<IBotSubCategory>>([]);
    const [municipalitiesOptions, setMunicipalitiesOptions] = useState<Array<IDDLMulti>>([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [showBotModal, setShowBotModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMunicipalityModal, setShowMunicipalityModal] = useState(false);
    const [showMunicipalitiesDDL, setshowMunicipalitiesDDL] = useState(false);
    const [selectedBot, setSelectedBot] = useState<IBot>();
    const [forceRefresh, setForceRefresh] = useState(false);
    const [borderColors, setBorderColors] = useState<IObj>({});
    const [googleContacts, setGoogleContacts] = useState<Array<IGoogleContact>>([]);
    const [filteredGoogleContacts, setFilteredGoogleContacts] = useState<Array<IGoogleContact>>([]);
    const [filterText, setFilterText] = useState('');
    const [totalContactsSelected, setTotalContactsSelected] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState<ISubscriptionProduct>();
    const [stripeUser, setStripeUser] = useState<IStripeUser>();
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<IStripePaymentMethod>();
    const [isEmailAlreadyVerified, setIsEmailAlreadyVerified] = useState(false);

    const navigate = useNavigate();

    const formValidation = useFormik({
        initialValues: botInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the AI Assistant name'),
            description: Yup.string().required('Please enter the AI Assistant description'),
            welcomeMessage: Yup.string().required('Please enter the AI Assistant welcome message')
        }),
        onSubmit: async (values: IBot, { resetForm }) => {
            console.log('submit', values);
            setLoading(true);
            const botData = {
                ...values,
                userId: loggedUser.userId
            } as IBot;
            botData.description = formatString(botData.description, botData.name);
            botData.welcomeMessage = formatString(botData.welcomeMessage, botData.name);
            botData.documentsInputs = botData.documentsInputs?.filter((d) => d);
            console.log('botData', botData);

            let botResponse;

            if (values.botId) botResponse = await updateBot(botData);
            else botResponse = await createBot(botData);

            if (botResponse.success) {
                if (!values.botId || !values.isSubscriptionActive) {
                    const { success, data, message } = await createSubscription(
                        loggedUser,
                        selectedProduct!,
                        botResponse.data
                    );
                    console.log('subscription data: ', data);
                    //If new bot, but subscription creation went wrong, delete bot
                    if (!values.botId && !success) {
                        await deleteBot(botResponse.data.botId!);
                        errorToast(message);
                        setShowBotModal(false);
                        setLoading(false);
                        return;
                    }
                }

                setShowBotModal(false);
                await getData();
                resetForm();
                setSelectedBot(undefined);
                sucessToast('AI Assistant saved successfully!');
            } else {
                errorToast(botResponse.message, 2000);
            }
            setShowBotModal(false);
            setLoading(false);
        }
    });

    const requestMuniValidation = useFormik({
        initialValues: { ...requestMuniInitValues, userId: loggedUser.userId! },
        validationSchema: Yup.object({
            name: Yup.string().required('Please add a city'),
            state: Yup.string().required('Please add a state')
        }),
        onSubmit: async (values: IMunicipalityRequest, { resetForm }) => {
            console.log('submit', values);
            if (loading) return;

            setLoading(true);

            const { success, message } = await createMunicipalityRequest(values);
            if (success) {
                sucessToast("Your request was sent. We are going to review it and then we'll notify you.", 5000);
                resetForm();
            } else {
                errorToast(message);
            }
            setShowMunicipalityModal(false);
            setLoading(false);
        }
    });

    useEffect(() => {
        getData();
        function startGoogleAuth() {
            gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
                scope: globals.googleContactScope
            });
        }
        gapi.load('client:auth2', startGoogleAuth);
    }, []);

    useEffect(() => {
        if (!showBotModal) {
            setshowMunicipalitiesDDL(false);
            setSelectedBot(undefined);
            formValidation.resetForm();
        }
    }, [showBotModal]);

    useEffect(() => {
        if (!showMunicipalityModal) requestMuniValidation.resetForm();
    }, [showMunicipalityModal]);

    useEffect(() => {
        const start = (currentPage - 1) * globals.perPageBots;
        const end = currentPage * globals.perPageBots;
        const filtered = bots?.slice(start, end);
        setFilteredBots(filtered);
    }, [currentPage, bots]);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    useEffect(() => {
        if (filterText) {
            const filtered = googleContacts.filter((c) =>
                c.names?.find((n) => n.displayName.toLowerCase().includes(filterText.toLowerCase()))
            );
            setFilteredGoogleContacts(filtered);
        } else setFilteredGoogleContacts(googleContacts);
    }, [filterText]);

    const getData = async () => {
        setLoading(true);

        if (!isEmailAlreadyVerified) setIsEmailAlreadyVerified(await isEmailVerified());

        const [
            botResponse,
            munResponse,
            subCategoriesResponse,
            stripeUserResponse,
            paymentMethodsResponse,
            userResponse
        ] = await Promise.all([
            getBotsByUser(loggedUser.userId),
            getStatesDDL(),
            getBotSubCategoriesByCategory(loggedUser.category?.botCategoryId!),
            getStripeUser(loggedUser.stripeCustomerId!),
            getPaymentMethods(loggedUser.stripeCustomerId!),
            getUser(loggedUser.userId!)
        ]);

        try {
            if (botResponse.success) {
                setBots(botResponse.data);
                const borderColors: IObj = {};
                botResponse.data.forEach((b) => {
                    borderColors[b.botId!] = generateColor();
                });
                setBorderColors(borderColors);
                if (botResponse.data.length && currentPage == 0) setCurrentPage(1);
            } else {
                errorToast(botResponse.message);
            }
            if (munResponse.success) {
                setMunicipalitiesOptions(munResponse.data);
            } else errorToast(munResponse.message);

            if (subCategoriesResponse.success) setSubCategories(subCategoriesResponse.data);
            else errorToast(subCategoriesResponse.message);

            if (stripeUserResponse.success) setStripeUser(stripeUserResponse.data);
            else errorToast(stripeUserResponse.message);

            if (paymentMethodsResponse.success && paymentMethodsResponse.data.length) {
                const tempPaymentMethods = paymentMethodsResponse.data.reverse();
                const defaultPM = tempPaymentMethods.find(
                    (pm) => pm.id == stripeUserResponse.data.invoiceSettings.defaultPaymentMethod
                );
                setDefaultPaymentMethod(defaultPM);
            }

            if (userResponse.success) {
                localStorage.setItem(globals.userSessionKey, encryptData({ ...loggedUser, ...userResponse.data }));
            }
        } catch (error) {}

        setLoading(false);
    };

    const onOpenBotModal = () => {
        if (subCategories.length) formValidation.setFieldValue('botSubCategoryId', subCategories?.[0].botSubCategoryId);
        setShowBotModal(true);
    };

    const onChangeMunicipality = (e: any) => {
        formValidation.setFieldValue('meta', { municipality: e.value });
    };

    const getMunicipalityDefault = () => {
        let defaultOption;
        _.forEach(municipalitiesOptions, (m) => {
            const opt = m.options.find((o) => o.value === selectedBot?.meta?.municipality);
            if (opt) {
                defaultOption = opt;
                return false;
            }
        });
        return defaultOption;
    };

    const onCloseBotModal = () => {
        setShowBotModal(false);
    };

    const onEditBot = (bot: IBot) => {
        formValidation.setValues({ ...bot });

        setSelectedBot(bot);
        setShowBotModal(true);
    };

    const onDeleteBot = async (bot: IBot) => {
        setShowDeleteModal(true);
        setSelectedBot(bot);
    };

    const onFacebookLogin = async (response: IFacebookResponse, bot: IBot) => {
        console.log(response);
        try {
            if (response.error) {
                errorToast('Action was cancelled');
                return;
            }

            if (response.accounts.data.length) {
                const pages: IFacebookPage[] = response.accounts.data.map((page) => {
                    return {
                        id: page.id,
                        name: page.name,
                        userId: response.id,
                        accessToken: page.access_token,
                        userAccessToken: response.accessToken,
                        category: page.category
                    };
                });
                const { success, message } = await addFacebookPages(bot?.botId!, pages);
                if (success) {
                    bot.facebookPages = [...pages];
                    setForceRefresh(true);
                } else {
                    errorToast(message);
                }
            }
        } catch (error) {
            errorToast('An error occurred while trying to login with Facebook');
        }
    };

    const onFacebookLogout = async (bot: IBot) => {
        setLoading(true);
        await removeFacebookPages(bot.botId!);
        bot.facebookPages = [];
        setForceRefresh(true);
        FacebookLoginClient.logout(() => {});
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteBot(selectedBot?.botId!);

        if (success) {
            await getData();
            sucessToast('AI Assistant deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedBot(undefined);
    };

    const avatarOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formValidation.setFieldValue('avatarInput', e.target.files?.[0]);
    };

    const startImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formValidation.setFieldValue('startImageInput', e.target.files?.[0]);
    };

    const getAvatarPreview = () => {
        try {
            if (formValidation.values.avatarInput?.name) {
                return URL.createObjectURL(formValidation.values.avatarInput);
            } else if (!formValidation.values.avatarInput?.name) {
                return userDummayImage;
            } else {
                const files = (document.getElementById('avatar') as HTMLInputElement).files;
                if (files) return URL.createObjectURL(files[0]);
            }
        } catch (error) {
            return userDummayImage;
        }
    };

    const getStartImagePreview = () => {
        try {
            if (formValidation.values.startImageInput?.name) {
                return URL.createObjectURL(formValidation.values.startImageInput);
            } else if (!formValidation.values.startImageInput?.name) {
                return placeHolderImage;
            } else {
                const files = (document.getElementById('startImage') as HTMLInputElement).files;
                if (files) return URL.createObjectURL(files[0]);
            }
        } catch (error) {
            return placeHolderImage;
        }
    };

    const onShowMunRequest = () => {
        setShowBotModal(false);
        setShowMunicipalityModal(true);
    };

    const splitAddress = (place: IGooglePlace) => {
        if (!place) return;
        let city = '';
        let state = '';
        let zipcode = '00000';
        let isoCode = '';

        place.address_components.forEach((component) => {
            if (component.types.includes('locality')) city = component.long_name;
            if (component.types.includes('administrative_area_level_1')) state = component.long_name;
            if (component.types.includes('administrative_area_level_1')) isoCode = component.short_name;
            if (component.types.includes('postal_code')) zipcode = component.long_name;
        });

        return { fullAddress: place.formatted_address, city, state, zipcode, isoCode };
    };

    const validateAddressField = () => {
        return (
            requestMuniValidation.touched.name &&
            (!!requestMuniValidation.errors.name ||
                !!requestMuniValidation.errors.state ||
                !!requestMuniValidation.errors.zipcode)
        );
    };

    const onAddressSelected = (place: IGooglePlace) => {
        const addressFields = splitAddress(place);
        requestMuniValidation.setFieldValue('name', addressFields?.city);
        requestMuniValidation.setFieldValue('state', addressFields?.state);
        requestMuniValidation.setFieldValue('isoCode', addressFields?.isoCode);
        requestMuniValidation.setFieldValue('zipcode', addressFields?.zipcode);

        setForceRefresh(true);
    };

    const addDocumentElement = () => {
        const inputsArray = formValidation.values.documentsInputs
            ? [...formValidation.values.documentsInputs, '']
            : [''];
        formValidation.setFieldValue('documentsInputs', inputsArray);
    };

    const documentOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        if (!formValidation.values.documentsInputs) return;

        const temp = [...formValidation.values.documentsInputs];
        temp[idx] = e.target.files?.[0];
        formValidation.setFieldValue('documentsInputs', [...temp]);
    };

    const documentOnRemove = (idx: number) => {
        const element = document.getElementById(`document-div-${idx}`);
        if (element && formValidation.values.documentsInputs) {
            //Hide element to simulate the deletion
            element.setAttribute('style', 'display:none !important');
            formValidation.values.documentsInputs[idx] = null;
        }
    };

    const googleLogin = async () => {
        setLoading(true);
        let accessToken = decrypData(localStorage.getItem(globals.googleAccessTokenKey));

        if (!accessToken) {
            try {
                const auth2 = gapi.auth2.getAuthInstance();
                const res = await auth2.signIn();
                accessToken = res.xc.access_token;

                localStorage.setItem(globals.googleAccessTokenKey, encryptData(accessToken));
            } catch (error) {
                errorToast('There was an error, please try again.');
            }
        }
        if (accessToken) {
            const { success, data } = await getContacts(accessToken);
            if (success) {
                if (data.length) {
                    setGoogleContacts(data);
                    setFilteredGoogleContacts(data);
                } else errorToast('No contacts with email or phone were found');
            } else {
                localStorage.removeItem(globals.googleAccessTokenKey);
                if (retries.current < 1) {
                    retries.current++;
                    googleLogin();
                    return;
                } else errorToast('There was an error to access your google contacts, please try again.');
            }
        } else errorToast('There was an error to access your google contacts, please try again.');
        retries.current = 0;
        setLoading(false);
    };

    const handleContactCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentContact = event.target;
        const contactCheckItems = currentContact?.parentElement?.querySelectorAll('.contacts-check-item');
        contactCheckItems?.forEach((ci) => {
            (ci as HTMLInputElement).checked = currentContact.checked;
        });

        setTotalContactsSelected(getSelectedContacts().length);
    };

    const handleContactItemCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentItem = event.target;
        const parentInput = document.querySelector(
            `#${currentItem.getAttribute('data-parent-id')}`
        ) as HTMLInputElement;
        const contactCheckItems = parentInput?.parentElement?.querySelectorAll('.contacts-check-item');
        parentInput.checked = false;
        contactCheckItems?.forEach((ci) => {
            if ((ci as HTMLInputElement).checked) parentInput.checked = true;
        });
        setTotalContactsSelected(getSelectedContacts().length);
    };

    const getSelectedContacts = () => {
        const dataToSend = [];
        try {
            const checkBoxContacts = document.getElementsByClassName('contacts-check-item');

            for (const checkBoxContact of checkBoxContacts) {
                const input = checkBoxContact as HTMLInputElement;
                if (input.checked) {
                    const contactData: IContactItemForApi = {
                        name: input.getAttribute('data-contact-name')?.trim() || '',
                        email: input.getAttribute('data-contact-email')?.replaceAll(' ', '') || '',
                        phone: trimAll(input.getAttribute('data-contact-phone') || '')
                    };
                    dataToSend.push(contactData);
                }
            }
        } catch (error: any) {
            errorToast(error.message);
        }

        return dataToSend;
    };

    const sendInviteToContacts = async () => {
        const dataToSend = getSelectedContacts();

        if (!dataToSend.length) {
            infoToast('Please select at least one contact.');
            return;
        }

        setLoading(true);
        const { success, message } = await sendInvite({ userId: loggedUser.userId!, contacts: dataToSend });

        if (success) {
            sucessToast('Invite sent to selected contacts');
        } else errorToast(message, 2000);

        setGoogleContacts([]);
        setLoading(false);
    };

    return {
        loggedUser,
        filteredBots,
        bots,
        currentPage,
        showBotModal,
        selectedBot,
        formValidation,
        requestMuniValidation,
        subCategories,
        showMunicipalitiesDDL,
        municipalitiesOptions,
        loading,
        showDeleteModal,
        showMunicipalityModal,
        borderColors,
        googleContacts,
        filteredGoogleContacts,
        filterText,
        totalContactsSelected,
        hasMunicipalityAccess,
        selectedProduct,
        stripeUser,
        defaultPaymentMethod,
        isEmailAlreadyVerified,
        setSelectedProduct,
        onOpenBotModal,
        onCloseBotModal,
        onEditBot,
        onFacebookLogin,
        onFacebookLogout,
        navigate,
        setCurrentPage,
        getAvatarPreview,
        getStartImagePreview,
        avatarOnChange,
        startImageOnChange,
        onChangeMunicipality,
        setShowDeleteModal,
        onDeleteBot,
        handleDelete,
        getMunicipalityDefault,
        validateAddressField,
        onAddressSelected,
        setShowMunicipalityModal,
        onShowMunRequest,
        googleLogin,
        setGoogleContacts,
        sendInviteToContacts,
        setFilterText,
        addDocumentElement,
        documentOnChange,
        documentOnRemove,
        handleContactCheckChange,
        handleContactItemCheckChange
    };
};

export default useDashboard;
