import { IGHLContact } from '@src/interfaces/IGHLContact';
import HttpService from '@src/utils/HttpService';
import { objToCamel } from '@src/utils/helpers';

export const createGHLContact = async (ghlContact: IGHLContact) => {
    const response = { data: {} as IGHLContact, success: false, message: '' };
    try {
        const { data } = await HttpService.post(process.env.REACT_APP_GOHIGHLEVEL_API_URL + '/contacts', ghlContact, {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_GOHIGHLEVEL_API_KEY
            }
        });
        console.log('Contact created successfully:', data);

        const formatApiResponse = objToCamel<IGHLContact>(data.contact);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        console.log('Failed to create contact:', error);
        response.message = error.message || 'Failed to create contact';
    }

    return response;
};

export const getGHLContacts = async () => {
    const response = { data: [] as Array<IGHLContact>, success: false, message: '' };
    try {
        const { data } = await HttpService.get(process.env.REACT_APP_GOHIGHLEVEL_API_URL + '/contacts', {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_GOHIGHLEVEL_API_KEY
            }
        });
        console.log('Get GHL Contacts successfully:', data);

        // const formatApiResponse = objToCamel(data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        console.log('Failed to create contact:', error);
        response.message = error.message || 'Failed to create contact';
    }

    return response;
};

export const getGHLContactByEmail = async (email: string) => {
    const response = { data: {} as IGHLContact, success: false, message: '' };
    try {
        const { data } = await HttpService.get(process.env.REACT_APP_GOHIGHLEVEL_API_URL + `/contacts?email=${email}`, {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_GOHIGHLEVEL_API_KEY
            }
        });
        console.log('Get GHL Contact by email successfully:', data);

        const formatApiResponse = objToCamel<IGHLContact>(data.contact);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        console.log('Failed to get GHL Contact by email:', error);
        response.message = error.message || 'Failed to get GHL Contact by email';
    }

    return response;
};

export const updateGHLContactTags = async (contactId: string, tags: string[]) => {
    const response = { data: {} as IGHLContact, success: false, message: '' };
    try {
        const { data } = await HttpService.put(
            process.env.REACT_APP_GOHIGHLEVEL_API_URL + `/contacts/${contactId}`,
            { tags },
            {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_GOHIGHLEVEL_API_KEY
                }
            }
        );
        console.log('Tags updated successfully for contact:', contactId);
        const formatApiResponse = objToCamel<IGHLContact>(data.contact);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        console.log('Failed to update tags for contact:', contactId, error);
        response.message = error.message || 'Failed to update tags for contact';
    }

    return response;
};
