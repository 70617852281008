import React from 'react';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { CustomTable } from '@src/components/CustomTable';
import OkCancelModal from '@src/components/OkCancelModal';
import IUser from '@src/interfaces/IUser';
import { RoutesName } from '@src/utils/enums';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import 'cleave.js/dist/addons/cleave-phone.in';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ClientsEditModal from './partials/ClientsEditModal';
import ClientsInfoModal from './partials/ClientsInfoModal';
import useAdminClientsList from './useAdminClientsList';

const AdminClientList = () => {
    document.title = 'Clients | Connections';

    const {
        clients,
        selectedClient,
        showInfoModal,
        showEditModal,
        showDeleteModal,
        loading,
        formValidation,
        roles,
        categories,
        setShowInfoModal,
        setShowEditModal,
        setShowDeleteModal,
        onResendVerificationClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    } = useAdminClientsList();

    const columnHelper = createColumnHelper<IUser>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        columnHelper.display({
            header: 'Name',
            cell: ({ row }) => (
                <Link
                    to={RoutesName.adminClientsInfo.replace(':userId', row.original.userId!)}
                    target='_blank'
                    className='text-primary d-inline-block'
                >
                    {row.original.name}
                </Link>
            )
        }),
        {
            header: 'Phone',
            accessorKey: 'phone'
        },
        {
            header: 'Email',
            accessorKey: 'email'
        },
        {
            header: 'Address',
            accessorKey: 'fullAddress'
        },
        {
            header: 'Category',
            accessorKey: 'category.name'
        },
        {
            header: 'Sub Category',
            accessorKey: 'subCategory.name'
        },
        {
            header: 'Is Phone Verified',
            accessorKey: 'isVerified',
            cell: (value: any) => {
                return value.getValue() ? (
                    <div className='d-flex align-items-center'>
                        <i className='ri-checkbox-circle-line fs-24 text-success me-2' />
                        Yes
                    </div>
                ) : (
                    <div className='d-flex align-items-center'>
                        <i className='ri-error-warning-line fs-24 text-warning  me-2' />
                        No
                    </div>
                );
            }
        },
        {
            header: 'Created At',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY hh:mm A')
        },
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY hh:mm A')
        },
        columnHelper.display({
            header: 'Actions',
            size: 100,
            cell: ({ row }) => (
                <ul className='list-inline hstack gap-2 mb-0'>
                    <li className='list-inline-item'>
                        <Link
                            to={RoutesName.adminClientsInfo.replace(':userId', row.original.userId!)}
                            id={`view-${row.original.userId}`}
                            target='_blank'
                            className='text-primary d-inline-block'
                        >
                            <i className='ri-eye-fill fs-16'></i>
                        </Link>
                        <UncontrolledTooltip placement='top' target={`view-${row.original.userId}`}>
                            View Info
                        </UncontrolledTooltip>
                    </li>

                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            id={`resend-${row.original.userId}`}
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onResendVerificationClick(row.original)}
                        >
                            <i className='ri-mail-send-line fs-16'></i>
                        </Link>
                        <UncontrolledTooltip placement='top' target={`resend-${row.original.userId}`}>
                            Resend verification email
                        </UncontrolledTooltip>
                    </li>

                    <li className='list-inline-item edit'>
                        <Link
                            to='#'
                            id={`edit-${row.original.userId}`}
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onEditClick(row.original)}
                        >
                            <i className='ri-pencil-fill fs-16'></i>
                        </Link>
                        <UncontrolledTooltip placement='top' target={`edit-${row.original.userId}`}>
                            Edit User
                        </UncontrolledTooltip>
                    </li>
                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            id={`delete-${row.original.userId}`}
                            className='text-danger d-inline-block remove-item-btn'
                            onClick={() => onDeleteClick(row.original)}
                        >
                            <i className='ri-delete-bin-5-fill fs-16'></i>
                        </Link>
                        <UncontrolledTooltip placement='top' target={`delete-${row.original.userId}`}>
                            Delete User
                        </UncontrolledTooltip>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>Clients List</h2>
                                </div>
                                <CustomTable data={clients} columns={columns} className='mt-6' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <ClientsInfoModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                selectedItem={selectedClient}
            />

            <ClientsEditModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                loading={loading}
                formValidation={formValidation}
                roles={roles}
                categories={categories}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br /><b>${selectedClient?.name}</b>?`}
                subTitle="If you continue, you won't be able to recover the information of this record."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </React.Fragment>
    );
};

export default AdminClientList;
