import { Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';

import logoLight from '@src/assets/images/logo_connections.png';
import { FormButton } from '@src/components/FormButton';
import { RoutesName } from '@src/utils/enums';
import htmlParse from 'html-react-parser';
import { Link } from 'react-router-dom';
import useForgotPassword from './useForgotPassword';

const ForgotPassword = () => {
    const { formValidation, errorMsg, emailSent, loading } = useForgotPassword();
    document.title = ' Forgot Password | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-15 fw-medium'>Create your own assistant for your business!</p>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                        <CardBody className='p-4'>
                            <div className='text-center mt-2'>
                                <h5 className='text-primary'>Forgot Password?</h5>
                                <p className='text-muted'>Reset password by entering your email</p>
                                <i className='ri-mail-send-line m-2 display-4 color-primary' />
                            </div>

                            {!!errorMsg ? (
                                <Alert color='danger' className='text-center mb-2 mx-2'>
                                    {htmlParse(errorMsg)}
                                </Alert>
                            ) : (
                                !emailSent && (
                                    <Alert color='warning' className='text-center mb-2 mx-2'>
                                        Enter your email and instructions will be sent to you!
                                    </Alert>
                                )
                            )}

                            {emailSent && (
                                <Alert color='success' className='text-center mb-2 mx-2'>
                                    Please check your email inbox for further instructions
                                </Alert>
                            )}
                            <div className='p-2'>
                                <Form onSubmit={formValidation.handleSubmit}>
                                    <div className='mb-4'>
                                        <Label className='form-label'>Email</Label>
                                        <Input
                                            type='email'
                                            className='form-control'
                                            id='email'
                                            placeholder='Enter Email'
                                            name='email'
                                            value={formValidation.values.email}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            invalid={formValidation.touched.email && !!formValidation.errors.email}
                                        />
                                        {formValidation.touched.email && !!formValidation.errors.email && (
                                            <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                                        )}
                                    </div>

                                    <div className='text-center mt-4'>
                                        <FormButton value='Send reset link' type='submit' loading={loading} />
                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                    <div className='mt-4 text-center'>
                        <p className='mb-0'>
                            Go back to{' '}
                            <Link to={RoutesName.root} className='fw-semibold text-primary text-decoration-underline'>
                                Login
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ForgotPassword;
