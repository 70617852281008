import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { getBotCategories } from '@src/services/BotCategoryService';
import {
    createBotSubCategory,
    deleteBotSubCategory,
    getBotSubCategories,
    getBotSubCategoryByCategory,
    updateBotSubCategory
} from '@src/services/BotSubCategoryService';
import globalStore from '@src/stores/globalStore';
import { errorToast, sucessToast } from '@src/utils/helpers';
import { botSubCategoryInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const useAdminBotsSubCategories = () => {
    const { categoryId: filteredCategoryId, subCategoryId: filteredSubCategoryId } = useParams();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [categories, setcategories] = useState<Array<IBotCategory>>([]);
    const [subCategories, setSubCategories] = useState<Array<IBotSubCategory>>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<IBotSubCategory>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: botSubCategoryInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            description: Yup.string().required('Please enter the description'),
            botCategory: Yup.object<IBotCategory>().required('Please select a category')
        }),
        onSubmit: async (values: IBotSubCategory, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            if (values.botCategory?.botCategoryId !== selectedSubCategory?.botCategory?.botCategoryId) {
                values.previousBotCategory = selectedSubCategory?.botCategory;
            }

            let response;
            if (values.botSubCategoryId) response = await updateBotSubCategory(values);
            else response = await createBotSubCategory(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast('AI Assistant Sub Category information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, [filteredSubCategoryId]);

    const getData = async () => {
        setLoading(true);
        let result;

        if (filteredCategoryId && filteredSubCategoryId)
            result = await getBotSubCategoryByCategory(filteredCategoryId, filteredSubCategoryId);
        else result = await getBotSubCategories();

        try {
            if (result.success) {
                setSubCategories(Array.isArray(result.data) ? result.data : [result.data]);
                const { success, data, message } = await getBotCategories();
                if (success) {
                    setcategories(data);
                } else errorToast(message);
            } else errorToast(result.message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (category: IBotSubCategory) => {
        setSelectedSubCategory(category);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedSubCategory(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (subCategory: IBotSubCategory) => {
        setSelectedSubCategory(subCategory);
        formValidation.setValues(_.cloneDeep(subCategory));
        setShowAddEditModal(true);
    };

    const onDeleteClick = (category: IBotSubCategory) => {
        setSelectedSubCategory(category);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteBotSubCategory(selectedSubCategory!);

        if (success) {
            await getData();
            sucessToast('AI Assistant Subcategory deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedSubCategory(undefined);
    };

    return {
        categories,
        subCategories,
        loading,
        selectedSubCategory,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        filteredSubCategoryId,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminBotsSubCategories;
