import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IBotCategory from '@src/interfaces/IBotCategory';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import IRole from '@src/interfaces/IRole';
import IUser from '@src/interfaces/IUser';
import { globals } from '@src/utils/constants';
import { splitAddress } from '@src/utils/helpers';
import 'cleave.js/dist/addons/cleave-phone.in';
import Cleave from 'cleave.js/react';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IClientsEditModal {
    show: boolean;
    formValidation: FormikProps<IUser>;
    loading: boolean;
    roles: Array<IRole>;
    categories: Array<IBotCategory>;
    onHide(): void;
}

const ClientsEditModal: React.FC<IClientsEditModal> = ({
    show,
    formValidation,
    loading,
    roles,
    categories,
    onHide
}) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    const validateAddressField = () => {
        //We only evaluate the zip code, because if theres no zipcode, it means the rest is not there
        return (
            !formValidation.touched.address || (!formValidation.errors.fullAddress && !formValidation.errors.zipcode)
        );
    };

    const onAddressSelected = (place: IGooglePlace) => {
        const addressFields = splitAddress(place);
        formValidation.setFieldValue('address', addressFields?.address);
        formValidation.setFieldValue('city', addressFields?.city);
        formValidation.setFieldValue('state', addressFields?.state);
        formValidation.setFieldValue('zipcode', addressFields?.zipcode);

        setForceRefresh(!forceRefresh);
    };

    const onAddressChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        formValidation.setFieldValue('fullAddress', e.target.value);
        //If user types the address without selecting it, we remove the zip to trigger the validation error
        formValidation.setFieldValue('zipcode', '');

        setForceRefresh(!forceRefresh);
    };

    return (
        <Modal isOpen={show} id='user-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    //To prevent the form to be sent when user press enter when selecting the address
                    if (document.activeElement?.id === 'address') return;
                    if (validateAddressField()) formValidation.handleSubmit();
                    formValidation.setFieldTouched('category', true);
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>Edit User</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter your name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name || ''}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='phone' className='form-label'>
                                Phone <span className='text-danger'>*</span>
                            </Label>
                            <Cleave
                                id='phone'
                                name='phone'
                                className={
                                    'form-control ' +
                                    (formValidation.touched.phone && !!formValidation.errors.phone
                                        ? 'is-invalid form-control'
                                        : '')
                                }
                                placeholder='(xxxx) xxx-xxx'
                                options={{
                                    numericOnly: true,
                                    delimiterLazyShow: true,
                                    delimiters: ['(', ') ', '-'],
                                    blocks: [0, 3, 3, 4]
                                }}
                                onBlur={formValidation.handleBlur}
                                onChange={formValidation.handleChange}
                                value={formValidation.values.phone || ''}
                            />
                            {formValidation.touched.phone && !!formValidation.errors.phone && (
                                <FormFeedback type='invalid' style={{ display: 'block' }}>
                                    {formValidation.errors.phone}
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Email <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                id='email'
                                name='email'
                                className='form-control'
                                placeholder='Enter email address'
                                type='email'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.email || ''}
                                invalid={formValidation.touched.email && !!formValidation.errors.email}
                            />
                            {formValidation.touched.email && !!formValidation.errors.email && (
                                <FormFeedback type='invalid'>{formValidation.errors.email}</FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Address <span className='text-danger'>*</span>
                            </Label>

                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                id='address'
                                name='address'
                                defaultValue={formValidation.values.fullAddress}
                                className={'form-control ' + (!validateAddressField() && 'is-invalid')}
                                placeholder='Enter your address'
                                onPlaceSelected={onAddressSelected}
                                onChange={onAddressChanged}
                                options={{
                                    componentRestrictions: { country: 'us' },
                                    types: ['address'],
                                    fields: ['address_components', 'formatted_address']
                                }}
                            />
                            {!validateAddressField() && (
                                <FormFeedback type='invalid'>{globals.invalidAddressMsg}</FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                                Open IA Api Key
                            </Label>
                            <Input
                                id='openaiApiKey'
                                name='openaiApiKey'
                                className='form-control'
                                placeholder='Enter an Open AI Key'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.openaiApiKey || ''}
                            />
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor='category' className='form-label'>
                                Category <span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='category'
                                value={formValidation.values.category}
                                onChange={(category) => {
                                    formValidation.setFieldValue('category', category);
                                }}
                                onBlur={() => formValidation.setFieldTouched('category', true)}
                                getOptionLabel={(c: IBotCategory) => c.name}
                                getOptionValue={(c: IBotCategory) => c.botCategoryId}
                                options={categories}
                                classNames={{
                                    control: () =>
                                        formValidation.touched.category && !!formValidation.errors.category
                                            ? 'form-control is-invalid'
                                            : ''
                                }}
                            />
                            {/* To show the error for react-select */}
                            <FormSelectFeedback
                                show={formValidation.touched.category && !!formValidation.errors.category}
                                message={formValidation.errors.category}
                            />
                        </div>
                        {globals.showMunicipalityOptions && (
                            <div className='mb-3'>
                                <Label htmlFor='roles' className='form-label'>
                                    Roles
                                </Label>
                                <Select
                                    name='roles'
                                    value={formValidation.values.roles}
                                    onChange={(roles) => {
                                        formValidation.setFieldValue('roles', roles);
                                    }}
                                    isMulti
                                    getOptionLabel={(role: IRole) => role.name}
                                    getOptionValue={(role: IRole) => role.roleId}
                                    options={roles}
                                />
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default ClientsEditModal;
