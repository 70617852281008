import React from 'react';
import { Row, TabPane } from 'reactstrap';
import 'react-credit-cards/es/styles-compiled.css';
import IUser from '@src/interfaces/IUser';
import useBotsTab from './useBotsTab';
import CustomPagination from '@src/components/CustomPagination';
import { globals } from '@src/utils/constants';
import BotSimpleInfoCard from '@src/components/BotSimpleInfoCard';

interface IBotsTab {
    user?: IUser;
}

const BotsTab: React.FC<IBotsTab> = ({ user }) => {
    const { filteredBots, bots, currentPage, setCurrentPage } = useBotsTab({ user });

    return (
        <TabPane tabId='2' className='mx-4'>
            <Row>
                {filteredBots?.map((bot, key) => (
                    <BotSimpleInfoCard keyVal={key} bot={bot} />
                ))}
            </Row>
            <CustomPagination
                total={Math.ceil((bots?.length || 0) / globals.perPageBotsAdmin)}
                perPage={globals.perPageBotsAdmin}
                currentPage={currentPage}
                changePage={setCurrentPage}
            />
        </TabPane>
    );
};

export default BotsTab;
