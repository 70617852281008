import { FormButton } from '@src/components/FormButton';
import { IGoogleContact } from '@src/interfaces/IGoogleContact';
import { trimAll } from '@src/utils/helpers';
import React from 'react';
import { Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IInviteContactsModal {
    show: boolean;
    loading: boolean;
    filterText: string;
    googleContacts: Array<IGoogleContact>;
    totalContactsSelected: number;
    filteredGoogleContacts: Array<IGoogleContact>;
    setFilterText(value: React.SetStateAction<string>): void;
    handleContactCheckChange(event: React.ChangeEvent<HTMLInputElement>): void;
    handleContactItemCheckChange(event: React.ChangeEvent<HTMLInputElement>): void;
    sendInviteToContacts(): Promise<void>;
    setGoogleContacts(value: React.SetStateAction<IGoogleContact[]>): void;
}

export const InviteContactsModal: React.FC<IInviteContactsModal> = ({
    show,
    loading,
    filterText,
    googleContacts,
    totalContactsSelected,
    filteredGoogleContacts,
    setFilterText,
    handleContactCheckChange,
    handleContactItemCheckChange,
    sendInviteToContacts,
    setGoogleContacts
}) => {
    return (
        <Modal key={'contact-modal'} isOpen={show} id='contacts-modal' centered toggle={() => setGoogleContacts([])}>
            <ModalBody>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <h4>Invite your Google Contacts</h4>
                        <p className='text-muted'>Select the contacts you wish to invite to use Connections!</p>
                    </div>
                    <div className='text-center mb-2'>
                        <i className='ri-contacts-book-line m-2 display-3 text-primary' />
                    </div>
                </div>
                <form action='#'>
                    <div className='form-icon mb-2 ps-2'>
                        <Input
                            name='filter-contacts'
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control form-control-icon'
                        />
                        <i className='ri-filter-line fs-20 text-primary ms-n1 ps-2' />
                    </div>
                    <div className=' mt-2 mb-2 ps-2'>
                        <span className='text-muted'>
                            Total found: <b>{filteredGoogleContacts.length}</b> of <b>{googleContacts.length}</b>
                            <br />
                            Selected: <b>{totalContactsSelected}</b>
                        </span>
                    </div>
                    <div className='mb-3 ps-2' style={{ height: '400px', overflow: 'auto' }}>
                        {googleContacts.map((contact, idx) => {
                            return (
                                <>
                                    <div
                                        key={`${idx}-${contact.names?.[0].displayName}`}
                                        className='form-check form-check-outline form-check-info mb-3'
                                        style={{
                                            display: contact.names?.[0].displayName
                                                .toLowerCase()
                                                .includes(filterText.toLowerCase())
                                                ? 'block'
                                                : 'none'
                                        }}
                                    >
                                        <Input
                                            className='form-check-input contacts-check'
                                            type='checkbox'
                                            id={trimAll(`c${idx}-${contact.names?.[0].displayName}`)}
                                            onChange={handleContactCheckChange}
                                        />
                                        <Label
                                            className='form-check-label fs-15'
                                            for={trimAll(`c${idx}-${contact.names?.[0].displayName}`)}
                                        >
                                            {contact.names?.[0].displayName}
                                        </Label>

                                        <div className='mt-2'>
                                            {contact.emailAddresses?.map((email, idx2) => (
                                                <div
                                                    key={`${idx}-${idx2}-${email.value}`}
                                                    className='form-check form-check-outline form-check-info ms-3'
                                                >
                                                    <Input
                                                        className='form-check-input contacts-check-item'
                                                        type='checkbox'
                                                        id={`c${idx}-${idx2}-${email.value}`}
                                                        onChange={handleContactItemCheckChange}
                                                        data-contact-name={contact.names?.[0].displayName}
                                                        data-contact-email={email.value}
                                                        data-parent-id={trimAll(
                                                            `c${idx}-${contact.names?.[0].displayName}`
                                                        )}
                                                    />
                                                    <Label
                                                        className='form-check-label d-flex align-items-center'
                                                        for={`c${idx}-${idx2}-${email.value}`}
                                                    >
                                                        <i className='ri-mail-line me-1 text-muted' />
                                                        <span className='text-muted'> {email.value}</span>
                                                    </Label>
                                                </div>
                                            ))}
                                            {contact.phoneNumbers?.map((phone, idx3) => (
                                                <div
                                                    key={`${idx}-${idx3}-${phone.value}`}
                                                    className='form-check form-check-outline form-check-info ms-3'
                                                >
                                                    <Input
                                                        className='form-check-input contacts-check-item'
                                                        type='checkbox'
                                                        id={`c${idx}-${idx3}-${phone.value}`}
                                                        onChange={handleContactItemCheckChange}
                                                        data-contact-name={contact.names?.[0].displayName}
                                                        data-contact-phone={phone.value}
                                                        data-parent-id={trimAll(
                                                            `c${idx}-${contact.names?.[0].displayName}`
                                                        )}
                                                    />
                                                    <Label
                                                        className='form-check-label d-flex align-items-center'
                                                        for={`c${idx}-${idx3}-${phone.value}`}
                                                    >
                                                        <i className='ri-phone-line me-1 text-muted' />
                                                        <span className='text-muted'> {phone.value}</span>
                                                    </Label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <FormButton
                    value='Send Invite'
                    loading={loading}
                    fitParent={false}
                    form='request-form'
                    onClick={sendInviteToContacts}
                />
                <FormButton value='Cancel' color='light' onClick={() => setGoogleContacts([])} fitParent={false} />
            </ModalFooter>
        </Modal>
    );
};
