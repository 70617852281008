import { globals } from '@src/utils/constants';
import { generateUniqueId } from '@src/utils/helpers';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Button, Input, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import * as XLSX from 'xlsx';

interface ICustomTable {
    data: Array<any>;
    columns: Array<ColumnDef<any, any>>;
    className?: string;
    initialFilter?: string;
    showExport?: boolean;
}

export const CustomTable: React.FC<ICustomTable> = ({ data, columns, className, initialFilter, showExport = true }) => {
    const exportTableId = 'export-custom-table-' + generateUniqueId();
    const [sorting, setSorting] = useState<Array<any>>([]);
    const [filtering, setFiltering] = useState(initialFilter);

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting: sorting,
            globalFilter: filtering
        },
        defaultColumn: {
            minSize: 0,
            size: 0
        },
        initialState: { pagination: { pageSize: globals.perPageTables } },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering
    });

    //Export table setup
    const exportTable = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting: sorting,
            globalFilter: filtering
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering
    });

    const exportToPdf = () => {
        const actionColumnIndex = columns.findIndex((column) => column.header === 'Actions');
        const clonedTable = getExportHtmlTable();
        // Remove the Actions column
        if (actionColumnIndex >= 0) for (let row of clonedTable?.rows) row.deleteCell(actionColumnIndex);
        //Create pdf object
        const pdfDoc = new jsPDF();
        autoTable(pdfDoc, { html: clonedTable });
        pdfDoc.save(`data_${moment().format('MMDDYYYYHHmmss')}.pdf`);
    };

    const exportToExcel = () => {
        const actionColumnIndex = columns.findIndex((column) => column.header === 'Actions');
        const clonedTable = getExportHtmlTable();
        for (let row of clonedTable?.rows) {
            // Remove the Actions column
            if (actionColumnIndex >= 0) row.deleteCell(actionColumnIndex);
            for (let cell of row.cells) {
                // Replace the cell's content with the text of the link
                if (cell.firstChild instanceof HTMLAnchorElement) cell.textContent = cell.firstChild.textContent;
            }
        }
        // Extract Data (create a workbook object from the table)
        const wb = XLSX.utils.table_to_book(clonedTable);
        XLSX.writeFile(wb, `data_${moment().format('MMDDYYYYHHmmss')}.xlsx`);
    };

    const exportToCsv = () => {
        const actionColumnIndex = columns.findIndex((column) => column.header === 'Actions');
        const clonedTable = getExportHtmlTable();
        for (let row of clonedTable?.rows) {
            // Remove the Actions column
            if (actionColumnIndex >= 0) row.deleteCell(actionColumnIndex);
            for (let cell of row.cells) {
                // Replace the cell's content with the text of the link
                if (cell.firstChild instanceof HTMLAnchorElement) cell.textContent = cell.firstChild.textContent;
            }
        }
        const ws = XLSX.utils.table_to_sheet(clonedTable);
        const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
        // Create a Blob from the CSV string
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        // Create a URL from the Blob
        const url = URL.createObjectURL(blob);
        // Create a link with the URL as the href and programmatically click it
        const link = document.createElement('a');
        link.href = url;
        link.download = `data_${moment().format('MMDDYYYYHHmmss')}.csv`;
        link.click();
        // Revoke the URL
        URL.revokeObjectURL(url);
    };

    const getExportHtmlTable = () => {
        const table = document.getElementById(exportTableId) as HTMLTableElement;
        return table.cloneNode(true) as HTMLTableElement;
    };

    const customClassName = useMemo(() => 'table-responsive ' + className, [className]);

    return (
        <div className={customClassName}>
            <div className='d-flex justify-content-center align-items-center mb-2'>
                <div className='form-icon w-100'>
                    <Input
                        name='filter-contacts'
                        value={filtering}
                        onChange={(e) => setFiltering(e.target.value)}
                        className='form-control form-control-icon'
                    />
                    <i className='ri-filter-line fs-20 text-primary ms-n1' />
                </div>

                {showExport && (
                    <>
                        <Button color='secondary' onClick={exportToPdf} className='btn-label ms-2'>
                            <i className='ri-file-download-fill label-icon align-middle fs-16'></i> PDF
                        </Button>
                        <Button color='success' onClick={exportToExcel} className='btn-label ms-2'>
                            <i className='ri-file-download-fill label-icon align-middle fs-16'></i> Excel
                        </Button>
                        <Button color='info' onClick={exportToCsv} className='btn-label ms-2'>
                            <i className='ri-file-download-fill label-icon align-middle fs-16'></i> CSV
                        </Button>
                    </>
                )}
            </div>
            <Table className='table-bordered align-middle table-nowrap mb-0 custom-table'>
                <thead className='table-light'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    scope='col'
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ width: header.getSize() ? header.getSize() : 'auto' }}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div className='d-flex align-items-center' role='button'>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getIsSorted() === 'asc' && (
                                                <i className='ri-sort-asc ms-2' />
                                            )}
                                            {header.column.getIsSorted() === 'desc' && (
                                                <i className='ri-sort-desc ms-2' />
                                            )}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className='align-items-center mt-4 pt-2 justify-content-between d-md-flex'>
                <div className='flex-shrink-0 mb-2 mb-md-0'>
                    <div className='text-muted'>
                        Showing{' '}
                        <span className='fw-semibold'>
                            {table.getPageCount() === 1
                                ? table.getFilteredRowModel().rows.length
                                : table.getState().pagination.pageSize * table.getState().pagination.pageIndex +
                                  table.getRowModel().rows.length}
                        </span>{' '}
                        of <span className='fw-semibold'>{table.getFilteredRowModel().rows.length}</span> Results
                    </div>
                </div>
                {table.getPageCount() > 1 && (
                    <Pagination listClassName='justify-content-center' className='pagination-separated mb-0'>
                        <PaginationItem>
                            <PaginationLink to='#' onClick={() => table.setPageIndex(0)}>
                                First
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                to='#'
                                disabled={!table.getCanPreviousPage()}
                                onClick={() => table.previousPage()}
                            >
                                <i className='mdi mdi-chevron-left' />
                            </PaginationLink>
                        </PaginationItem>
                        {Array.from({ length: table.getPageCount() }, (_, i) => i + 1).map((i) => {
                            return (
                                <PaginationItem
                                    active={i - 1 === table.getState().pagination.pageIndex}
                                    key={`page-${i}`}
                                >
                                    <PaginationLink to='#' onClick={() => table.setPageIndex(i - 1)}>
                                        {i}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        })}

                        <PaginationItem>
                            <PaginationLink to='#' disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
                                <i className='mdi mdi-chevron-right' />
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                to='#'
                                disabled={!table.getCanNextPage()}
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            >
                                Last
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                )}
            </div>

            {/* Export hidden table */}
            <Table id={exportTableId} className='table-bordered align-middle table-nowrap mb-0 custom-table d-none'>
                <thead className='table-light'>
                    {exportTable.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    scope='col'
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ width: header.getSize() ? header.getSize() : 'auto' }}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div className='d-flex align-items-center' role='button'>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getIsSorted() === 'asc' && (
                                                <i className='ri-sort-asc ms-2' />
                                            )}
                                            {header.column.getIsSorted() === 'desc' && (
                                                <i className='ri-sort-desc ms-2' />
                                            )}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {exportTable.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
