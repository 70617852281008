export enum RoutesName {
    //Public customers
    root = '/',
    login = '/login',
    register = '/register',
    registrationWizard = '/registration-wizard',
    registerVerification = '/register-verification',
    forgotPassword = '/forgot-password',
    resetPassword = '/reset-password/:token',
    faqs = '/faqs',
    notFound = '/not-found',
    accessDenied = '/access-denied',
    testScrapper = 'testscrapper',
    // Clients
    dashboard = '/dashboard',
    questionnaire = '/questionnaire',
    profile = '/profile',
    messages = '/messages',
    billing = '/billing',
    messagesWithBotId = '/messages/:botId',
    logout = '/logout',
    emailVerification = '/email-verification',
    phoneVerification = '/phone-verification',
    //Customers
    botEmbed = '/chat/:id',
    //Admin
    adminRoot = '/admin',
    adminLogin = '/admin/login',
    adminDashboard = '/admin/dashboard',
    adminProfile = '/admin/profile',
    adminClientsInfo = '/admin/clients/:userId',
    adminClients = '/admin/clients',
    adminLeads = '/admin/leads',
    adminImportUser = '/admin/import-user',
    adminUsers = '/admin/users',
    adminQuestions = '/admin/questions',
    adminUsersAnswers = '/admin/users/answers',
    adminBotCategories = '/admin/bots/categories',
    adminBotCategoriesWithId = '/admin/bots/categories/:categoryId',
    adminBotSubCategories = '/admin/bots/subcategories',
    adminBotSubCategoriesByCategory = '/admin/bots/subcategories/:categoryId/:subCategoryId',
    adminBotPrompts = '/admin/bots/prompts',
    adminBotMessages = '/admin/bots/messages/:botId',
    adminBotsList = '/admin/bots',
    adminStates = '/admin/states',
    adminStatesWithId = '/admin/states/:stateId',
    adminMunicipalities = '/admin/municipalities',
    adminMunicipalitiesByState = '/admin/municipalities/:stateId/:municipalityId',
    adminMunicipalitiesRequests = '/admin/municipalities-requests'
}

export enum MessageRole {
    assistant = 'assistant',
    user = 'user'
}

export enum MessageAction {
    validatePhoneNumber = 'ValidatePhoneNumber',
    createLead = 'CreateLead',
    endChat = 'EndChat',
    chatUnavailable = 'ChatUnavailable'
}

export enum MenuItems {
    dashboard = 'dashboard',
    users = 'users',
    bots = 'AI Assistants',
    questionnaire = 'questionnaire',
    municipalities = 'municipalities'
}

export enum SubMenuItems {
    //Users
    clientsList = 'clients',
    leadsList = 'leads',
    adminsList = 'admininstrators',
    importUser = 'Import user',
    //Bots
    botList = 'list all',
    botCategories = 'categories',
    botSubCategories = 'sub categories',
    botPrompts = 'global Prompts',
    //questionnaire
    questions = 'questions',
    usersAnswers = 'users answers',
    //Municipalities
    states = 'states',
    municipalities = 'municipalities',
    municipalitiesRequests = 'municipalities requests'
}

export enum SideBarClientItems {
    home = 'Home',
    billing = 'Billing',
    questionnaire = 'Questionnaire'
}
