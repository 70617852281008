import { CustomTable } from '@src/components/CustomTable';
import OkCancelModal from '@src/components/OkCancelModal';
import ISubscription from '@src/interfaces/ISubscription';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import { cancelSubscription, getSubscriptions, revertCancelSubscription } from '@src/services/PaymentService';
import globalStore from '@src/stores/globalStore';
import { capitalize, errorToast, getLoggedUser, sucessToast } from '@src/utils/helpers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Button, Col, Row, TabPane, UncontrolledTooltip } from 'reactstrap';

interface IBillingHistory {}
const SubscriptionsTab: React.FC<IBillingHistory> = ({}) => {
    const loggedUser = getLoggedUser();
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);
    const [subscriptions, setSubscriptions] = useState<Array<ISubscriptionProduct>>([]);
    const [selectedSubscription, setSelectedSubscription] = useState<ISubscription>();
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showRevertCancelModal, setShowRevertCancelModal] = useState<boolean>(false);

    const columnHelper = createColumnHelper<ISubscription>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        {
            header: 'Created',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).format('MM/DD/YYYY')
        },
        {
            header: 'Description',
            accessorKey: 'subscriptionProduct.description'
        },
        {
            header: 'AI Assistant',
            accessorKey: 'bot.name'
        },
        columnHelper.display({
            header: 'Status',
            cell: ({ row }) => {
                return (
                    <div className='d-flex align-items-center'>
                        <Badge color={row.original.isActive ? 'success' : 'danger'}>
                            {capitalize(row.original.isActive ? 'active' : 'inactive')}
                        </Badge>
                        {!!row.original.canceledAt && row.original.isActive && (
                            <Badge color='light' className='text-muted fs-12 ms-2 d-flex align-items-center'>
                                <i className='ri-time-line me-2'></i>Cancels{' on '}
                                {moment(row.original.nextChargeDate).format('MMM D')}
                            </Badge>
                        )}
                    </div>
                );
            }
        }),

        {
            header: 'Amount',
            accessorKey: 'subscriptionProduct.price',
            cell: (value) => `$${value.getValue()}`
        },
        {
            header: 'Interval',
            accessorKey: 'subscriptionProduct.recurringIntervalDescription',
            cell: (value) => capitalize(value.getValue())
        },
        columnHelper.display({
            header: 'Current Period',
            cell: ({ row }) => {
                return (
                    <div className='d-flex align-items-center'>
                        <span className='ms-2'>
                            {moment(row.original.nextChargeDate)
                                .subtract(row.original.subscriptionProduct.recurringInterval, 'days')
                                .format('MM/DD/YYYY')}
                            {' to '}
                            {moment(row.original.nextChargeDate).format('MM/DD/YYYY')}
                        </span>
                    </div>
                );
            }
        }),
        columnHelper.display({
            header: 'Actions',
            size: 30,
            cell: ({ row }) => {
                return (
                    <ul className='list-inline hstack gap-2 mb-0 d-flex justify-content-center'>
                        {row.original.isActive && !row.original.canceledAt && (
                            <li className='list-inline-item'>
                                <Button
                                    id={`cancel-subscription-link-${row.original.subscriptionId}`}
                                    color='danger'
                                    className='btn-icon'
                                    onClick={() => onCancelClick(row.original)}
                                    outline
                                >
                                    <i className='ri-close-circle-fill' />{' '}
                                </Button>
                                <UncontrolledTooltip
                                    placement='top'
                                    target={`cancel-subscription-link-${row.original.subscriptionId}`}
                                >
                                    Cancel Subscription
                                </UncontrolledTooltip>
                            </li>
                        )}

                        {row.original.isActive && row.original.canceledAt && (
                            <li className='list-inline-item'>
                                <Button
                                    id={`cancel-subscription-link-${row.original.subscriptionId}`}
                                    color='warning'
                                    className='btn-icon'
                                    onClick={() => onRevertCancelClick(row.original)}
                                    outline
                                >
                                    <i className='ri-arrow-go-back-fill' />{' '}
                                </Button>
                                <UncontrolledTooltip
                                    placement='top'
                                    target={`cancel-subscription-link-${row.original.subscriptionId}`}
                                >
                                    Revert Subscription Cancellation
                                </UncontrolledTooltip>
                            </li>
                        )}

                        {(!row.original.isActive || !!row.original.deletedAt) && '-'}
                    </ul>
                );
            }
        })
    ];

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { success, data, message } = await getSubscriptions(loggedUser.userId!);
        if (success) {
            setSubscriptions(data);
        } else errorToast(message);
    };

    const onCancelClick = async (subscription: ISubscription) => {
        setSelectedSubscription(subscription);
        setShowCancelModal(true);
    };

    const handleCancelSubscription = async () => {
        setLoading(true);

        const { success, message } = await cancelSubscription(selectedSubscription!.subscriptionId);

        if (success) {
            await getData();
            sucessToast('Subscription was canceled successfully!');
            setShowCancelModal(false);
        } else errorToast(message);

        setLoading(false);
    };

    const onRevertCancelClick = async (subscription: ISubscription) => {
        setSelectedSubscription(subscription);
        setShowRevertCancelModal(true);
    };

    const handleRevertCancelSubscription = async () => {
        setLoading(true);

        const { success, message } = await revertCancelSubscription(selectedSubscription!.subscriptionId);

        if (success) {
            await getData();
            sucessToast('Subscription cancellation was reverted successfully!');
            setShowRevertCancelModal(false);
        } else errorToast(message);

        setLoading(false);
    };

    return (
        <TabPane tabId='3'>
            <Row>
                <Col lg={12}>
                    <CustomTable data={subscriptions} columns={columns} />
                </Col>
            </Row>

            <OkCancelModal
                title={`Are you sure you want to cancel <br /><b>${selectedSubscription?.subscriptionProduct.name} for AI Assistant: ${selectedSubscription?.bot.name}</b>?`}
                subTitle="If you continue, your AI Assistant will stop working after the end period of the subscription. After cancellation, you won't be able to reactivate this subscription, but you'll be able to add a new subscriptions for AI Assistants that have a canceled/inactive subscription."
                show={showCancelModal}
                onAccept={handleCancelSubscription}
                yesText="Yes, I'm sure"
                onCancel={() => setShowCancelModal(false)}
                cancelText='Cancel'
                loading={loading}
            />

            <OkCancelModal
                title={`Are you sure you want to revert the cancellation process of <b>${selectedSubscription?.subscriptionProduct.name} for AI Assistant: ${selectedSubscription?.bot.name}</b>?`}
                subTitle='If you continue, you will be charge until the next payment date.'
                show={showRevertCancelModal}
                onAccept={handleRevertCancelSubscription}
                yesText="Yes, I'm sure"
                onCancel={() => setShowRevertCancelModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </TabPane>
    );
};

export default SubscriptionsTab;
