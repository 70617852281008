import 'cleave.js/dist/addons/cleave-phone.in';
import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

// Formik formValidation
import { useFormik } from 'formik';
import * as Yup from 'yup';

import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import IUser from '@src/interfaces/IUser';
import { getBotCategories } from '@src/services/BotCategoryService';
import { createUser, login } from '@src/services/UserService';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, errorToast, splitAddress } from '@src/utils/helpers';
import { registerInitValues } from '@src/utils/initValues';
import { jwtDecode } from 'jwt-decode';
const useRegister = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('key') || '';

    const referralObj = useMemo(() => (token ? (jwtDecode(token) as any) : undefined), []);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [categories, setCategories] = useState<Array<IBotCategory>>([]);

    const formValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            phone: Yup.string().required('Please enter your phone'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter your email'),
            fullAddress: Yup.string().required(globals.invalidAddressMsg),
            address: Yup.string().required(globals.invalidAddressMsg),
            city: Yup.string().required(globals.invalidAddressMsg),
            state: Yup.string().required(globals.invalidAddressMsg),
            zipcode: Yup.string().required(globals.invalidAddressMsg),
            category: Yup.object<IBotCategory>().required('Please select a category'),
            subCategory: Yup.object<IBotSubCategory>().required('Please select a sub category'),
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required('Confirm password is required')
        }),
        onSubmit: async (values: IUser) => {
            console.log('submit', values);
            if (loading) return;

            setLoading(true);
            setErrorMsg('');
            const { data, success, message } = await createUser(values);
            if (success) {
                localStorage.setItem(globals.userSessionKey, encryptData(data));
                await login(values.email, values.password);
                navigate(RoutesName.registerVerification, { replace: true });
            } else {
                setErrorMsg(message);
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        formValidation.setFieldValue('referralBy', {
            user_id: referralObj?.user_id,
            referralCode: referralObj?.referral_code
        });
        formValidation.setFieldValue('name', referralObj?.name || '');
        formValidation.setFieldValue('email', referralObj?.email || '');
    }, [referralObj]);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    useEffect(() => {
        formValidation.setFieldValue('subCategory', null);
    }, [formValidation.values.category]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const { success, data, message } = await getBotCategories();
        if (success) {
            setCategories(data);
        } else errorToast(message);
        setLoading(false);
    };

    const validateAddressField = () => {
        //We only evaluate the zip code, because if theres no zipcode, it means the rest is not there
        return (
            !formValidation.touched.address || (!formValidation.errors.fullAddress && !formValidation.errors.zipcode)
        );
    };

    const onAddressSelected = (place: IGooglePlace) => {
        const addressFields = splitAddress(place);
        formValidation.setFieldValue('address', addressFields?.address);
        formValidation.setFieldValue('city', addressFields?.city);
        formValidation.setFieldValue('state', addressFields?.state);
        formValidation.setFieldValue('zipcode', addressFields?.zipcode);

        setForceRefresh(true);
    };

    const onAddressChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        formValidation.setFieldValue('fullAddress', e.target.value);
        //If user types the address without selecting it, we remove the zip to trigger the validation error
        formValidation.setFieldValue('zipcode', '');

        setForceRefresh(true);
    };

    return {
        formValidation,
        errorMsg,
        loading,
        referralObj,
        categories,
        validateAddressField,
        onAddressSelected,
        onAddressChanged
    };
};

export default useRegister;
