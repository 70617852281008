import { RoutesName } from '@src/utils/enums';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

export const EmailNotVerifiedAlert = () => {
    return (
        <Alert color='warning'>
            You haven't verified your email yet. If you want to received important emails regarding your acccount
            balance, transactions, etc, please{' '}
            <b>
                <Link className='text-primary' to={RoutesName.emailVerification}>
                    click here to verify it.
                </Link>
            </b>
        </Alert>
    );
};
