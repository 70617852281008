import { FormButton } from '@src/components/FormButton';
import FormSelectFeedback from '@src/components/FormSelectFeedback';
import IMunicipality from '@src/interfaces/IMunicipality';
import IState from '@src/interfaces/IState';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IMunicipalitiesListAddEditModal {
    show: boolean;
    formValidation: FormikProps<IMunicipality>;
    loading: boolean;
    states?: Array<IState>;
    onHide(): void;
}

const MunicipalitiesListAddEditModal: React.FC<IMunicipalitiesListAddEditModal> = ({
    show,
    formValidation,
    loading,
    states,
    onHide
}) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    const addDocumentElement = () => {
        const inputsArray = formValidation.values.documentsInputs
            ? [...formValidation.values.documentsInputs, '']
            : [''];
        formValidation.setFieldValue('documentsInputs', inputsArray);
    };

    const documentOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        if (!formValidation.values.documentsInputs) return;

        const temp = [...formValidation.values.documentsInputs];
        temp[idx] = e.target.files?.[0];
        formValidation.setFieldValue('documentsInputs', [...temp]);
    };

    const documentOnRemove = (idx: number) => {
        const element = document.getElementById(`document-div-${idx}`);
        if (element && formValidation.values.documentsInputs) {
            //Hide element to simulate the deletion
            element.setAttribute('style', 'display:none !important');
            formValidation.values.documentsInputs[idx] = null;
        }
    };

    const onMarkDocumentDeleted = (documentId: string) => {
        const docFound = formValidation.values.deletedDocuments?.find((d) => d === documentId);
        if (docFound) {
            const filtered = formValidation.values.deletedDocuments?.filter((d) => d !== docFound);
            formValidation.setFieldValue('deletedDocuments', filtered);
        } else {
            const newDocsArray = [...(formValidation.values.deletedDocuments ?? []), documentId];
            formValidation.setFieldValue('deletedDocuments', newDocsArray);
        }
    };

    return (
        <Modal isOpen={show} id='category-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.muniId ? 'Edit' : 'Add'} Municipality</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='isoCode' className='form-label'>
                                Iso Code <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='isoCode'
                                type='text'
                                placeholder='Enter ISO Code'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.isoCode}
                                invalid={formValidation.touched.isoCode && !!formValidation.errors.isoCode}
                            />
                            {formValidation.touched.isoCode && !!formValidation.errors.isoCode && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.isoCode}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='state' className='form-label'>
                                State <span className='text-danger'>*</span>
                            </Label>
                            <Select
                                name='state'
                                value={formValidation.values.state}
                                onChange={(state) => {
                                    formValidation.setFieldValue('state', state);
                                    formValidation.setFieldValue('stateId', state?.stateId);
                                }}
                                getOptionLabel={(s: IState) => s.name}
                                getOptionValue={(s: IState) => s.stateId}
                                options={states}
                                onBlur={formValidation.handleBlur}
                                classNames={{
                                    control: () =>
                                        formValidation.touched.stateId && !!formValidation.errors.stateId
                                            ? 'form-control is-invalid'
                                            : ''
                                }}
                            />
                            {/* To show the error for react-select */}
                            <FormSelectFeedback
                                show={formValidation.touched.stateId && !!formValidation.errors.stateId}
                                message={formValidation.errors.stateId}
                            />
                        </div>
                        <div id={`document-div`} className='mb-3'>
                            <Label className='form-label'>Knowledge documents</Label>
                            <p className='text-muted'>
                                You can add documents (pdf, doc, ppt, txt) to feed the AI Assistants with more
                                information of the municipality
                            </p>
                            <div className={'d-flex '}>
                                {formValidation?.values.documents?.map((doc, idx) => {
                                    const colorClass = formValidation.values.deletedDocuments?.includes(doc.id)
                                        ? 'danger'
                                        : 'primary';
                                    const icon =
                                        colorClass === 'danger' ? 'ri-arrow-go-back-line' : 'ri-delete-bin-line';
                                    return (
                                        <div className='d-flex me-2' key={'form-doc-' + idx}>
                                            <Link to={doc.url || '#'} target='_blank'>
                                                <span
                                                    className={`badge rounded-pill border border-${colorClass} me-1 text-${colorClass}`}
                                                >
                                                    doc-{idx + 1}
                                                </span>
                                            </Link>
                                            <i
                                                className={'fs-15 ' + icon}
                                                role='button'
                                                onClick={() => onMarkDocumentDeleted(doc.id)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {!!formValidation.values.deletedDocuments?.length && (
                                <div className='text-muted'>
                                    Documents to be deleted: <b>{formValidation.values.deletedDocuments?.length}</b>
                                </div>
                            )}
                            <div className={formValidation?.values.documents ? 'mb-2' : ''}></div>
                            <div className='d-flex align-items-center mb-2' role='button' onClick={addDocumentElement}>
                                <i className='ri-add-circle-line fs-24 me-1' /> Add document
                            </div>
                            {formValidation.values.documentsInputs?.map((document, idx) => (
                                <div key={idx} id={`document-div-${idx}`} className='d-flex mb-2'>
                                    <div className='w-100 me-2'>
                                        <Input
                                            id={`document-${idx}`}
                                            name={`document-${idx}`}
                                            className='form-control'
                                            type='file'
                                            accept='.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf'
                                            onChange={(e) => documentOnChange(e, idx)}
                                        />
                                    </div>
                                    <i
                                        className='ri-delete-bin-line fs-24'
                                        role='button'
                                        onClick={() => documentOnRemove(idx)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default MunicipalitiesListAddEditModal;
