import React from 'react';
import { Link } from 'react-router-dom';

import logoSm from '@src/assets/images/logo_connections.png';

import ProfileDropdown from '@src/components/ProfileDropdown';
import { RoutesName } from '@src/utils/enums';

const Header = ({ headerClass }: { headerClass: string }) => {
    document.documentElement.setAttribute('data-layout', 'horizontal');

    return (
        <React.Fragment>
            <header id='page-topbar' className={headerClass}>
                <div className='layout-width'>
                    <div className='navbar-header'>
                        <div className='d-flex'>
                            <div className='navbar-brand-box horizontal-logo'>
                                <Link to={RoutesName.dashboard} className='logo'>
                                    <span className='logo-lg'>
                                        <img src={logoSm} alt='Connections' height='50' />
                                    </span>
                                </Link>
                            </div>
                        </div>

                        <div className='d-flex align-items-center'>
                            {/* <Link to={RoutesName.dashboard}>
                                <i className='ri-home-7-fill h1' />
                            </Link> */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
