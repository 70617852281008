import { IGlobalStore } from '@src/interfaces/IStore';
import { ConfirmationResult } from 'firebase/auth';
import { create } from 'zustand';

export default create<IGlobalStore>((set, get) => ({
    loading: false,
    phoneOTPResponse: {} as ConfirmationResult,
    httpRequestCounter: 0,

    setLoading: (val: boolean) => {
        set(() => ({
            loading: !val && get().httpRequestCounter > 1 ? true : val,
            httpRequestCounter: val ? get().httpRequestCounter + 1 : get().httpRequestCounter - 1
        }));
    },
    setPhoneOTPResponse: (res: ConfirmationResult) => set(() => ({ phoneOTPResponse: res }))
}));
