import HttpService from '@src/utils/HttpService';
import IRole from '@src/interfaces/IRole';
import { objToCamel } from '@src/utils/helpers';

export const getRole = async (roleId: string) => {
    const response = { data: {} as IRole, success: false, message: '' };

    try {
        const endPoint = `/roles/${roleId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IRole;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getRoles = async () => {
    const response = { data: [] as Array<IRole>, success: false, message: '' };

    try {
        const endPoint = `/roles`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IRole);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
