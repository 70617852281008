import { FormButton } from '@src/components/FormButton';
import htmlParse from 'html-react-parser';
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IOkCancelModal {
    show: boolean;
    title: string;
    subTitle?: string;
    bodyContent?: React.ReactNode;
    loading?: boolean;
    yesText?: string;
    cancelText?: string;
    hideYesButton?: boolean;
    type?: 'warning' | 'info' | 'error' | 'question' | 'none';
    onCancel(): void;
    onAccept(): void;
}

const OkCancelModal: React.FC<IOkCancelModal> = ({
    show,
    loading,
    title,
    subTitle,
    bodyContent,
    yesText = 'Yes',
    cancelText,
    hideYesButton = false,
    type = 'question',
    onCancel,
    onAccept
}) => {
    let icon = '';
    if (type === 'question') icon = 'ri-question-line color-primary';
    if (type === 'warning') icon = 'mdi mdi-alert-outline text-warning';
    if (type === 'info') icon = 'ri-information-line text-info';
    if (type === 'error') icon = 'ri-error-warning-line text-danger';

    return (
        <Modal isOpen={show} id='new-bot-modal' centered toggle={onCancel}>
            <ModalBody className='text-center'>
                {!!icon && <i className={`${icon} m-2 display-1`} />}
                <div className='pt-2'>
                    <h4>{htmlParse(title)}</h4>
                    {subTitle && <p className='text-muted'>{htmlParse(subTitle)}</p>}
                    <div className='mx-2'>{bodyContent}</div>
                </div>
            </ModalBody>
            <ModalFooter>
                {!hideYesButton && (
                    <FormButton value={yesText} loading={loading} onClick={onAccept} fitParent={false} />
                )}

                {!!cancelText && <FormButton value={cancelText} color='light' onClick={onCancel} fitParent={false} />}
            </ModalFooter>
        </Modal>
    );
};

export default OkCancelModal;
