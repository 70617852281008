import { FormButton } from '@src/components/FormButton';
import IBotPrompt from '@src/interfaces/IBotPrompt';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IBotsPromptsAddEditModal {
    show: boolean;
    formValidation: FormikProps<IBotPrompt>;
    loading: boolean;
    onHide(): void;
}

const BotsPromptsAddEditModal: React.FC<IBotsPromptsAddEditModal> = ({ show, formValidation, loading, onHide }) => {
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    return (
        <Modal isOpen={show} id='category-info-modal' centered backdrop={'static'}>
            <Form
                id='edit-form'
                onSubmit={(e) => {
                    e.preventDefault();
                    //To prevent the form to be sent when category press enter when selecting the address
                    if (document.activeElement?.id === 'address') return;
                    formValidation.handleSubmit();
                    return false;
                }}
                action='#'
            >
                <ModalBody>
                    <h3>{formValidation.values.promptId ? 'Edit' : 'Add'} BotPrompt</h3>
                    <div className='mt-3'>
                        <div className='mb-3'>
                            <Label htmlFor='name' className='form-label'>
                                Name <span className='text-danger'>*</span>
                            </Label>
                            <Input
                                name='name'
                                type='text'
                                placeholder='Enter name'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name}
                                invalid={formValidation.touched.name && !!formValidation.errors.name}
                            />
                            {formValidation.touched.name && !!formValidation.errors.name && (
                                <FormFeedback type='invalid'>
                                    <div>{formValidation.errors.name}</div>
                                </FormFeedback>
                            )}
                        </div>

                        <div className='mb-3'>
                            <Label htmlFor='instructions' className='form-label'>
                                Instructions <span className='text-danger'>*</span>
                            </Label>
                            <p className='text-muted'>
                                You can add all the necessary instrucction for all AI Assistant, for example request
                                user information or validate questions that it may received and how to answer those
                                questions.
                            </p>
                            <Input
                                name='instructions'
                                type='textarea'
                                value={formValidation.values.instructions || ''}
                                className='form-control'
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                rows={10}
                                invalid={formValidation.touched.instructions && !!formValidation.errors.instructions}
                            />
                            {formValidation.touched.instructions && !!formValidation.errors.instructions && (
                                <FormFeedback type='invalid'>{formValidation.errors.instructions}</FormFeedback>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <FormButton
                        value='Save changes'
                        loading={loading}
                        fitParent={false}
                        type='submit'
                        form='edit-form'
                    />
                    <FormButton value='Cancel' color='light' onClick={onHide} fitParent={false} />
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default BotsPromptsAddEditModal;
