import IBotCategory from '@src/interfaces/IBotCategory';
import IRole from '@src/interfaces/IRole';
import IUser from '@src/interfaces/IUser';
import { getBotCategories } from '@src/services/BotCategoryService';
import { getRoles } from '@src/services/RoleService';
import { deleteUser, getUsers, resendVerificationEmail, updateUser } from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { errorToast, sucessToast } from '@src/utils/helpers';
import { registerInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const useAdminClientsList = () => {
    const navigate = useNavigate();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [clients, setClients] = useState<Array<IUser>>([]);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [categories, setCategories] = useState<Array<IBotCategory>>([]);
    const [selectedClient, setSelectedClient] = useState<IUser>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            phone: Yup.string().required('Please enter your phone'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter your email'),
            fullAddress: Yup.string().required(globals.invalidAddressMsg),
            address: Yup.string().required(globals.invalidAddressMsg),
            city: Yup.string().required(globals.invalidAddressMsg),
            state: Yup.string().required(globals.invalidAddressMsg),
            zipcode: Yup.string().required(globals.invalidAddressMsg),
            category: Yup.object<IBotCategory>().required('Please select a category')
        }),
        onSubmit: async (values: IUser) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            const { success, message } = await updateUser(values);
            if (success) {
                await getData();
                formValidation.resetForm();
                sucessToast('User information was saved!');
            } else {
                errorToast(message);
            }
            setLoading(false);
            setShowEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const responses = await Promise.all([getUsers(), getRoles(), getBotCategories()]);
        const userResponse = responses[0];
        const rolesResponse = responses[1];
        const categoriesResponse = responses[2];

        try {
            if (userResponse.success) {
                setClients(userResponse.data);
            } else errorToast(userResponse.message);

            if (rolesResponse.success) {
                setRoles(rolesResponse.data);
            } else errorToast(rolesResponse.message);

            if (categoriesResponse.success) {
                setCategories(categoriesResponse.data);
            } else errorToast(categoriesResponse.message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (user: IUser) => {
        navigate(RoutesName.adminClientsInfo.replace(':userId', user.userId!), { state: { user } });
    };

    const onResendVerificationClick = async (user: IUser) => {
        setLoading(true);
        const { success, message } = await resendVerificationEmail(user.email);

        if (success) {
            await getData();
            sucessToast(message);
        } else errorToast(message);
        setLoading(false);
    };

    const onEditClick = (user: IUser) => {
        setSelectedClient(user);
        formValidation.setValues(_.cloneDeep(user));
        setShowEditModal(true);
    };

    const onDeleteClick = (user: IUser) => {
        setSelectedClient(user);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteUser(selectedClient?.userId!);

        if (success) {
            await getData();
            sucessToast('User deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedClient(undefined);
    };

    return {
        clients,
        loading,
        selectedClient,
        showInfoModal,
        showEditModal,
        showDeleteModal,
        formValidation,
        roles,
        categories,
        setShowInfoModal,
        setShowEditModal,
        setShowDeleteModal,
        onInfoClick,
        onResendVerificationClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminClientsList;
