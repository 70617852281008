import logoLight from '@src/assets/images/logo_connections.png';
import { RoutesName } from '@src/utils/enums';
import 'cleave.js/dist/addons/cleave-phone.in';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import useEmailVerification from './useEmailVerification';

const EmailVerification = () => {
    const { loading, loggedUser, logout, handleResendEmailCode, navigate } = useEmailVerification();

    document.title = 'Verify email | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center text-white-50'>
                        <div>
                            <Link to='/' className='d-inline-block auth-logo'>
                                <img src={logoLight} alt='' height='100' />
                            </Link>
                        </div>
                        <p className='mt-3 fs-16 fw-semibold'>Create your own assistant for your business!</p>
                    </div>
                </Col>
            </Row>
            <div id='request-otp'></div>

            <Row className='justify-content-center'>
                <Col md={8} lg={6} xl={5} className='me-5'>
                    <Card className='mt-4 pt-0 pb-0'>
                        <CardBody className='p-4 mt-2'>
                            <div className='mb-4'>
                                <div className='avatar-lg mx-auto'>
                                    <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
                                        <i className='ri-mail-line'></i>
                                    </div>
                                </div>
                            </div>

                            <div className='p-2 mt-4'>
                                <div className='text-muted text-center mb-4 mx-lg-3'>
                                    <h4>Verify Your Email</h4>
                                    <p>
                                        We sent to you a link to{' '}
                                        <span className='fw-semibold'>{loggedUser?.email}</span> to complete your email
                                        verification.
                                    </p>
                                </div>

                                <div className='mt-3 text-center'>
                                    <p className='mb-0'>
                                        Didn't receive the email?
                                        <Link
                                            to='#'
                                            onClick={loading ? undefined : handleResendEmailCode}
                                            className='fw-semibold text-primary text-decoration-underline ms-2'
                                        >
                                            Resend {loading && <Spinner size='sm' className='ms-2'></Spinner>}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <div className='mt-4 text-center'>
                <p className='mb-0'>
                    Do you want to use another account?{' '}
                    <Link
                        to={RoutesName.root}
                        onClick={() => logout(navigate)}
                        className='fw-semibold text-primary text-decoration-underline'
                    >
                        Login
                    </Link>
                </p>
            </div>
        </>
    );
};

export default EmailVerification;
