import IAutoCharge from '@src/interfaces/IAutoCharge';
import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createAutoCharge = async (autoCharge: IAutoCharge) => {
    const response = { data: {} as IAutoCharge, success: false, message: '' };

    try {
        const endPoint = '/autoCharges/create';
        const formatApiData = objToSnake(autoCharge);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IAutoCharge;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getAutoCharge = async (autoChargeId: string) => {
    const response = { data: {} as IAutoCharge, success: false, message: '' };

    try {
        const endPoint = `/autoCharges/${autoChargeId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IAutoCharge;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getAutoChargeByUser = async (userId: string) => {
    const response = { data: {} as IAutoCharge, success: false, message: '' };

    try {
        const endPoint = `/autoCharges/user/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IAutoCharge;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getAutoCharges = async () => {
    const response = { data: [] as Array<IAutoCharge>, success: false, message: '' };

    try {
        const endPoint = `/autoCharges`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IAutoCharge);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateAutoCharge = async (autoCharge: IAutoCharge) => {
    const response = { data: {} as IAutoCharge, success: false, message: '' };

    try {
        const endPoint = `/autoCharges/update/${autoCharge.autoChargeId}`;
        const formatApiData = objToSnake(autoCharge);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IAutoCharge;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteAutoCharge = async (autoChargeId: string) => {
    const response = { data: {} as IAutoCharge, success: false, message: '' };

    try {
        const endPoint = `/autoCharges/${autoChargeId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IAutoCharge;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
