import 'cleave.js/dist/addons/cleave-phone.in';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Formik validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

import IUser from '@src/interfaces/IUser';
import { auth } from '@src/services/Firebase';
import {
    getPublicUser,
    logout,
    resendEmailCode,
    resendPhoneCode,
    updatePublicUser,
    validatePhoneCode
} from '@src/services/UserService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { encryptData, errorToast, getLoggedUser, isEmailVerified, sucessToast } from '@src/utils/helpers';
import { registrationVerificationInitValues } from '@src/utils/initValues';

const useRegistrationVerification = () => {
    const sessionUser = getLoggedUser();
    const setGlobalLoading = globalStore((state) => state.setLoading);

    const [loggedUser, setLoggedUser] = useState<IUser>(sessionUser);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [isEmailAlreadyVerified, setIsEmailAlreadyVerified] = useState(false);
    const navigate = useNavigate();

    let emailCheckInterval: NodeJS.Timer;

    const formValidation = useFormik({
        initialValues: registrationVerificationInitValues,
        validationSchema: Yup.object({
            digit1: Yup.string().required(),
            digit2: Yup.string().required(),
            digit3: Yup.string().required(),
            digit4: Yup.string().required()
        }),
        onSubmit: async (values) => {
            console.log('submit', values);
            setLoading(true);
            setErrorMsg('');

            try {
                const code = values.digit1 + values.digit2 + values.digit3 + values.digit4;
                const { data, success } = await validatePhoneCode(loggedUser.token!, code);
                if (success && data) {
                    loggedUser.isVerified = true;
                    const { data, success, message } = await updatePublicUser(loggedUser);
                    if (success) {
                        await auth.currentUser?.reload();
                        data.token = loggedUser.token;
                        localStorage.setItem(globals.userSessionKey, encryptData(data));

                        if (auth.currentUser?.emailVerified) {
                            navigate(RoutesName.dashboard, { replace: true });
                            sucessToast('Verification completed. Welcome to Connections!');
                        } else sucessToast('Phone Verification completed!');
                    } else {
                        setErrorMsg(message);
                    }
                } else {
                    setErrorMsg('Invalid code.');
                }
            } catch (error: any) {
                setErrorMsg('There was an error, please try again');
            }

            setLoading(false);
        }
    });

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if ((await isEmailVerified()) && loggedUser.isVerified) {
            navigate(RoutesName.dashboard, { replace: true });
            return;
        }

        console.log('GETTING DATA on INIT.....');
        setGlobalLoading(true);
        if (!loggedUser.userId || loggedUser.isAdmin) navigate(RoutesName.login, { replace: true });

        const { success, message, data } = await getPublicUser(loggedUser.userId!);

        if (success) {
            await auth.currentUser?.reload();
            data.token = loggedUser.token;
            setLoggedUser(data);
            localStorage.setItem(globals.userSessionKey, encryptData(data));

            await auth.currentUser?.reload();
            if ((await isEmailVerified()) && data.isVerified) {
                navigate(RoutesName.dashboard, { replace: true });
            }
            if (!auth.currentUser?.emailVerified) {
                createEmailCheckInterval();
            }
        } else {
            if (loggedUser.userId) errorToast(message);
        }

        if (!auth.currentUser) navigate(RoutesName.login, { replace: true });

        setGlobalLoading(false);
    };

    const createEmailCheckInterval = () => {
        clearInterval(emailCheckInterval);
        emailCheckInterval = setInterval(async () => {
            await auth.currentUser?.reload();
            if (auth.currentUser?.emailVerified) {
                clearInterval(emailCheckInterval);
                const storedLoggedUser = getLoggedUser();
                setIsEmailAlreadyVerified(true);
                setLoggedUser({ ...storedLoggedUser });
                localStorage.setItem(
                    globals.userSessionKey,
                    encryptData({ ...storedLoggedUser, isEmailVerified: true })
                );
                if (storedLoggedUser.isVerified) {
                    navigate(RoutesName.dashboard, { replace: true });
                    sucessToast('Verification completed. Welcome to Connections!');
                } else sucessToast('Email Verification completed!');
            }
        }, 1000);
    };

    const getInputElement = (index: number) => {
        return document.getElementById('digit' + index) as HTMLInputElement;
    };

    const moveToNext = (index: number) => {
        console.log(index);
        if (index <= 4) {
            getInputElement(index).focus();
        }
    };

    const onDigitChange = (e: React.ChangeEvent<HTMLInputElement>, nextIndex: number) => {
        formValidation.handleChange(e);
        if (e.target.value) moveToNext(nextIndex);
    };

    const handleResendEmailCode = async () => {
        setLoading(true);
        const { success, message } = await resendEmailCode(loggedUser.userId!);
        if (success) {
            sucessToast('Verification email was sent successfully!');
            createEmailCheckInterval();
        } else {
            errorToast(message);
        }
        setLoading(false);
    };

    const handleResendPhoneCode = async () => {
        setLoading(true);
        try {
            const { success, message } = await resendPhoneCode(loggedUser.token);
            if (success) {
                sucessToast('Phone code verification was sent successfully!');
                createEmailCheckInterval();
            } else {
                errorToast(message);
            }
        } catch (error: any) {
            setErrorMsg(error.message);
        }
        setLoading(false);
    };

    return {
        formValidation,
        errorMsg,
        loading,
        loggedUser,
        isEmailAlreadyVerified,
        logout,
        onDigitChange,
        handleResendEmailCode,
        handleResendPhoneCode,
        navigate
    };
};

export default useRegistrationVerification;
