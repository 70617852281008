import React from 'react';
import { getChartColorsArray } from '@src/utils/helpers';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface ILineChart {
    title: string;
    colors: Array<string>;
    series: Array<{ name: string; data: Array<number> }>;
    xaxis: Array<string>;
    height?: number;
}

const LineChart: React.FC<ILineChart> = ({ title, colors, series, xaxis, height = 300 }) => {
    const linechartBasicColors = getChartColorsArray(colors);

    const options: ApexOptions = {
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 4
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: linechartBasicColors,
        title: {
            text: title,
            align: 'left',
            style: {
                fontWeight: 500
            }
        },

        xaxis: {
            categories: xaxis
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                dir='ltr'
                options={options}
                series={series}
                type='line'
                className='apex-charts'
                height={height}
            />
        </React.Fragment>
    );
};

export default LineChart;
