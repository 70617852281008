import React from 'react';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import { CustomTable } from '@src/components/CustomTable';
import IBot from '@src/interfaces/IBot';
import IDocumentFile from '@src/interfaces/IDocumentFile';
import { globals } from '@src/utils/constants';
import { RoutesName } from '@src/utils/enums';
import { formatString } from '@src/utils/helpers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import useAdminBotsList from './useAdminBotsList';

const AdminBotsList = () => {
    document.title = 'AI Assistant List | Connections';

    const { bots } = useAdminBotsList();

    const columnHelper = createColumnHelper<IBot>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        columnHelper.display({
            header: 'Name',
            cell: ({ row }) => (
                <div className='d-flex align-items-center'>
                    <div className='avatar-sm h-auto'>
                        <img
                            src={row.original.avatar || userDummayImage}
                            alt='bot-avatar'
                            className='img-thumbnail rounded-circle'
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = userDummayImage;
                            }}
                        />
                    </div>
                    <div className='w-100 ms-2 me-4'>
                        <span className='text-body'>{row.original.name}</span>
                    </div>
                </div>
            )
        }),
        columnHelper.display({
            header: 'Status',
            cell: ({ row }) => (
                <div className='d-flex align-items-center ms-2'>
                    <Badge
                        color={
                            row.original.temporaryDisabled
                                ? 'warning'
                                : row.original.isSubscriptionActive
                                ? 'success'
                                : 'danger'
                        }
                    >
                        {row.original.temporaryDisabled
                            ? ' Disabled'
                            : row.original.isSubscriptionActive
                            ? 'active'
                            : 'inactive'}
                    </Badge>
                </div>
            )
        }),

        {
            header: 'User',
            accessorKey: 'userId',
            cell: (value) => (
                <Link
                    to={RoutesName.adminClientsInfo.replace(':userId', value.getValue()?.userId!)}
                    target='_blank'
                    className='text-primary d-inline-block'
                >
                    {value.getValue()?.name}
                </Link>
            )
        },
        {
            header: 'Documents',
            accessorKey: 'documents',
            cell: (value) => (
                <>
                    {value.getValue()?.length === 0 && <span className='fs-11'>No documents</span>}
                    {value.getValue()?.map((doc: IDocumentFile, idx: number) => (
                        <Link to={doc.url} key={'card-doc-' + idx} target='_blank'>
                            <span className='badge rounded-pill border border-primary text-primary me-1'>
                                doc-{idx + 1}
                            </span>
                        </Link>
                    ))}
                </>
            )
        },
        {
            header: 'Instructions',
            accessorKey: 'instructions',
            cell: (value) => <span className='text-truncate-cell'>{value.getValue()}</span>
        },
        {
            header: 'Created At',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        columnHelper.display({
            header: 'Actions',
            size: 60,
            cell: ({ row }) => (
                <ul className='list-inline hstack mb-0'>
                    <li className='list-inline-item'>
                        <Link to={RoutesName.adminBotMessages.replace(':botId', row.original.botId!)} target='_blank'>
                            <Button color='primary' className='btn-icon' outline>
                                <i className='ri-message-2-line' />
                            </Button>
                        </Link>
                    </li>
                    <li className='list-inline-item'>
                        <Link to={formatString(globals.fullScreenChatUrl, row.original.botId)} target='_blank'>
                            <Button color='primary' className='btn-icon' outline>
                                <i className='ri-external-link-line' />{' '}
                            </Button>
                        </Link>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>AI Assistant List</h2>
                                </div>

                                <CustomTable data={bots} columns={columns} className='mt-6' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdminBotsList;
