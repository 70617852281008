import HttpService from '@src/utils/HttpService';
import IMunicipality from '@src/interfaces/IMunicipality';
import { objToCamel, objToSnake } from '@src/utils/helpers';
import { IObj } from '@src/interfaces/IGeneric';

export const createMunicipality = async (municipality: IMunicipality) => {
    const response = { data: {} as IMunicipality, success: false, message: '' };

    try {
        const endPoint = '/municipalities/create';
        const formatApiData = objToSnake(municipality) as IObj;
        console.log('data sent', formatApiData);
        const formData = objToFormData(formatApiData);
        console.log('formData', formData);

        const { data } = await HttpService.post(endPoint, formData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IMunicipality;
        response.success = true;
    } catch (error: any) {
        console.log(error);
        console.log('Array.isArray(error)', Array.isArray(error));
        if (Array.isArray(error)) {
            response.message = error[0].msg;
        } else response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMunicipality = async (stateId: string, municipalityId: string) => {
    const response = { data: {} as IMunicipality, success: false, message: '' };

    try {
        const endPoint = `/municipalities/${stateId}/${municipalityId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IMunicipality;
        console.log('formatMunicipalityApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMunicipalities = async () => {
    const response = { data: [] as Array<IMunicipality>, success: false, message: '' };

    try {
        const endPoint = `/municipalities`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IMunicipality);
        console.log('formatMunicipalitysApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateMunicipality = async (municipality: IMunicipality) => {
    console.log('update muni');
    const response = { data: {} as IMunicipality, success: false, message: '' };

    try {
        const endPoint = `/municipalities/update/${municipality.muniId}`;
        console.log('municipality', municipality);
        const formatApiData = objToSnake(municipality) as IObj;
        console.log('data sent', formatApiData);
        const formData = objToFormData(formatApiData);
        console.log('formData', formData);

        const { data } = await HttpService.put(endPoint, formData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IMunicipality;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteMunicipality = async (stateId: string, municipalityId: string) => {
    const response = { data: {} as IMunicipality, success: false, message: '' };

    try {
        const endPoint = `/municipalities/${stateId}/${municipalityId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IMunicipality;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

const objToFormData = (objData: IObj) => {
    const formData = new FormData();

    Object.entries(objData).forEach(([key, value]) => {
        if (key == 'documents') return; //This only are the urls when is already uploaded

        if (Array.isArray(value)) {
            value?.forEach((item: any) => formData.append(key.replace('_inputs', ''), item));
        } else formData.append(key, value);
    });

    return formData;
};
