import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import IStripeUser from '@src/interfaces/IStripeUser';
import IUser from '@src/interfaces/IUser';
import IStripeProduct from '@src/interfaces/IStripeProduct';
import IStripeSubscription from '@src/interfaces/IStripeSubscription';
import IStripePayment from '@src/interfaces/IStripePayment';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import IBot from '@src/interfaces/IBot';

export const createSetupIntent = async (customerId: string) => {
    const response = { data: '', success: false, message: '' };

    try {
        const endPoint = '/payments/createSetupIntent';
        const formatApiData = objToSnake({ customerId });
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const createPaymentIntent = async (customerId: string, amount: number, description: string) => {
    const response = { data: '', success: false, message: '' };

    try {
        const endPoint = '/payments/createPaymentIntent';
        const formatApiData = objToSnake({ customerId, amount, description });
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const addFreeBalance = async (userId: string, amount: number) => {
    const response = { data: {} as IUser, success: false, message: '' };

    try {
        const endPoint = '/payments/addFreeBalance';
        const formatApiData = objToSnake({ userId, amount });
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel<IUser>(data);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getPaymentMethods = async (customerId: string) => {
    const response = { data: [] as Array<IStripePaymentMethod>, success: false, message: '' };

    try {
        const endPoint = `/payments/paymentMethods/${customerId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<IStripePaymentMethod>;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getStripeUser = async (customerId: string) => {
    const response = { data: {} as IStripeUser, success: false, message: '' };

    try {
        const endPoint = `/users/${customerId}/stripe`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel<IStripeUser>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getStripeProduct = async (productId: string) => {
    const response = { data: {} as IStripeProduct, success: false, message: '' };

    try {
        const endPoint = `/payments/product/${productId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel<IStripeProduct>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getSubscriptionProducts = async () => {
    const response = { data: [] as Array<ISubscriptionProduct>, success: false, message: '' };

    try {
        const endPoint = `/subscriptions/products`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<ISubscriptionProduct>;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getPayments = async (user: IUser) => {
    const response = { data: [] as Array<IStripePayment>, success: false, message: '' };

    try {
        const endPoint = `/payments/${user.userId}/${user.stripeCustomerId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<IStripePayment>;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getSubscriptions = async (userId: string) => {
    const response = { data: [] as Array<ISubscriptionProduct>, success: false, message: '' };

    try {
        const endPoint = `/subscriptions/user/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<ISubscriptionProduct>;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const createStripeUser = async (user: IUser) => {
    const response = { data: {} as IStripeUser, success: false, message: '' };
    const formatApiData = objToSnake(user);

    try {
        const endPoint = `/users/create/${user.userId}/stripe`;

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel<IStripeUser>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const createStripeSubscription = async (customerId: string, priceId: string, botName: string) => {
    const response = { data: {} as IStripeSubscription, success: false, message: '' };
    const formatApiData = objToSnake({ priceId, botName });

    try {
        const endPoint = `/payments/createSubscription/${customerId}`;

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel<IStripeSubscription>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const createSubscription = async (user: IUser, subscriptionProduct: ISubscriptionProduct, bot: IBot) => {
    const response = { data: {} as IStripeSubscription, success: false, message: '' };
    const formatApiData = objToSnake({ user, subscriptionProduct, bot });

    try {
        const endPoint = `/subscriptions/user/create`;

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel<IStripeSubscription>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateDefaultPaymentMethod = async (customerId: string, paymentMethodId: string) => {
    const response = { data: '', success: false, message: '' };

    try {
        const endPoint = '/payments/setDefaultPaymentMethod';
        const formatApiData = objToSnake({ customerId, paymentMethodId });
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        console.log('data', data);
        response.data = data;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const activateSubscription = async (subscriptionId: string) => {
    const response = { data: {} as IStripeSubscription, success: false, message: '' };

    try {
        const endPoint = `/payments/subscription/${subscriptionId}`;
        const { data } = await HttpService.post(endPoint);
        const formatApiResponse = objToCamel<IStripeSubscription>(data);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deletePaymentMethods = async (pmId: string) => {
    const response = { data: {} as IStripePayment, success: false, message: '' };

    try {
        const endPoint = `/payments/paymentMethod/${pmId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel<IStripePayment>(data);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const cancelSubscription = async (subscriptionId: string) => {
    const response = { data: {} as IStripeSubscription, success: false, message: '' };

    try {
        const endPoint = `/subscriptions/user/cancel/${subscriptionId}`;
        const { data } = await HttpService.post(endPoint);
        const formatApiResponse = objToCamel<IStripeSubscription>(data);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const revertCancelSubscription = async (subscriptionId: string) => {
    const response = { data: {} as IStripeSubscription, success: false, message: '' };

    try {
        const endPoint = `/subscriptions/user/revertCancel/${subscriptionId}`;
        const { data } = await HttpService.post(endPoint);
        const formatApiResponse = objToCamel<IStripeSubscription>(data);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
