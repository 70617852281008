import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { municipalityInitValues } from '@src/utils/initValues';
import IMunicipality from '@src/interfaces/IMunicipality';
import {
    createMunicipality,
    deleteMunicipality,
    getMunicipalities,
    getMunicipality,
    updateMunicipality
} from '@src/services/MunicipalityService';
import { useParams } from 'react-router-dom';
import { getStates } from '@src/services/StateService';
import IState from '@src/interfaces/IState';

const useAdminMunicipalitiesList = () => {
    const { stateId: filteredStateId, municipalityId: filteredMunicipalityId } = useParams();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [municipalities, setMunicipalities] = useState<Array<IMunicipality>>([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState<IMunicipality>();
    const [states, setStates] = useState<Array<IState>>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: municipalityInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            isoCode: Yup.string().required('Please enter the ISO code'),
            stateId: Yup.string().required('Please enter the state')
        }),
        onSubmit: async (values: IMunicipality, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);
            values.documentsInputs = values.documentsInputs?.filter((d) => d);
            let response;
            if (values.muniId) response = await updateMunicipality(values);
            else response = await createMunicipality(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast(' Municipality information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, [filteredMunicipalityId]);

    const getData = async () => {
        setLoading(true);

        const promises: Array<any> = [getStates()];

        if (filteredStateId && filteredMunicipalityId)
            promises.push(getMunicipality(filteredStateId, filteredMunicipalityId));
        else promises.push(getMunicipalities());

        const responses = await Promise.all(promises);
        const stateResponse = responses[0];
        const municipalities = responses[1];

        try {
            if (municipalities.success) {
                setMunicipalities(Array.isArray(municipalities.data) ? municipalities.data : [municipalities.data]);
            } else errorToast(municipalities.message);

            if (stateResponse.success) {
                setStates(stateResponse.data);
            } else errorToast(municipalities.message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (municipality: IMunicipality) => {
        setSelectedMunicipality(municipality);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedMunicipality(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (municipality: IMunicipality) => {
        setSelectedMunicipality(municipality);
        formValidation.setValues({ ...municipality });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (municipality: IMunicipality) => {
        setSelectedMunicipality(municipality);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteMunicipality(
            selectedMunicipality?.stateId!,
            selectedMunicipality?.muniId!
        );

        if (success) {
            await getData();
            sucessToast(' Municipality deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedMunicipality(undefined);
    };

    return {
        municipalities,
        loading,
        selectedMunicipality,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        filteredMunicipalityId,
        states,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminMunicipalitiesList;
