import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Formik formValidation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import IGooglePlace from '@src/interfaces/IGooglePlace';
import { globals } from '@src/utils/constants';
import { getUser, login, updateUser } from '@src/services/UserService';
import IUser from '@src/interfaces/IUser';
import { registerInitValues } from '@src/utils/initValues';
import globalStore from '@src/stores/globalStore';
import { encryptData, errorToast, getLoggedUser, splitAddress, sucessToast } from '@src/utils/helpers';
const useProfile = () => {
    let loggedUser = getLoggedUser();
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [activeTab, setActiveTab] = useState(1);
    const [forceRefresh, setForceRefresh] = useState(false);
    const navigate = useNavigate();

    const formInfoValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            phone: Yup.string().required('Please enter your phone'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter your email'),
            fullAddress: Yup.string().required(globals.invalidAddressMsg),
            address: Yup.string().required(globals.invalidAddressMsg),
            city: Yup.string().required(globals.invalidAddressMsg),
            state: Yup.string().required(globals.invalidAddressMsg),
            zipcode: Yup.string().required(globals.invalidAddressMsg)
        }),
        onSubmit: async (values: IUser) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            // if (loggedUser.email !== values.email) {
            //     clearInterval(emailCheckInterval);
            //     emailCheckInterval = setInterval(async ()=>{
            //         await auth.currentUser?.reload();
            // if (auth.currentUser?.emailVerified) {
            //     clearInterval(emailCheckInterval);
            // }
            //     }, 1000)
            // } else if (loggedUser.phone !== values.phone) {
            // } else await updateUserInfo(values);

            await updateUserInfo(values);
            setLoading(false);
        }
    });

    const updateUserInfo = async (user: IUser) => {
        const { data, success, message } = await updateUser(user);
        if (success) {
            localStorage.setItem(globals.userSessionKey, encryptData(data));
            loggedUser = data;
            sucessToast('Your information was saved!');
        } else {
            errorToast(message);
        }
    };

    const formPasswordValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            currentPassword: Yup.string().required('Current Password is required'),
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required('Confirm password is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            try {
                const { success, message } = await login(loggedUser.email, values.currentPassword);
                if (success) {
                    const { success, message } = await updateUser({ ...loggedUser, password: values.password });
                    if (success) {
                        sucessToast('Your password was changed!');
                        resetForm();
                    } else {
                        errorToast(message);
                    }
                } else {
                    if (message.includes('Invalid')) errorToast('Invalid current password');
                    errorToast(message);
                }
            } catch (error: any) {
                errorToast(error.message);
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    const getData = async () => {
        setLoading(true);

        try {
            const { success, data, message } = await getUser(loggedUser.userId!);

            if (success) {
                formInfoValidation.setValues({ ...data });
            } else {
                errorToast(message);
            }
        } catch (error) {}
        setLoading(false);
    };

    const tabChange = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const validateAddressField = () => {
        //We only evaluate the zip code, because if theres no zipcode, it means the rest is not there
        return (
            !formInfoValidation.touched.address ||
            (!formInfoValidation.errors.fullAddress && !formInfoValidation.errors.zipcode)
        );
    };

    const onAddressSelected = (place: IGooglePlace) => {
        const addressFields = splitAddress(place);
        formInfoValidation.setFieldValue('address', addressFields?.address);
        formInfoValidation.setFieldValue('city', addressFields?.city);
        formInfoValidation.setFieldValue('state', addressFields?.state);
        formInfoValidation.setFieldValue('zipcode', addressFields?.zipcode);

        setForceRefresh(true);
    };

    const onAddressChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        formInfoValidation.setFieldValue('fullAddress', e.target.value);
        //If user types the address without selecting it, we remove the zip to trigger the validation error
        formInfoValidation.setFieldValue('zipcode', '');

        setForceRefresh(true);
    };

    return {
        formInfoValidation,
        formPasswordValidation,
        loading,
        activeTab,
        validateAddressField,
        onAddressSelected,
        onAddressChanged,
        tabChange,
        navigate
    };
};

export default useProfile;
