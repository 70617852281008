import Loader from '@src/components/Loader';
import globalStore from '@src/stores/globalStore';
import { RoutesName } from '@src/utils/enums';
import { getLoggedUser } from '@src/utils/helpers';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Navigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ClientSideBar } from './ClientSideBar';
import Header from './Header';

const Protected = (props: any) => {
    const loading = globalStore((state) => state.loading);
    const loggedUser = getLoggedUser();
    const location = useLocation();

    if (!loggedUser?.userId) {
        return <Navigate to={{ pathname: RoutesName.root }} replace />;
    }
    if (!loggedUser.questionanaire && location.pathname !== RoutesName.questionnaire) {
        return <Navigate to={{ pathname: RoutesName.questionnaire }} replace />;
    }

    const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

    return (
        <div className='main-content'>
            <Header headerClass={'topbar-shadow'} />
            <ClientSideBar />
            <Loader show={loading} />
            <div className='page-content'>
                <Elements stripe={stripe}>
                    <Container fluid>{props.children}</Container>
                </Elements>
            </div>
        </div>
    );
};

export default Protected;
