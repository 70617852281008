import React from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';

import profileBg from '@src/assets/images/bg-abstract.jpg';
import { CustomTable } from '@src/components/CustomTable';
import { FormButton } from '@src/components/FormButton';
import OkCancelModal from '@src/components/OkCancelModal';
import IBotCategory from '@src/interfaces/IBotCategory';
import { RoutesName } from '@src/utils/enums';
import { copyText } from '@src/utils/helpers';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import 'cleave.js/dist/addons/cleave-phone.in';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import BotCategoryAddEditModal from './partials/BotCategoryAddEditModal';
import BotCategoryInfoModal from './partials/BotCategoryInfoModal';
import useAdminBotsCategories from './useAdminBotsCategories';

const AdminBotsCategories = () => {
    document.title = 'AI Assistant Categories | Connections';

    const {
        categories,
        selectedCategory,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        loading,
        formValidation,
        filteredCategoryId,
        setShowInfoModal,
        onAddClick,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    } = useAdminBotsCategories();

    const columnHelper = createColumnHelper<IBotCategory>();

    const columns: Array<ColumnDef<any, any>> = [
        {
            header: '#',
            accessorFn: (_, idx) => idx + 1
        },
        columnHelper.display({
            header: 'Id',
            cell: ({ row }) => (
                <Link to='#' onClick={() => copyText(row.original.botCategoryId)} className='me-1'>
                    {row.original.botCategoryId}
                </Link>
            )
        }),
        {
            header: 'Name',
            accessorKey: 'name'
        },
        {
            header: 'Description',
            accessorKey: 'description',
            size: 200,
            cell: (value) => <span className='text-truncate-cell'>{value.getValue()}</span>
        },
        columnHelper.display({
            header: 'Sub Categories',
            size: 200,
            cell: ({ row }) => (
                <div style={{ maxWidth: 200, overflowX: 'auto' }}>
                    {row.original.botSubcategories?.map((subCat) => (
                        <Link
                            key={subCat.botSubCategoryId}
                            to={`${RoutesName.adminBotSubCategories}/${row.original.botCategoryId}/${subCat.botSubCategoryId}`}
                            className='me-1'
                        >
                            <Badge color='primary' pill>
                                {subCat.name}
                            </Badge>
                        </Link>
                    ))}
                </div>
            )
        }),
        {
            header: 'Created At',
            accessorKey: 'createdAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        {
            header: 'Updated At',
            accessorKey: 'updatedAt',
            cell: (value) => moment(value.getValue()).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A (z)')
        },
        columnHelper.display({
            header: 'Actions',
            size: 100,
            cell: ({ row }) => (
                <ul className='list-inline hstack gap-2 mb-0'>
                    <li className='list-inline-item'>
                        <Link to='#' onClick={() => onInfoClick(row.original)} className='text-primary d-inline-block'>
                            <i className='ri-eye-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item edit'>
                        <Link
                            to='#'
                            className='text-primary d-inline-block edit-item-btn'
                            onClick={() => onEditClick(row.original)}
                        >
                            <i className='ri-pencil-fill fs-16'></i>
                        </Link>
                    </li>
                    <li className='list-inline-item'>
                        <Link
                            to='#'
                            className='text-danger d-inline-block remove-item-btn'
                            onClick={() => onDeleteClick(row.original)}
                        >
                            <i className='ri-delete-bin-5-fill fs-16'></i>
                        </Link>
                    </li>
                </ul>
            )
        })
    ];

    return (
        <React.Fragment>
            <div className='profile-foreground position-relative mx-n4 mt-n4'>
                <div className='profile-wid-bg'>
                    <img src={profileBg} alt='' className='profile-wid-img' />
                </div>
            </div>
            <Row className='g-4'>
                <Col lg={12}>
                    <div className='pt-4'>
                        <Card className='ribbon-box border shadow-none mb-lg-0'>
                            <CardBody>
                                <div className='ribbon ribbon-primary round-shape mt-3'>
                                    <h2 className='text-white mb-1'>AI Assistant Categories</h2>
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <div className='flex-shrink-0'>
                                        <FormButton onClick={onAddClick}>
                                            <i className='ri-add-line align-bottom'></i> Add New
                                        </FormButton>
                                    </div>
                                </div>
                                {!!filteredCategoryId && categories.length && (
                                    <div className='mt-2'>
                                        <Link to={RoutesName.adminBotCategories}>
                                            <span className='badge rounded-pill border border-primary text-primary d-inline-flex align-items-center'>
                                                {categories[0].name} <i className='ri-close-circle-line fs-15 ms-1'></i>
                                            </span>
                                        </Link>
                                    </div>
                                )}
                                <CustomTable data={categories} columns={columns} className='mt-3' />
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <BotCategoryInfoModal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                selectedItem={selectedCategory}
            />

            <BotCategoryAddEditModal
                show={showAddEditModal}
                onHide={() => setShowAddEditModal(false)}
                loading={loading}
                formValidation={formValidation}
            />

            <OkCancelModal
                title={`Are you sure you want to delete: <br /><b>${selectedCategory?.name}</b>?`}
                subTitle="If you continue, you won't be able to recover the information of this record."
                show={showDeleteModal}
                onAccept={handleDelete}
                yesText="Yes, I'm sure"
                onCancel={() => setShowDeleteModal(false)}
                cancelText='Cancel'
                loading={loading}
            />
        </React.Fragment>
    );
};

export default AdminBotsCategories;
