import { IMunicipalityRequest } from '@src/interfaces/IMunicipality';
import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createMunicipalityRequest = async (request: IMunicipalityRequest) => {
    const response = { data: {} as IMunicipalityRequest, success: false, message: '' };

    try {
        const endPoint = '/municipalityRequests/create';
        const formatApiData = objToSnake(request);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IMunicipalityRequest;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMunicipalityRequest = async (municipalityRequestId: string) => {
    const response = { data: {} as IMunicipalityRequest, success: false, message: '' };

    try {
        const endPoint = `/municipalityRequests/${municipalityRequestId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IMunicipalityRequest;
        console.log('formatMunicipalityRequestApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMunicipalityRequests = async () => {
    const response = { data: [] as Array<IMunicipalityRequest>, success: false, message: '' };

    try {
        const endPoint = `/municipalityRequests`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => {
            item.state = item.state._path.segments[1];
            return objToCamel(item) as IMunicipalityRequest;
        });
        console.log('formatMunicipalityRequestsApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateMunicipalityRequest = async (municipalityRequest: IMunicipalityRequest) => {
    const response = { data: {} as IMunicipalityRequest, success: false, message: '' };

    try {
        const endPoint = `/municipalityRequests/update/${municipalityRequest.municipalityRequestId}`;
        const formatApiData = objToSnake(municipalityRequest);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IMunicipalityRequest;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteMunicipalityRequest = async (municipalityRequestId: string) => {
    const response = { data: {} as IMunicipalityRequest, success: false, message: '' };

    try {
        const endPoint = `/municipalityRequests/${municipalityRequestId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IMunicipalityRequest;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const approveMunicipalityRequest = async (municipalityRequest: IMunicipalityRequest) => {
    const response = { data: {} as IMunicipalityRequest, success: false, message: '' };

    try {
        const endPoint = `/municipalityRequests/approve/${municipalityRequest.municipalityRequestId}`;
        const formatApiData = objToSnake(municipalityRequest);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IMunicipalityRequest;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
