import ILead from '@src/interfaces/ILead';
import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createLead = async (lead: ILead) => {
    const response = { data: {} as ILead, success: false, message: '' };

    try {
        const endPoint = '/leads/create';
        const formatApiData = objToSnake(lead);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as ILead;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getLead = async (leadId: string) => {
    const response = { data: {} as ILead, success: false, message: '' };

    try {
        const endPoint = `/leads/${leadId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as ILead;
        console.log('formatLeadApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getLeads = async () => {
    const response = { data: [] as Array<ILead>, success: false, message: '' };

    try {
        const endPoint = `/leads`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as ILead);
        console.log('formatLeadsApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateLead = async (lead: ILead) => {
    const response = { data: {} as ILead, success: false, message: '' };

    try {
        const endPoint = `/leads/update/${lead.leadId}`;
        const formatApiData = objToSnake(lead);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as ILead;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteLead = async (leadId: string) => {
    const response = { data: {} as ILead, success: false, message: '' };

    try {
        const endPoint = `/leads/${leadId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as ILead;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
