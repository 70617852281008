import IAnswer from '@src/interfaces/IAnswer';
import { IObj } from '@src/interfaces/IGeneric';
import IQuestion from '@src/interfaces/IQuestion';
import IUser from '@src/interfaces/IUser';
import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';

// **************************************************************************************
// Gets
export const getQuestionnaire = async () => {
    const response = { data: [] as Array<IQuestion>, success: false, message: '' };

    try {
        const endPoint = 'questionnaire';

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel<IQuestion>(item));
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;

        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getQuestion = async (questionId: string) => {
    const response = { data: {} as IQuestion, success: false, message: '' };

    try {
        const endPoint = `/questionnaire/${questionId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel<IQuestion>(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const saveAnwers = async (user: IUser, answers: Array<IAnswer>) => {
    const response = { data: [] as Array<IAnswer>, success: false, message: '' };

    try {
        const endPoint = `/questionnaire/answer/${user.userId}`;
        const formatApiData = objToSnake({ questionnaire: answers }) as IObj;
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        const formatApiResponse = data.map((item: any) => objToCamel<IAnswer>(item));
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

// **************************************************************************************
// Posts
export const createQuestion = async (question: IQuestion) => {
    const response = { data: {} as IQuestion, success: false, message: '' };

    try {
        const endPoint = '/questionnaire/create';
        const formatApiData = objToSnake(question);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IQuestion;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

// **************************************************************************************
// Updates
export const updateQuestion = async (question: IQuestion) => {
    const response = { data: {} as IQuestion, success: false, message: '' };

    try {
        const endPoint = `/questionnaire/update/${question.questionId}`;
        const formatApiData = objToSnake(question);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IQuestion;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

// **************************************************************************************
// Delete
export const deleteQuestion = async (questionId: string) => {
    const response = { data: {} as IQuestion, success: false, message: '' };

    try {
        const endPoint = `/questionnaire/${questionId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IQuestion;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
