import animation1 from '@src/assets/animations/connections-loading1.json';
import animation2 from '@src/assets/animations/connections-loading2.json';
import { getLoggedUser } from '@src/utils/helpers';
import Lottie from 'lottie-react';
import React from 'react';

interface ILoader {
    show: boolean;
}

const Loader: React.FC<ILoader> = ({ show }) => {
    document.documentElement.setAttribute('data-page-loading', show.toString());
    const clientUser = getLoggedUser();

    if (!show) return null;

    return (
        <div
            className='d-flex justify-content-center align-items-center'
            style={{
                position: 'fixed',
                inset: 0,
                top: clientUser ? '70px' : '0px',
                backgroundColor: '#000000aa',
                zIndex: 1051
            }}
        >
            <div style={{ width: '20vh', height: '20vh' }}>
                <Lottie
                    animationData={animation1}
                    loop={true}
                    className=''
                    style={{ position: 'absolute', width: '20vh', height: '20vh' }}
                />
                <Lottie
                    animationData={animation2}
                    loop={true}
                    className=''
                    style={{ position: 'absolute', width: '20vh', height: '20vh' }}
                />
            </div>
        </div>
    );
};

export const LoaderChat: React.FC<ILoader> = ({ show }) => {
    if (!show) return null;

    return (
        <div
            style={{
                width: '100%',
                height: '70.5px',
                position: 'absolute',
                backgroundColor: '#000000aa',
                zIndex: 10,
                bottom: 0
            }}
        >
            <Lottie
                animationData={animation2}
                loop={true}
                style={{
                    position: 'absolute',
                    width: '70px',
                    height: '70px',
                    left: '50%',
                    marginLeft: '-35px'
                }}
            />
        </div>
    );
};

export default Loader;
