import { initializeApp } from 'firebase/app';
import { RecaptchaVerifier, getAuth, signInWithEmailAndPassword, signInWithPhoneNumber, signOut } from 'firebase/auth';

import { addDoc, collection, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'connections-109b4.firebaseapp.com',
    projectId: 'connections-109b4',
    storageBucket: 'connections-109b4.appspot.com',
    messagingSenderId: '893192526190',
    appId: '1:893192526190:web:f9071530be19ba31ef897f'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth();
export const db = getFirestore();

export {
    RecaptchaVerifier,
    addDoc,
    collection,
    getDocs,
    onSnapshot,
    query,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signOut,
    where
};
export default app;
