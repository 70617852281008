import IBotCategory from '@src/interfaces/IBotCategory';
import {
    createBotCategory,
    deleteBotCategory,
    getBotCategories,
    getBotCategory,
    updateBotCategory
} from '@src/services/BotCategoryService';
import globalStore from '@src/stores/globalStore';
import { errorToast, sucessToast } from '@src/utils/helpers';
import { botCategoryInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const useAdminBotsCategories = () => {
    const { categoryId: filteredCategoryId } = useParams();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [categories, setCategories] = useState<Array<IBotCategory>>([]);
    const [selectedCategory, setSelectedCategory] = useState<IBotCategory>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: botCategoryInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter the name'),
            description: Yup.string().required('Please enter the description')
        }),
        onSubmit: async (values: IBotCategory, { resetForm }) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            let response;
            if (values.botCategoryId) response = await updateBotCategory(values);
            else response = await createBotCategory(values);

            if (response.success) {
                await getData();
                resetForm();
                sucessToast('AI Assistant Category information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, [filteredCategoryId]);

    const getData = async () => {
        setLoading(true);
        let result;

        if (filteredCategoryId) result = await getBotCategory(filteredCategoryId);
        else result = await getBotCategories();

        try {
            if (result.success) {
                setCategories(Array.isArray(result.data) ? result.data : [result.data]);
            } else errorToast(result.message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (category: IBotCategory) => {
        setSelectedCategory(category);
        setShowInfoModal(true);
    };

    const onAddClick = () => {
        setSelectedCategory(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (category: IBotCategory) => {
        setSelectedCategory(category);
        formValidation.setValues({ ...category });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (category: IBotCategory) => {
        setSelectedCategory(category);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteBotCategory(selectedCategory?.botCategoryId!);

        if (success) {
            await getData();
            sucessToast('AI Assistant Category deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedCategory(undefined);
    };

    return {
        categories,
        loading,
        selectedCategory,
        showInfoModal,
        showAddEditModal,
        showDeleteModal,
        formValidation,
        filteredCategoryId,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminBotsCategories;
