import { useState } from 'react';

// Formik formValidation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { sendResetPasswordEmail } from '@src/services/UserService';
import { forgotPassInitValues } from '@src/utils/initValues';

const useForgotPassword = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const formValidation = useFormik({
        initialValues: forgotPassInitValues,
        validationSchema: Yup.object({
            email: Yup.string().email('Please enter a valid email address').required('Please Enter Your Email')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setEmailSent(false);
            setErrorMsg('');
            console.log('submit', values);

            try {
                const { success, message } = await sendResetPasswordEmail(values.email);
                if (success) {
                    formValidation.resetForm();
                    setEmailSent(true);
                } else setErrorMsg(message);
            } catch (error: any) {
                setErrorMsg(error.message);
            }
            setLoading(false);
        }
    });
    return { formValidation, errorMsg, emailSent, loading };
};

export default useForgotPassword;
