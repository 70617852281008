import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import IUser from '@src/interfaces/IUser';
import globalStore from '@src/stores/globalStore';
import { createUser, deleteUser, getUsers, resendVerificationEmail, updateUser } from '@src/services/UserService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerInitValues } from '@src/utils/initValues';

const useAdminUsersList = () => {
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [users, setUsers] = useState<Array<IUser>>([]);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: registerInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter your email')
        }),
        onSubmit: async (values: IUser) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);
            let response;
            if (values.userId) response = await updateUser(values);
            else response = await createUser(values, true);

            await getData();
            formValidation.resetForm();

            if (response.success) {
                sucessToast('User information was saved!');
            } else {
                errorToast(response.message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const { success, data, message } = await getUsers(true);

        try {
            if (success) {
                setUsers(data);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const onInfoClick = (user: IUser) => {
        setSelectedUser(user);
        setShowInfoModal(true);
    };

    const onResendClick = async (user: IUser) => {
        setLoading(true);
        const { success, message } = await resendVerificationEmail(user.email);

        if (success) {
            await getData();
            sucessToast(message);
        } else errorToast(message);
        setLoading(false);
    };

    const onAddClick = () => {
        setSelectedUser(undefined);
        formValidation.resetForm();
        setShowAddEditModal(true);
    };

    const onEditClick = (user: IUser) => {
        setSelectedUser(user);
        formValidation.setValues({ ...user });
        setShowAddEditModal(true);
    };

    const onDeleteClick = (user: IUser) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteUser(selectedUser?.userId!);

        if (success) {
            await getData();
            sucessToast('User deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedUser(undefined);
    };

    return {
        users,
        loading,
        selectedUser,
        showInfoModal,
        showEditModal,
        showDeleteModal,
        formValidation,
        setShowInfoModal,
        setShowAddEditModal,
        setShowDeleteModal,
        onInfoClick,
        onResendClick,
        onAddClick,
        onEditClick,
        onDeleteClick,
        handleDelete
    };
};

export default useAdminUsersList;
