import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';
import { MessageRole } from '@src/utils/enums';
import { getLoggedUser } from '@src/utils/helpers';
import htmlParse from 'html-react-parser';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import useMessages from './useMessages';

const Messages = () => {
    const { threads, currentThread, messages, bot, loading, setCurrentThread } = useMessages();
    const loggedUser = getLoggedUser(true);

    document.title = 'Messages | Connections';

    return (
        <div
            className='chat-wrapper d-lg-flex gap-1'
            style={{ marginTop: loggedUser.isAdmin ? 'calc(45px + 2.7rem)' : '0' }}
        >
            <div className='chat-leftsidebar h-auto'>
                <div className='px-4 pt-4 mb-4'>
                    <div className='d-flex align-items-start'>
                        <div className='flex-grow-1'>
                            <h5 className='mb-4'>Chats</h5>
                        </div>
                    </div>
                </div>

                <SimpleBar className='chat-room-list pt-3' style={{ margin: '-16px 0px 0px' }}>
                    <div className='chat-message-list'>
                        <ul className='list-unstyled chat-list chat-user-list users-list' id='userList'>
                            {!threads.length && !loading && (
                                <Alert color='warning' className='mt-4'>
                                    There are no chat threads for this bot
                                </Alert>
                            )}
                            {threads.map((chat) => (
                                <li
                                    key={chat.threadId}
                                    className={currentThread?.threadId === chat.threadId ? 'active' : ''}
                                >
                                    <Link
                                        to='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentThread(chat);
                                        }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0 chat-user-img online align-self-center me-2 ms-0'>
                                                <div className='me-1'>
                                                    <div
                                                        className={
                                                            'avatar-title rounded-circle avatar-xxs' +
                                                            (chat.lead?.name ? ' bg-success' : '')
                                                        }
                                                    >
                                                        {(chat.lead?.name || '?').charAt(0)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 overflow-hidden'>
                                                <p
                                                    className={
                                                        'text-truncate mb-0' + (chat.lead?.name ? ' fw-bold' : '')
                                                    }
                                                >
                                                    {chat.lead?.name || '<no lead>'}
                                                </p>
                                                <span
                                                    className={
                                                        'fs-10 ' +
                                                        (currentThread?.threadId === chat.threadId
                                                            ? 'text-white'
                                                            : 'text-muted')
                                                    }
                                                >
                                                    {moment(chat.updatedAt || chat.createdAt).diff(
                                                        Date.now(),
                                                        'days'
                                                    ) >= 1
                                                        ? moment(chat.updatedAt || chat.createdAt).format(
                                                              'MM/DD/YY hh:mm A'
                                                          )
                                                        : moment(chat.updatedAt || chat.createdAt).fromNow()}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </SimpleBar>
            </div>

            <div className='user-chat w-100 overflow-hidden'>
                <div className='chat-content d-lg-flex'>
                    <div className='w-100 overflow-hidden position-relative'>
                        <div className='position-relative'>
                            <div className='p-3 user-chat-topbar' style={{ top: 0, left: 0 }}>
                                <Row className='align-items-center'>
                                    <Col>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-grow-1 overflow-hidden'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0'>
                                                        <img
                                                            src={bot?.avatar || userDummayImage}
                                                            className='img-thumbnail rounded-circle avatar-xs'
                                                            alt='avatar'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 overflow-hidden'>
                                                        <h5 className='text-truncate mb-0 fs-16'>{bot?.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='position-relative' id='users-chat' style={{ paddingTop: '80px' }}>
                                <div
                                    className='chat-conversation chat-conversation-no-reply px-3'
                                    id='chat-conversation'
                                >
                                    <PerfectScrollbar>
                                        <div id='elmLoader'></div>
                                        <ul className='list-unstyled chat-conversation-list' id='users-conversation'>
                                            {!!threads.length && !messages.length && !loading && (
                                                <Alert color='warning' className='mt-4'>
                                                    There are no messages for this chat thread
                                                </Alert>
                                            )}
                                            {messages.map((message, key) => (
                                                <li
                                                    className={
                                                        message.role === MessageRole.user
                                                            ? ' chat-list left'
                                                            : 'chat-list right'
                                                    }
                                                    key={key}
                                                >
                                                    <div className='conversation-list'>
                                                        {message.role == MessageRole.user && (
                                                            <div className='me-2'>
                                                                <div className='avatar-title rounded-circle avatar-xxs'>
                                                                    {(currentThread?.lead?.name || '?').charAt(0)}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {message.role === MessageRole.assistant && (
                                                            <div className='chat-avatar me-2'>
                                                                <img src={bot?.avatar || userDummayImage} alt='A' />
                                                            </div>
                                                        )}

                                                        <div className='user-chat-content'>
                                                            <div className='ctext-wrap'>
                                                                <div className='ctext-wrap-content'>
                                                                    <p className='mb-0 ctext-content'>
                                                                        {message.role === MessageRole.assistant
                                                                            ? htmlParse(message.content.text.value)
                                                                            : message.content.text.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='conversation-name'>
                                                                <small className='text-muted time'>
                                                                    {moment(message.createdAt).fromNow()}
                                                                </small>{' '}
                                                                <span className='text-success check-message-icon'>
                                                                    <i className='ri-check-double-line align-bottom'></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </PerfectScrollbar>
                                </div>
                                <div
                                    className='alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show '
                                    id='copyClipBoard'
                                    role='alert'
                                >
                                    Message copied
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Messages;
