import { Col, Row } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import error from '@src/assets/images/error-404.svg';
import { FormButton } from '@src/components/FormButton';

const NotFound = () => {
    const navigate = useNavigate();

    document.title = ' 404 Not found | Connections';

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center pt-4'>
                        <div className=''>
                            <img src={error} alt='' className='error-basic-img' />
                        </div>
                        <div>
                            <h3 className='text-uppercase'>Sorry, Page not Found</h3>
                            <p className='text-muted mb-4'>The page you are looking for is not available.</p>
                            <FormButton onClick={() => navigate(-1)} fitParent={false}>
                                <i className='mdi mdi-arrow-left me-1'></i>Go Back
                            </FormButton>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default NotFound;
