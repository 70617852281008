import 'cleave.js/dist/addons/cleave-phone.in';
import { useEffect, useMemo, useState } from 'react';

// Formik formValidation
import IBotCategory from '@src/interfaces/IBotCategory';
import IBotSubCategory from '@src/interfaces/IBotSubCategory';
import { IStepOneData } from '@src/interfaces/IGeneric';
import IUser from '@src/interfaces/IUser';
import { getBotCategories } from '@src/services/BotCategoryService';
import globalStore from '@src/stores/globalStore';
import registrationStore from '@src/stores/registrationStore';
import { errorToast } from '@src/utils/helpers';
import { registrationStepOneInitValues } from '@src/utils/initValues';
import { useFormik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import * as Yup from 'yup';

const useRegistrationStepOne = () => {
    const { nextStep } = useWizard();
    const setLoading = globalStore((state) => state.setLoading);
    const loading = globalStore((state) => state.loading);
    const setRegistrationUser = registrationStore((state) => state.setRegistrationUser);

    const [searchParams] = useSearchParams();
    const token = searchParams.get('key') || '';
    const [categories, setCategories] = useState<Array<IBotCategory>>([]);
    const referralObj = useMemo(() => (token ? (jwtDecode(token) as any) : undefined), []);

    const formValidation = useFormik({
        initialValues: registrationStepOneInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            companyName: Yup.string().required('Please enter your business name'),
            category: Yup.object<IBotCategory>().required('Please select a category'),
            subCategory: Yup.object<IBotSubCategory>().required('Please select a sub category')
        }),
        onSubmit: async (values: IStepOneData) => {
            console.log('submit', values);
            if (loading) return;

            const registrationUser = {
                name: formValidation.values.name!,
                companyName: formValidation.values.companyName!,
                category: formValidation.values.category!,
                subCategory: formValidation.values.subCategory!
            } as IUser;

            setRegistrationUser(registrationUser);
            nextStep();
        }
    });

    useEffect(() => {
        getData();
        if (referralObj) {
            formValidation.setFieldValue('name', referralObj.name);
        }
    }, []);

    useEffect(() => {
        formValidation.setFieldValue('subCategory', null);
    }, [formValidation.values.category]);

    const getData = async () => {
        setLoading(true);
        const { success, data, message } = await getBotCategories();
        if (success) {
            setCategories(data);
        } else errorToast(message);

        setLoading(false);
    };

    return {
        formValidation,
        loading,
        categories
    };
};

export default useRegistrationStepOne;
