import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'react-credit-cards/es/styles-compiled.css';
import { errorToast, getLoggedUser, sucessToast } from '@src/utils/helpers';
import IStripePaymentMethod from '@src/interfaces/IStripePaymentMethod';
import globalStore from '@src/stores/globalStore';
import { createSetupIntent, deletePaymentMethods, updateDefaultPaymentMethod } from '@src/services/PaymentService';

interface IUsePaymentMethodsTab {
    getData(): Promise<void>;
    paymentMethods: Array<IStripePaymentMethod>;
}

const usePaymentMethodsTab = ({ paymentMethods, getData }: IUsePaymentMethodsTab) => {
    const loggedUser = getLoggedUser();
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const elements = useElements();
    const stripe = useStripe();
    const [showPMModal, setShowPMModal] = useState(false);
    const [showDeletePMModal, setShowDeletePMModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState<IStripePaymentMethod>();

    const onDeletePM = async (card: IStripePaymentMethod) => {
        setShowDeletePMModal(true);
        setSelectedCard(card);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deletePaymentMethods(selectedCard?.id!);

        if (success) {
            await getData();
            sucessToast('Payment method deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeletePMModal(false);
        setSelectedCard(undefined);
    };

    const onOpenPMModal = () => {
        setSelectedCard(undefined);
        setShowPMModal(true);
    };

    const handleAddPM = async () => {
        setLoading(true);
        const cardElement = elements?.getElement(CardElement);
        const { success, data, message } = await createSetupIntent(loggedUser.stripeCustomerId!);
        if (success) {
            const result = await stripe?.confirmCardSetup(data, {
                payment_method: {
                    card: cardElement!
                }
            });
            if (result?.error) {
                setLoading(false);
                return errorToast(result.error.message);
            }
            if (!paymentMethods.length) {
                await updateDefaultPaymentMethod(
                    loggedUser.stripeCustomerId!,
                    result?.setupIntent?.payment_method?.toString()!
                );
            }
            getData();
            setShowPMModal(false);
        } else errorToast(message);
        setLoading(false);
    };

    const onSetDefaultPM = async (card: IStripePaymentMethod) => {
        setLoading(true);
        const { success, message } = await updateDefaultPaymentMethod(loggedUser.stripeCustomerId!, card.id!);
        if (success) {
            getData();
        } else errorToast(message);
        setLoading(false);
    };

    return {
        onOpenPMModal,
        loggedUser,
        showDeletePMModal,
        selectedCard,
        loading,
        showPMModal,
        onSetDefaultPM,
        onDeletePM,
        handleDelete,
        setShowDeletePMModal,
        handleAddPM,
        setShowPMModal
    };
};

export default usePaymentMethodsTab;
