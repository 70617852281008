import HttpService from '@src/utils/HttpService';
import IState from '@src/interfaces/IState';
import { objToCamel, objToSnake } from '@src/utils/helpers';
import { IDDLMulti } from '@src/interfaces/IGeneric';

export const createState = async (state: IState) => {
    const response = { data: {} as IState, success: false, message: '' };

    try {
        const endPoint = '/states/create';
        const formatApiData = objToSnake(state);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.post(endPoint, formatApiData);
        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IState;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getState = async (stateId: string) => {
    const response = { data: {} as IState, success: false, message: '' };

    try {
        const endPoint = `/states/${stateId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = objToCamel(data) as IState;
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getStates = async () => {
    const response = { data: [] as Array<IState>, success: false, message: '' };

    try {
        const endPoint = `/states`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IState);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getStatesDDL = async () => {
    const response = { data: [] as Array<IDDLMulti>, success: false, message: '' };

    try {
        //Note: temporarily disabled
        // const endPoint = `/states`;

        // const { data } = await HttpService.get(endPoint);
        // console.log('data getStatesDDL', data);
        // const formatApiResponse = data.map((item: any) => {
        //     const temp = objToCamel(item);

        //     return {
        //         label: `${temp.name} (${temp.isoCode})`,
        //         options: temp.municipalities.map((m: any) => ({
        //             value: m.muniId,
        //             label: m.name
        //         }))
        //     } as IDDLMulti;
        // });
        // console.log('formatApiResponse getStatesDDL', formatApiResponse);
        // response.data = formatApiResponse;

        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const updateState = async (state: IState) => {
    const response = { data: {} as IState, success: false, message: '' };

    try {
        const endPoint = `/states/update/${state.stateId}`;
        const formatApiData = objToSnake(state);
        console.log('data sent', formatApiData);

        const { data } = await HttpService.put(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IState;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteState = async (stateId: string) => {
    const response = { data: {} as IState, success: false, message: '' };

    try {
        const endPoint = `/states/${stateId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IState;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
