import { FormButton } from '@src/components/FormButton';
import IMunicipality from '@src/interfaces/IMunicipality';
import { RoutesName } from '@src/utils/enums';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, Table } from 'reactstrap';

interface IMunicipalitiesListInfoModal {
    show: boolean;
    selectedItem?: IMunicipality;
    onHide(): void;
}

const MunicipalitiesListInfoModal: React.FC<IMunicipalitiesListInfoModal> = ({ show, onHide, selectedItem }) => {
    return (
        <Modal isOpen={show} id='category-info-modal' centered toggle={onHide}>
            <ModalBody>
                <div className='d-flex justify-content-between align-items-start'>
                    <div>
                        <h4>{selectedItem?.name}</h4>
                        <Table className='table-borderless mb-0'>
                            <tbody>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Iso Code:
                                    </th>
                                    <td className='text-muted'>{selectedItem?.isoCode}</td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        State:
                                    </th>
                                    <td className='text-muted'>
                                        <Link to={`${RoutesName.adminStates}/${selectedItem?.stateId}`}>
                                            {selectedItem?.state?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Documents:
                                    </th>
                                    <td className=''>
                                        {selectedItem?.documents?.map((doc, idx) => (
                                            <Link to={doc.url || '#'} key={'form-doc-' + idx} target='_blank'>
                                                <span className='badge rounded-pill border border-primary text-primary me-1'>
                                                    doc-{idx + 1}
                                                </span>
                                            </Link>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Created At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.createdAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                                <tr>
                                    <th className='ps-0' scope='row'>
                                        Updated At:
                                    </th>
                                    <td className='text-muted'>
                                        {moment(selectedItem?.updatedAt).format('MM/DD/YYYY hh:mm A')}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='text-center mb-2'>
                        <i className='mdi mdi-city-variant-outline  m-2 display-3 text-primary' />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <FormButton value='Aceptar' fitParent={false} onClick={onHide} />
            </ModalFooter>
        </Modal>
    );
};

export default MunicipalitiesListInfoModal;
