// import { SuccessResponse } from '@greatsumini/react-facebook-login';
import IBot from '@src/interfaces/IBot';
import IStripeUser from '@src/interfaces/IStripeUser';
import ISubscriptionProduct from '@src/interfaces/ISubscriptionProduct';
import { createStripeUser, getStripeUser, getSubscriptionProducts } from '@src/services/PaymentService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import { encryptData, getLoggedUser } from '@src/utils/helpers';
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';

interface IBotAddEditModal {
    selectedBot?: IBot;
    show: boolean;
    formValidation: FormikProps<IBot>;
    setSelectedProduct: (price: ISubscriptionProduct) => void;
}

const useBotAddEditModal = ({ selectedBot, show, formValidation, setSelectedProduct }: IBotAddEditModal) => {
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [userAcceptedToPay, setUserAcceptedToPay] = useState(false);
    const loggedUser = getLoggedUser();
    const setLoading = globalStore((state) => state.setLoading);
    const [internalLoading, setInternalLoading] = useState(false);
    const [products, setProducts] = useState<Array<ISubscriptionProduct>>();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setShowAdvanced(!!selectedBot?.documents?.length || !!selectedBot?.allowedDomain || !!selectedBot?.maxTokens);
    }, [selectedBot?.documents, selectedBot?.allowedDomain]);

    useEffect(() => {
        if (!show) setUserAcceptedToPay(false);
    }, [show]);

    const getData = async () => {
        setInternalLoading(true);

        try {
            let stripeUserResponse = { success: false, data: {} as IStripeUser, message: '' };
            if (loggedUser.stripeCustomerId) stripeUserResponse = await getStripeUser(loggedUser.stripeCustomerId!);

            if (!stripeUserResponse.success) stripeUserResponse = await createStripeUser(loggedUser);

            if (stripeUserResponse.success) {
                loggedUser.stripeCustomerId = stripeUserResponse.data.id;
                localStorage.setItem(globals.userSessionKey, encryptData(loggedUser));

                const responses = await Promise.all([getSubscriptionProducts()]);
                const productsResponse = responses[0];

                if (productsResponse.success) {
                    console.log('productResponse', productsResponse.data);
                    setProducts(productsResponse.data);
                }
            }
        } catch (error) {}
        setInternalLoading(false);
    };

    const addDocumentElement = () => {
        const inputsArray = formValidation.values.documentsInputs
            ? [...formValidation.values.documentsInputs, '']
            : [''];
        formValidation.setFieldValue('documentsInputs', inputsArray);
    };

    const documentOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        if (!formValidation.values.documentsInputs) return;

        const temp = [...formValidation.values.documentsInputs];
        temp[idx] = e.target.files?.[0];
        formValidation.setFieldValue('documentsInputs', [...temp]);
    };

    const documentOnRemove = (idx: number) => {
        const element = document.getElementById(`document-div-${idx}`);
        if (element && formValidation.values.documentsInputs) {
            //Hide element to simulate the deletion
            element.setAttribute('style', 'display:none !important');
            formValidation.values.documentsInputs[idx] = null;
        }
    };

    const onMarkDocumentDeleted = (documentId: string) => {
        const docFound = formValidation.values.deletedDocuments?.find((d) => d === documentId);
        if (docFound) {
            const filtered = formValidation.values.deletedDocuments?.filter((d) => d != docFound);
            formValidation.setFieldValue('deletedDocuments', filtered);
        } else {
            const newDocsArray = [...(formValidation.values.deletedDocuments ?? []), documentId];
            formValidation.setFieldValue('deletedDocuments', newDocsArray);
        }
    };

    const onSubscribe = async (product: ISubscriptionProduct) => {
        setLoading(true);

        setSelectedProduct(product);

        //If creating bot, then we just show the form to create the bot (charge will be done until bot is created)
        if (!selectedBot?.botId) setUserAcceptedToPay(true);

        //If editing bot and subscription is not active, then we save the bot to create & charge the subscription.
        if (selectedBot?.botId && !selectedBot?.isSubscriptionActive) {
            formValidation.handleSubmit();
        }

        setLoading(false);
    };

    return {
        showAdvanced,
        userAcceptedToPay,
        internalLoading,
        products,
        loggedUser,
        addDocumentElement,
        documentOnChange,
        documentOnRemove,
        onMarkDocumentDeleted,
        onSubscribe,
        setShowAdvanced
    };
};

export default useBotAddEditModal;
