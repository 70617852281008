import { useEffect, useState } from 'react';
import { errorToast, sucessToast } from '@src/utils/helpers';
import globalStore from '@src/stores/globalStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ILead from '@src/interfaces/ILead';

import { useParams } from 'react-router-dom';
import { deleteLead, getLeads, updateLead } from '@src/services/LeadService';
import { leadInitValues } from '@src/utils/initValues';
import _ from 'lodash';

const useAdminLeads = () => {
    const { categoryId: filteredCategoryId } = useParams();

    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    const [leads, setleads] = useState<Array<ILead>>([]);
    const [selectedLead, setSelectedLead] = useState<ILead>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const formValidation = useFormik({
        initialValues: leadInitValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter your name'),
            phone: Yup.string().required('Please enter your phone'),
            email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter a valid email address')
        }),
        onSubmit: async (values: ILead) => {
            console.log('submit', values);

            if (loading) return;

            setLoading(true);

            const { success, message } = await updateLead(values);
            if (success) {
                await getData();
                formValidation.resetForm();
                sucessToast('Lead information was saved!');
            } else {
                errorToast(message);
            }
            setLoading(false);
            setShowAddEditModal(false);
        }
    });

    useEffect(() => {
        getData();
    }, [filteredCategoryId]);

    const getData = async () => {
        setLoading(true);

        try {
            const { success, data, message } = await getLeads();
            if (success) {
                setleads(Array.isArray(data) ? data : [data]);
            } else errorToast(message);
        } catch (error) {}
        setLoading(false);
    };

    const onEditClick = (lead: ILead) => {
        setSelectedLead(lead);
        formValidation.setValues(_.cloneDeep(lead));
        setShowAddEditModal(true);
    };

    const onDeleteClick = (lead: ILead) => {
        setSelectedLead(lead);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        const { success, message } = await deleteLead(selectedLead?.leadId!);

        if (success) {
            await getData();
            sucessToast('Lead deleted successfully!');
        } else errorToast(message);
        setLoading(false);
        setShowDeleteModal(false);
        setSelectedLead(undefined);
    };

    return {
        leads,
        selectedLead,
        formValidation,
        showDeleteModal,
        onEditClick,
        showAddEditModal,
        loading,
        setShowDeleteModal,
        handleDelete,
        onDeleteClick,
        setShowAddEditModal
    };
};

export default useAdminLeads;
