import { FormButton } from '@src/components/FormButton';
import { SearchAnimation } from '@src/components/SearchAnimation';
import { collection, db, onSnapshot, query, where } from '@src/services/Firebase';
import globalStore from '@src/stores/globalStore';
import HttpService from '@src/utils/HttpService';
import { errorToast, generateUniqueId } from '@src/utils/helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Input, Label } from 'reactstrap';

document.title = ' Test scrapper | Connections';

const TestScrapper: React.FC<{}> = () => {
    const [url, setUrl] = useState<string>('');
    const [data, setData] = useState<string>('');
    const [waitForScrape, setWaitForScrape] = useState<boolean>(false);
    const [scrapperUniqueId, setScrapperUniqueId] = useState('');
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    useEffect(() => {
        if (!waitForScrape) return;

        const docRef = query(
            collection(db, 'scrapping'),
            where('website', '==', url),
            where('scraping_id', '==', scrapperUniqueId)
        );

        let firstResponse = true;
        const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (firstResponse) {
                    firstResponse = false;
                    return;
                }
                const data = doc.data();
                console.log(`${doc.id} => `, JSON.stringify(data, null, 2));

                const lg = data.logs?.replaceAll(',', '\n - ');
                setData(lg);
            });
        });

        // Clean up subscription on unmount
        return () => unsubscribe();
    }, [waitForScrape]);

    const getData = async () => {
        if (!url.trim()) return;
        setLoading(true);
        setData('');
        setWaitForScrape(true);
        const uniqueId = generateUniqueId();
        setScrapperUniqueId(uniqueId);
        try {
            const startTime = Date.now();
            const { data } = await HttpService.post('/scrapper/test', { url, uniqueId });
            const duration = moment(Date.now() - startTime).format('mm:ss');

            setData(`Process has finished after ${duration}\nWith the following response from ChatGPT:\n\n\n${data}`);
            setWaitForScrape(false);
        } catch (error: any) {
            errorToast('Error while fetching data: ', error.message);
        }
        setLoading(false);
    };

    return (
        <Card className='m-3 p-5'>
            <div>
                <Label htmlFor='email' className='form-label'>
                    Website <span className='text-danger'>*</span>
                </Label>
                <div className='d-flex'>
                    <Input
                        name='website'
                        type='url'
                        className='form-control me-4'
                        placeholder='Enter a website url'
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <FormButton
                        value='Start scrapping'
                        fitParent={false}
                        style={{ minWidth: 150 }}
                        loading={loading}
                        onClick={getData}
                    />
                </div>
            </div>
            {loading && (
                <div className='mt-n2 mb-n5'>
                    <SearchAnimation />
                </div>
            )}
            <CardBody className='bg-dark text-white mt-3' style={{ borderRadius: 4 }}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>{data}</pre>
            </CardBody>
            <CardFooter>
                <div className='mt-4'></div>
            </CardFooter>
        </Card>
    );
};

export default TestScrapper;
